import React from 'react';
import "./pagination.css";

export const Pagination = (props) => {
    
    const getContent = () => {
        if(props.textDetail === "minimal") {
            return(
                <div className="pagination border-box flex_horizontal center_vertical center_horizontal">
                    <button className="bttn border gray" onClick={(e) => props.buttonClickHandler("navigation", "prev")}>❮</button>
                    <span className="colorGrayE154span weight300 centeralign" style={{marginLeft : "2px", marginRight : "2px", fontSize : "11px", width : "20px"}}>{props.page}</span>
                    <button className="bttn border gray" onClick={(e) => props.buttonClickHandler("navigation", "next")}>❯</button>
                </div>
            )
        }else{
            return(
                <div className="pagination border-box flex_horizontal center_vertical center_horizontal">
                    <button className="bttn border gray" onClick={(e) => props.buttonClickHandler("navigation", "prev")}>❮ &nbsp; Prev</button>
                    <span className="colorGrayE154span weight300 centeralign" style={{marginLeft : "5px", marginRight : "5px", fontSize : "11px", width : "60px"}}>Page {props.page}</span>
                    <button className="bttn border gray" onClick={(e) => props.buttonClickHandler("navigation", "next")}>Next &nbsp; ❯</button>
                </div>
            )
        }
    }

    return(
        <>
        {getContent()}
        </>    
    );
}