import { initializeApp } from "firebase/app";
// import constants from "./constants";

const databaseURL = "https://mirkwood-793fc-default-rtdb.asia-southeast1.firebasedatabase.app";
const firebase_config = {
    apiKey: "AIzaSyDVOIZo-K26JuUD-b7lxpkR0X-WvLnCfg0",
    authDomain: "mirkwood-793fc.firebaseapp.com",
    databaseURL: databaseURL,
    projectId: "mirkwood-793fc",
    storageBucket: "mirkwood-793fc.appspot.com",
    messagingSenderId: "917834021129",
    appId: "1:917834021129:web:1c272621733623aa30bbd8",
    measurementId: "G-JCS1RPXGB5"
  };

const firebaseapp = initializeApp(firebase_config);
// firebase.initializeApp(Constants.getFirebaseConfig());

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then(function(registration) {
//         firebase.messaging().useServiceWorker(registration);
//     }).catch(function(err) {
//       console.log('Service worker registration failed, error:', err);
//     });
//   }

export default firebaseapp;