import React from 'react';
import Bottombarunauth from './bottombar_unauth';
import "./bottombar.css"
import Bottombarinterviewcandidate from './bottombar_interview_candidate';
import Bottombaraccount from './bottombar_account';
import Bottombarchat from './bottombar_chat';


export default function Bottombar(props) {
	let contentType = ["account"].includes(props.type) ? "account" : "default";
	let content;
	switch(props.type) {
		case "interview_candidate":
			content = <Bottombarinterviewcandidate {...props} />;
			break;
		case "unauth":
			content = <Bottombarunauth {...props} />;
			break;
		case "chat":
			content = <Bottombarchat {...props} />;
			break;
		case "account":
			content = <Bottombaraccount {...props} />;
			break;
		default:
			content = <></>;
	}
	return (
		<>
		{
			{
				"account": 
					<>
						{content}
					</>,
				"default": 
					<div className="bottombarbase border-box">
						{content}
					</div>,
			}[contentType]
		}
		</>
	);
}