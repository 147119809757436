import React from 'react';

const base_url_comms = "https://comms.gomechanic.app/";
const base_url = "https://mirkwoodbe.thorintech.com/";

class constantsfixed{
    getBaseURLPR(){
        return base_url_comms;
    }
    getBaseURL(){
        return base_url;
    }
}

export default new constantsfixed();
