import React from 'react';

const COUNTRY_CODES = [{'name': 'Afghanistan',
'dial_code': '+93',
'emoji': '🇦🇫',
'code': 'AF',
'dial_code1': '93'},
{'name': 'Aland Islands',
'dial_code': '+358',
'emoji': '🇦🇽',
'code': 'AX',
'dial_code1': '358'},
{'name': 'Albania',
'dial_code': '+355',
'emoji': '🇦🇱',
'code': 'AL',
'dial_code1': '355'},
{'name': 'Algeria',
'dial_code': '+213',
'emoji': '🇩🇿',
'code': 'DZ',
'dial_code1': '213'},
{'name': 'AmericanSamoa',
'dial_code': '+1684',
'emoji': '🇦🇸',
'code': 'AS',
'dial_code1': '1684'},
{'name': 'Andorra',
'dial_code': '+376',
'emoji': '🇦🇩',
'code': 'AD',
'dial_code1': '376'},
{'name': 'Angola',
'dial_code': '+244',
'emoji': '🇦🇴',
'code': 'AO',
'dial_code1': '244'},
{'name': 'Anguilla',
'dial_code': '+1264',
'emoji': '🇦🇮',
'code': 'AI',
'dial_code1': '1264'},
{'name': 'Antarctica',
'dial_code': '+672',
'emoji': '🇦🇶',
'code': 'AQ',
'dial_code1': '672'},
{'name': 'Antigua and Barbuda',
'dial_code': '+1268',
'emoji': '🇦🇬',
'code': 'AG',
'dial_code1': '1268'},
{'name': 'Argentina',
'dial_code': '+54',
'emoji': '🇦🇷',
'code': 'AR',
'dial_code1': '54'},
{'name': 'Armenia',
'dial_code': '+374',
'emoji': '🇦🇲',
'code': 'AM',
'dial_code1': '374'},
{'name': 'Aruba',
'dial_code': '+297',
'emoji': '🇦🇼',
'code': 'AW',
'dial_code1': '297'},
{'name': 'Australia',
'dial_code': '+61',
'emoji': '🇦🇺',
'code': 'AU',
'dial_code1': '61'},
{'name': 'Austria',
'dial_code': '+43',
'emoji': '🇦🇹',
'code': 'AT',
'dial_code1': '43'},
{'name': 'Azerbaijan',
'dial_code': '+994',
'emoji': '🇦🇿',
'code': 'AZ',
'dial_code1': '994'},
{'name': 'Bahamas',
'dial_code': '+1242',
'emoji': '🇧🇸',
'code': 'BS',
'dial_code1': '1242'},
{'name': 'Bahrain',
'dial_code': '+973',
'emoji': '🇧🇭',
'code': 'BH',
'dial_code1': '973'},
{'name': 'Bangladesh',
'dial_code': '+880',
'emoji': '🇧🇩',
'code': 'BD',
'dial_code1': '880'},
{'name': 'Barbados',
'dial_code': '+1246',
'emoji': '🇧🇧',
'code': 'BB',
'dial_code1': '1246'},
{'name': 'Belarus',
'dial_code': '+375',
'emoji': '🇧🇾',
'code': 'BY',
'dial_code1': '375'},
{'name': 'Belgium',
'dial_code': '+32',
'emoji': '🇧🇪',
'code': 'BE',
'dial_code1': '32'},
{'name': 'Belize',
'dial_code': '+501',
'emoji': '🇧🇿',
'code': 'BZ',
'dial_code1': '501'},
{'name': 'Benin',
'dial_code': '+229',
'emoji': '🇧🇯',
'code': 'BJ',
'dial_code1': '229'},
{'name': 'Bermuda',
'dial_code': '+1441',
'emoji': '🇧🇲',
'code': 'BM',
'dial_code1': '1441'},
{'name': 'Bhutan',
'dial_code': '+975',
'emoji': '🇧🇹',
'code': 'BT',
'dial_code1': '975'},
{'name': 'Bolivia, Plurinational State of',
'dial_code': '+591',
'emoji': '🇧🇴',
'code': 'BO',
'dial_code1': '591'},
{'name': 'Bosnia and Herzegovina',
'dial_code': '+387',
'emoji': '🇧🇦',
'code': 'BA',
'dial_code1': '387'},
{'name': 'Botswana',
'dial_code': '+267',
'emoji': '🇧🇼',
'code': 'BW',
'dial_code1': '267'},
{'name': 'Brazil',
'dial_code': '+55',
'emoji': '🇧🇷',
'code': 'BR',
'dial_code1': '55'},
{'name': 'British Indian Ocean Territory',
'dial_code': '+246',
'emoji': '🇮🇴',
'code': 'IO',
'dial_code1': '246'},
{'name': 'Brunei Darussalam',
'dial_code': '+673',
'emoji': '🇧🇳',
'code': 'BN',
'dial_code1': '673'},
{'name': 'Bulgaria',
'dial_code': '+359',
'emoji': '🇧🇬',
'code': 'BG',
'dial_code1': '359'},
{'name': 'Burkina Faso',
'dial_code': '+226',
'emoji': '🇧🇫',
'code': 'BF',
'dial_code1': '226'},
{'name': 'Burundi',
'dial_code': '+257',
'emoji': '🇧🇮',
'code': 'BI',
'dial_code1': '257'},
{'name': 'Cambodia',
'dial_code': '+855',
'emoji': '🇰🇭',
'code': 'KH',
'dial_code1': '855'},
{'name': 'Cameroon',
'dial_code': '+237',
'emoji': '🇨🇲',
'code': 'CM',
'dial_code1': '237'},
{'name': 'Canada',
'dial_code': '+1',
'emoji': '🇨🇦',
'code': 'CA',
'dial_code1': '1'},
{'name': 'Cape Verde',
'dial_code': '+238',
'emoji': '🇨🇻',
'code': 'CV',
'dial_code1': '238'},
{'name': 'Cayman Islands',
'dial_code': '+345',
'emoji': '🇰🇾',
'code': 'KY',
'dial_code1': '345'},
{'name': 'Central African Republic',
'dial_code': '+236',
'emoji': '🇨🇫',
'code': 'CF',
'dial_code1': '236'},
{'name': 'Chad',
'dial_code': '+235',
'emoji': '🇹🇩',
'code': 'TD',
'dial_code1': '235'},
{'name': 'Chile',
'dial_code': '+56',
'emoji': '🇨🇱',
'code': 'CL',
'dial_code1': '56'},
{'name': 'China',
'dial_code': '+86',
'emoji': '🇨🇳',
'code': 'CN',
'dial_code1': '86'},
{'name': 'Christmas Island',
'dial_code': '+61',
'emoji': '🇨🇽',
'code': 'CX',
'dial_code1': '61'},
{'name': 'Cocos (Keeling) Islands',
'dial_code': '+61',
'emoji': '🇨🇨',
'code': 'CC',
'dial_code1': '61'},
{'name': 'Colombia',
'dial_code': '+57',
'emoji': '🇨🇴',
'code': 'CO',
'dial_code1': '57'},
{'name': 'Comoros',
'dial_code': '+269',
'emoji': '🇰🇲',
'code': 'KM',
'dial_code1': '269'},
{'name': 'Congo',
'dial_code': '+242',
'emoji': '🇨🇬',
'code': 'CG',
'dial_code1': '242'},
{'name': 'Congo, The Democratic Republic of the Congo',
'dial_code': '+243',
'emoji': '🇨🇩',
'code': 'CD',
'dial_code1': '243'},
{'name': 'Cook Islands',
'dial_code': '+682',
'emoji': '🇨🇰',
'code': 'CK',
'dial_code1': '682'},
{'name': 'Costa Rica',
'dial_code': '+506',
'emoji': '🇨🇷',
'code': 'CR',
'dial_code1': '506'},
{'name': "Cote d'Ivoire",
'dial_code': '+225',
'emoji': '🇨🇮',
'code': 'CI',
'dial_code1': '225'},
{'name': 'Croatia',
'dial_code': '+385',
'emoji': '🇭🇷',
'code': 'HR',
'dial_code1': '385'},
{'name': 'Cuba',
'dial_code': '+53',
'emoji': '🇨🇺',
'code': 'CU',
'dial_code1': '53'},
{'name': 'Cyprus',
'dial_code': '+357',
'emoji': '🇨🇾',
'code': 'CY',
'dial_code1': '357'},
{'name': 'Czech Republic',
'dial_code': '+420',
'emoji': '🇨🇿',
'code': 'CZ',
'dial_code1': '420'},
{'name': 'Denmark',
'dial_code': '+45',
'emoji': '🇩🇰',
'code': 'DK',
'dial_code1': '45'},
{'name': 'Djibouti',
'dial_code': '+253',
'emoji': '🇩🇯',
'code': 'DJ',
'dial_code1': '253'},
{'name': 'Dominica',
'dial_code': '+1767',
'emoji': '🇩🇲',
'code': 'DM',
'dial_code1': '1767'},
{'name': 'Dominican Republic',
'dial_code': '+1849',
'emoji': '🇩🇴',
'code': 'DO',
'dial_code1': '1849'},
{'name': 'Ecuador',
'dial_code': '+593',
'emoji': '🇪🇨',
'code': 'EC',
'dial_code1': '593'},
{'name': 'Egypt',
'dial_code': '+20',
'emoji': '🇪🇬',
'code': 'EG',
'dial_code1': '20'},
{'name': 'El Salvador',
'dial_code': '+503',
'emoji': '🇸🇻',
'code': 'SV',
'dial_code1': '503'},
{'name': 'Equatorial Guinea',
'dial_code': '+240',
'emoji': '🇬🇶',
'code': 'GQ',
'dial_code1': '240'},
{'name': 'Eritrea',
'dial_code': '+291',
'emoji': '🇪🇷',
'code': 'ER',
'dial_code1': '291'},
{'name': 'Estonia',
'dial_code': '+372',
'emoji': '🇪🇪',
'code': 'EE',
'dial_code1': '372'},
{'name': 'Ethiopia',
'dial_code': '+251',
'emoji': '🇪🇹',
'code': 'ET',
'dial_code1': '251'},
{'name': 'Falkland Islands (Malvinas)',
'dial_code': '+500',
'emoji': '🇫🇰',
'code': 'FK',
'dial_code1': '500'},
{'name': 'Faroe Islands',
'dial_code': '+298',
'emoji': '🇫🇴',
'code': 'FO',
'dial_code1': '298'},
{'name': 'Fiji',
'dial_code': '+679',
'emoji': '🇫🇯',
'code': 'FJ',
'dial_code1': '679'},
{'name': 'Finland',
'dial_code': '+358',
'emoji': '🇫🇮',
'code': 'FI',
'dial_code1': '358'},
{'name': 'France',
'dial_code': '+33',
'emoji': '🇫🇷',
'code': 'FR',
'dial_code1': '33'},
{'name': 'French Guiana',
'dial_code': '+594',
'emoji': '🇬🇫',
'code': 'GF',
'dial_code1': '594'},
{'name': 'French Polynesia',
'dial_code': '+689',
'emoji': '🇵🇫',
'code': 'PF',
'dial_code1': '689'},
{'name': 'Gabon',
'dial_code': '+241',
'emoji': '🇬🇦',
'code': 'GA',
'dial_code1': '241'},
{'name': 'Gambia',
'dial_code': '+220',
'emoji': '🇬🇲',
'code': 'GM',
'dial_code1': '220'},
{'name': 'Georgia',
'dial_code': '+995',
'emoji': '🇬🇪',
'code': 'GE',
'dial_code1': '995'},
{'name': 'Germany',
'dial_code': '+49',
'emoji': '🇩🇪',
'code': 'DE',
'dial_code1': '49'},
{'name': 'Ghana',
'dial_code': '+233',
'emoji': '🇬🇭',
'code': 'GH',
'dial_code1': '233'},
{'name': 'Gibraltar',
'dial_code': '+350',
'emoji': '🇬🇮',
'code': 'GI',
'dial_code1': '350'},
{'name': 'Greece',
'dial_code': '+30',
'emoji': '🇬🇷',
'code': 'GR',
'dial_code1': '30'},
{'name': 'Greenland',
'dial_code': '+299',
'emoji': '🇬🇱',
'code': 'GL',
'dial_code1': '299'},
{'name': 'Grenada',
'dial_code': '+1473',
'emoji': '🇬🇩',
'code': 'GD',
'dial_code1': '1473'},
{'name': 'Guadeloupe',
'dial_code': '+590',
'emoji': '🇬🇵',
'code': 'GP',
'dial_code1': '590'},
{'name': 'Guam',
'dial_code': '+1671',
'emoji': '🇬🇺',
'code': 'GU',
'dial_code1': '1671'},
{'name': 'Guatemala',
'dial_code': '+502',
'emoji': '🇬🇹',
'code': 'GT',
'dial_code1': '502'},
{'name': 'Guernsey',
'dial_code': '+44',
'emoji': '🇬🇬',
'code': 'GG',
'dial_code1': '44'},
{'name': 'Guinea',
'dial_code': '+224',
'emoji': '🇬🇳',
'code': 'GN',
'dial_code1': '224'},
{'name': 'Guinea-Bissau',
'dial_code': '+245',
'emoji': '🇬🇼',
'code': 'GW',
'dial_code1': '245'},
{'name': 'Guyana',
'dial_code': '+595',
'emoji': '🇬🇾',
'code': 'GY',
'dial_code1': '595'},
{'name': 'Haiti',
'dial_code': '+509',
'emoji': '🇭🇹',
'code': 'HT',
'dial_code1': '509'},
{'name': 'Holy See (Vatican City State)',
'dial_code': '+379',
'emoji': '🇻🇦',
'code': 'VA',
'dial_code1': '379'},
{'name': 'Honduras',
'dial_code': '+504',
'emoji': '🇭🇳',
'code': 'HN',
'dial_code1': '504'},
{'name': 'Hong Kong',
'dial_code': '+852',
'emoji': '🇭🇰',
'code': 'HK',
'dial_code1': '852'},
{'name': 'Hungary',
'dial_code': '+36',
'emoji': '🇭🇺',
'code': 'HU',
'dial_code1': '36'},
{'name': 'Iceland',
'dial_code': '+354',
'emoji': '🇮🇸',
'code': 'IS',
'dial_code1': '354'},
{'name': 'India',
'dial_code': '+91',
'emoji': '🇮🇳',
'code': 'IN',
'dial_code1': '91'},
{'name': 'Indonesia',
'dial_code': '+62',
'emoji': '🇮🇩',
'code': 'ID',
'dial_code1': '62'},
{'name': 'Iran, Islamic Republic of Persian Gulf',
'dial_code': '+98',
'emoji': '🇮🇷',
'code': 'IR',
'dial_code1': '98'},
{'name': 'Iraq',
'dial_code': '+964',
'emoji': '🇮🇷',
'code': 'IQ',
'dial_code1': '964'},
{'name': 'Ireland',
'dial_code': '+353',
'emoji': '🇮🇪',
'code': 'IE',
'dial_code1': '353'},
{'name': 'Isle of Man',
'dial_code': '+44',
'emoji': '🇮🇲',
'code': 'IM',
'dial_code1': '44'},
{'name': 'Israel',
'dial_code': '+972',
'emoji': '🇮🇱',
'code': 'IL',
'dial_code1': '972'},
{'name': 'Italy',
'dial_code': '+39',
'emoji': '🇮🇹',
'code': 'IT',
'dial_code1': '39'},
{'name': 'Jamaica',
'dial_code': '+1876',
'emoji': '🇯🇲',
'code': 'JM',
'dial_code1': '1876'},
{'name': 'Japan',
'dial_code': '+81',
'emoji': '🇯🇵',
'code': 'JP',
'dial_code1': '81'},
{'name': 'Jersey',
'dial_code': '+44',
'emoji': '🇯🇪',
'code': 'JE',
'dial_code1': '44'},
{'name': 'Jordan',
'dial_code': '+962',
'emoji': '🇯🇴',
'code': 'JO',
'dial_code1': '962'},
{'name': 'Kazakhstan',
'dial_code': '+77',
'emoji': '🇰🇿',
'code': 'KZ',
'dial_code1': '77'},
{'name': 'Kenya',
'dial_code': '+254',
'emoji': '🇰🇪',
'code': 'KE',
'dial_code1': '254'},
{'name': 'Kiribati',
'dial_code': '+686',
'emoji': '🇰🇮',
'code': 'KI',
'dial_code1': '686'},
{'name': "Korea, Democratic People's Republic of Korea",
'dial_code': '+850',
'emoji': '🇰🇵',
'code': 'KP',
'dial_code1': '850'},
{'name': 'Korea, Republic of South Korea',
'dial_code': '+82',
'emoji': '🇰🇷',
'code': 'KR',
'dial_code1': '82'},
{'name': 'Kuwait',
'dial_code': '+965',
'emoji': '🇰🇼',
'code': 'KW',
'dial_code1': '965'},
{'name': 'Kyrgyzstan',
'dial_code': '+996',
'emoji': '🇰🇬',
'code': 'KG',
'dial_code1': '996'},
{'name': 'Laos',
'dial_code': '+856',
'emoji': '🇱🇦',
'code': 'LA',
'dial_code1': '856'},
{'name': 'Latvia',
'dial_code': '+371',
'emoji': '🇱🇻',
'code': 'LV',
'dial_code1': '371'},
{'name': 'Lebanon',
'dial_code': '+961',
'emoji': '🇱🇧',
'code': 'LB',
'dial_code1': '961'},
{'name': 'Lesotho',
'dial_code': '+266',
'emoji': '🇱🇸',
'code': 'LS',
'dial_code1': '266'},
{'name': 'Liberia',
'dial_code': '+231',
'emoji': '🇱🇷',
'code': 'LR',
'dial_code1': '231'},
{'name': 'Libyan Arab Jamahiriya',
'dial_code': '+218',
'emoji': '🇱🇾',
'code': 'LY',
'dial_code1': '218'},
{'name': 'Liechtenstein',
'dial_code': '+423',
'emoji': '🇱🇮',
'code': 'LI',
'dial_code1': '423'},
{'name': 'Lithuania',
'dial_code': '+370',
'emoji': '🇱🇹',
'code': 'LT',
'dial_code1': '370'},
{'name': 'Luxembourg',
'dial_code': '+352',
'emoji': '🇱🇺',
'code': 'LU',
'dial_code1': '352'},
{'name': 'Macao',
'dial_code': '+853',
'emoji': '🇲🇴',
'code': 'MO',
'dial_code1': '853'},
{'name': 'Macedonia',
'dial_code': '+389',
'emoji': '🇲🇰',
'code': 'MK',
'dial_code1': '389'},
{'name': 'Madagascar',
'dial_code': '+261',
'emoji': '🇲🇬',
'code': 'MG',
'dial_code1': '261'},
{'name': 'Malawi',
'dial_code': '+265',
'emoji': '🇲🇼',
'code': 'MW',
'dial_code1': '265'},
{'name': 'Malaysia',
'dial_code': '+60',
'emoji': '🇲🇾',
'code': 'MY',
'dial_code1': '60'},
{'name': 'Maldives',
'dial_code': '+960',
'emoji': '🇲🇻',
'code': 'MV',
'dial_code1': '960'},
{'name': 'Mali',
'dial_code': '+223',
'emoji': '🇲🇱',
'code': 'ML',
'dial_code1': '223'},
{'name': 'Malta',
'dial_code': '+356',
'emoji': '🇲🇹',
'code': 'MT',
'dial_code1': '356'},
{'name': 'Marshall Islands',
'dial_code': '+692',
'emoji': '🇲🇭',
'code': 'MH',
'dial_code1': '692'},
{'name': 'Martinique',
'dial_code': '+596',
'emoji': '🇲🇶',
'code': 'MQ',
'dial_code1': '596'},
{'name': 'Mauritania',
'dial_code': '+222',
'emoji': '🇲🇷',
'code': 'MR',
'dial_code1': '222'},
{'name': 'Mauritius',
'dial_code': '+230',
'emoji': '🇲🇺',
'code': 'MU',
'dial_code1': '230'},
{'name': 'Mayotte',
'dial_code': '+262',
'emoji': '🇾🇹',
'code': 'YT',
'dial_code1': '262'},
{'name': 'Mexico',
'dial_code': '+52',
'emoji': '🇲🇽',
'code': 'MX',
'dial_code1': '52'},
{'name': 'Micronesia, Federated States of Micronesia',
'dial_code': '+691',
'emoji': '🇫🇲',
'code': 'FM',
'dial_code1': '691'},
{'name': 'Moldova',
'dial_code': '+373',
'emoji': '🇲🇩',
'code': 'MD',
'dial_code1': '373'},
{'name': 'Monaco',
'dial_code': '+377',
'emoji': '🇲🇨',
'code': 'MC',
'dial_code1': '377'},
{'name': 'Mongolia',
'dial_code': '+976',
'emoji': '🇲🇳',
'code': 'MN',
'dial_code1': '976'},
{'name': 'Montenegro',
'dial_code': '+382',
'emoji': '🇲🇪',
'code': 'ME',
'dial_code1': '382'},
{'name': 'Montserrat',
'dial_code': '+1664',
'emoji': '🇲🇸',
'code': 'MS',
'dial_code1': '1664'},
{'name': 'Morocco',
'dial_code': '+212',
'emoji': '🇲🇦',
'code': 'MA',
'dial_code1': '212'},
{'name': 'Mozambique',
'dial_code': '+258',
'emoji': '🇲🇿',
'code': 'MZ',
'dial_code1': '258'},
{'name': 'Myanmar',
'dial_code': '+95',
'emoji': '🇲🇲',
'code': 'MM',
'dial_code1': '95'},
{'name': 'Namibia',
'emoji': '🇳🇦',
'dial_code': '+264',
'code': 'NA',
'dial_code1': '264'},
{'name': 'Nauru',
'dial_code': '+674',
'emoji': '🇳🇷',
'code': 'NR',
'dial_code1': '674'},
{'name': 'Nepal',
'dial_code': '+977',
'emoji': '🇳🇵',
'code': 'NP',
'dial_code1': '977'},
{'name': 'Netherlands',
'dial_code': '+31',
'emoji': '🇳🇱',
'code': 'NL',
'dial_code1': '31'},
{'name': 'Netherlands Antilles',
'dial_code': '+599',
'emoji': '🇧🇶',
'code': 'AN',
'dial_code1': '599'},
{'name': 'New Caledonia',
'dial_code': '+687',
'emoji': '🇳🇨',
'code': 'NC',
'dial_code1': '687'},
{'name': 'New Zealand',
'dial_code': '+64',
'emoji': '🇳🇿',
'code': 'NZ',
'dial_code1': '64'},
{'name': 'Nicaragua',
'dial_code': '+505',
'emoji': '🇳🇮',
'code': 'NI',
'dial_code1': '505'},
{'name': 'Niger',
'dial_code': '+227',
'emoji': '🇳🇪',
'code': 'NE',
'dial_code1': '227'},
{'name': 'Nigeria',
'dial_code': '+234',
'emoji': '🇳🇬',
'code': 'NG',
'dial_code1': '234'},
{'name': 'Niue',
'dial_code': '+683',
'emoji': '🇳🇺',
'code': 'NU',
'dial_code1': '683'},
{'name': 'Norfolk Island',
'dial_code': '+672',
'emoji': '🇳🇫',
'code': 'NF',
'dial_code1': '672'},
{'name': 'Northern Mariana Islands',
'dial_code': '+1670',
'emoji': '🇲🇵',
'code': 'MP',
'dial_code1': '1670'},
{'name': 'Norway',
'dial_code': '+47',
'emoji': '🇳🇴',
'code': 'NO',
'dial_code1': '47'},
{'name': 'Oman',
'dial_code': '+968',
'emoji': '🇴🇲',
'code': 'OM',
'dial_code1': '968'},
{'name': 'Pakistan',
'dial_code': '+92',
'emoji': '🇵🇰',
'code': 'PK',
'dial_code1': '92'},
{'name': 'Palau',
'dial_code': '+680',
'emoji': '🇵🇼',
'code': 'PW',
'dial_code1': '680'},
{'name': 'Palestinian Territory, Occupied',
'dial_code': '+970',
'emoji': '🇵🇸',
'code': 'PS',
'dial_code1': '970'},
{'name': 'Panama',
'dial_code': '+507',
'emoji': '🇵🇦',
'code': 'PA',
'dial_code1': '507'},
{'name': 'Papua New Guinea',
'dial_code': '+675',
'emoji': '🇵🇬',
'code': 'PG',
'dial_code1': '675'},
{'name': 'Paraguay',
'dial_code': '+595',
'emoji': '🇵🇾',
'code': 'PY',
'dial_code1': '595'},
{'name': 'Peru',
'dial_code': '+51',
'emoji': '🇵🇪',
'code': 'PE',
'dial_code1': '51'},
{'name': 'Philippines',
'dial_code': '+63',
'emoji': '🇵🇭',
'code': 'PH',
'dial_code1': '63'},
{'name': 'Pitcairn',
'dial_code': '+872',
'emoji': '🇵🇳',
'code': 'PN',
'dial_code1': '872'},
{'name': 'Poland',
'dial_code': '+48',
'emoji': '🇵🇱',
'code': 'PL',
'dial_code1': '48'},
{'name': 'Portugal',
'dial_code': '+351',
'emoji': '🇵🇹',
'code': 'PT',
'dial_code1': '351'},
{'name': 'Puerto Rico',
'dial_code': '+1939',
'emoji': '🇵🇷',
'code': 'PR',
'dial_code1': '1939'},
{'name': 'Qatar',
'dial_code': '+974',
'emoji': '🇶🇦',
'code': 'QA',
'dial_code1': '974'},
{'name': 'Romania',
'dial_code': '+40',
'emoji': '🇷🇴',
'code': 'RO',
'dial_code1': '40'},
{'name': 'Russia',
'dial_code': '+7',
'emoji': '🇷🇺',
'code': 'RU',
'dial_code1': '7'},
{'name': 'Rwanda',
'dial_code': '+250',
'emoji': '🇷🇼',
'code': 'RW',
'dial_code1': '250'},
{'name': 'Reunion',
'dial_code': '+262',
'emoji': '🇷🇪',
'code': 'RE',
'dial_code1': '262'},
{'name': 'Saint Barthelemy',
'dial_code': '+590',
'emoji': '🇧🇱',
'code': 'BL',
'dial_code1': '590'},
{'name': 'Saint Helena, Ascension and Tristan Da Cunha',
'dial_code': '+290',
'emoji': '🇸🇭',
'code': 'SH',
'dial_code1': '290'},
{'name': 'Saint Kitts and Nevis',
'dial_code': '+1869',
'emoji': '🇰🇳',
'code': 'KN',
'dial_code1': '1869'},
{'name': 'Saint Lucia',
'dial_code': '+1758',
'emoji': '🇱🇨',
'code': 'LC',
'dial_code1': '1758'},
{'name': 'Saint Martin',
'dial_code': '+590',
'emoji': '🇲🇫',
'code': 'MF',
'dial_code1': '590'},
{'name': 'Saint Pierre and Miquelon',
'dial_code': '+508',
'emoji': '🇵🇲',
'code': 'PM',
'dial_code1': '508'},
{'name': 'Saint Vincent and the Grenadines',
'dial_code': '+1784',
'emoji': '🇻🇨',
'code': 'VC',
'dial_code1': '1784'},
{'name': 'Samoa',
'dial_code': '+685',
'emoji': '🇼🇸',
'code': 'WS',
'dial_code1': '685'},
{'name': 'San Marino',
'dial_code': '+378',
'emoji': '🇸🇲',
'code': 'SM',
'dial_code1': '378'},
{'name': 'Sao Tome and Principe',
'dial_code': '+239',
'emoji': '🇸🇹',
'code': 'ST',
'dial_code1': '239'},
{'name': 'Saudi Arabia',
'dial_code': '+966',
'emoji': '🇸🇦',
'code': 'SA',
'dial_code1': '966'},
{'name': 'Senegal',
'dial_code': '+221',
'emoji': '🇸🇳',
'code': 'SN',
'dial_code1': '221'},
{'name': 'Serbia',
'dial_code': '+381',
'emoji': '🇷🇸',
'code': 'RS',
'dial_code1': '381'},
{'name': 'Seychelles',
'dial_code': '+248',
'emoji': '🇸🇨',
'code': 'SC',
'dial_code1': '248'},
{'name': 'Sierra Leone',
'dial_code': '+232',
'emoji': '🇸🇱',
'code': 'SL',
'dial_code1': '232'},
{'name': 'Singapore',
'dial_code': '+65',
'emoji': '🇸🇬',
'code': 'SG',
'dial_code1': '65'},
{'name': 'Slovakia',
'dial_code': '+421',
'emoji': '🇸🇰',
'code': 'SK',
'dial_code1': '421'},
{'name': 'Slovenia',
'dial_code': '+386',
'emoji': '🇸🇮',
'code': 'SI',
'dial_code1': '386'},
{'name': 'Solomon Islands',
'dial_code': '+677',
'emoji': '🇸🇧',
'code': 'SB',
'dial_code1': '677'},
{'name': 'Somalia',
'dial_code': '+252',
'emoji': '🇸🇴',
'code': 'SO',
'dial_code1': '252'},
{'name': 'South Africa',
'dial_code': '+27',
'emoji': '🇿🇦',
'code': 'ZA',
'dial_code1': '27'},
{'name': 'South Sudan',
'dial_code': '+211',
'emoji': '🇸🇸',
'code': 'SS',
'dial_code1': '211'},
{'name': 'South Georgia and the South Sandwich Islands',
'dial_code': '+500',
'emoji': '🇬🇸',
'code': 'GS',
'dial_code1': '500'},
{'name': 'Spain',
'dial_code': '+34',
'emoji': '🇪🇸',
'code': 'ES',
'dial_code1': '34'},
{'name': 'Sri Lanka',
'dial_code': '+94',
'emoji': '🇱🇰',
'code': 'LK',
'dial_code1': '94'},
{'name': 'Sudan',
'dial_code': '+249',
'emoji': '🇸🇩',
'code': 'SD',
'dial_code1': '249'},
{'name': 'Suriname',
'dial_code': '+597',
'emoji': '🇸🇷',
'code': 'SR',
'dial_code1': '597'},
{'name': 'Svalbard and Jan Mayen',
'dial_code': '+47',
'emoji': '🇸🇯',
'code': 'SJ',
'dial_code1': '47'},
{'name': 'Swaziland',
'dial_code': '+268',
'emoji': '🇸🇿',
'code': 'SZ',
'dial_code1': '268'},
{'name': 'Sweden',
'dial_code': '+46',
'emoji': '🇸🇪',
'code': 'SE',
'dial_code1': '46'},
{'name': 'Switzerland',
'dial_code': '+41',
'emoji': '🇨🇭',
'code': 'CH',
'dial_code1': '41'},
{'name': 'Syrian Arab Republic',
'dial_code': '+963',
'emoji': '🇸🇾',
'code': 'SY',
'dial_code1': '963'},
{'name': 'Taiwan',
'dial_code': '+886',
'emoji': '🇹🇼',
'code': 'TW',
'dial_code1': '886'},
{'name': 'Tajikistan',
'dial_code': '+992',
'emoji': '🇹🇯',
'code': 'TJ',
'dial_code1': '992'},
{'name': 'Tanzania, United Republic of Tanzania',
'dial_code': '+255',
'emoji': '🇹🇿',
'code': 'TZ',
'dial_code1': '255'},
{'name': 'Thailand',
'dial_code': '+66',
'emoji': '🇹🇭',
'code': 'TH',
'dial_code1': '66'},
{'name': 'Timor-Leste',
'dial_code': '+670',
'emoji': '🇹🇱',
'code': 'TL',
'dial_code1': '670'},
{'name': 'Togo',
'dial_code': '+228',
'emoji': '🇹🇬',
'code': 'TG',
'dial_code1': '228'},
{'name': 'Tokelau',
'dial_code': '+690',
'emoji': '🇹🇰',
'code': 'TK',
'dial_code1': '690'},
{'name': 'Tonga',
'dial_code': '+676',
'emoji': '🇹🇴',
'code': 'TO',
'dial_code1': '676'},
{'name': 'Trinidad and Tobago',
'dial_code': '+1868',
'emoji': '🇹🇹',
'code': 'TT',
'dial_code1': '1868'},
{'name': 'Tunisia',
'dial_code': '+216',
'emoji': '🇹🇳',
'code': 'TN',
'dial_code1': '216'},
{'name': 'Turkey',
'dial_code': '+90',
'emoji': '🇹🇷',
'code': 'TR',
'dial_code1': '90'},
{'name': 'Turkmenistan',
'dial_code': '+993',
'emoji': '🇹🇲',
'code': 'TM',
'dial_code1': '993'},
{'name': 'Turks and Caicos Islands',
'dial_code': '+1649',
'emoji': '🇹🇨',
'code': 'TC',
'dial_code1': '1649'},
{'name': 'Tuvalu',
'dial_code': '+688',
'emoji': '🇹🇻',
'code': 'TV',
'dial_code1': '688'},
{'name': 'Uganda',
'dial_code': '+256',
'emoji': '🇺🇬',
'code': 'UG',
'dial_code1': '256'},
{'name': 'Ukraine',
'dial_code': '+380',
'emoji': '🇺🇦',
'code': 'UA',
'dial_code1': '380'},
{'name': 'United Arab Emirates',
'dial_code': '+971',
'emoji': '🇦🇪',
'code': 'AE',
'dial_code1': '971'},
{'name': 'United Kingdom',
'dial_code': '+44',
'emoji': '🇬🇧',
'code': 'GB',
'dial_code1': '44'},
{'name': 'United States',
'dial_code': '+1',
'emoji': '🇺🇸',
'code': 'US',
'dial_code1': '1'},
{'name': 'Uruguay',
'dial_code': '+598',
'emoji': '🇺🇾',
'code': 'UY',
'dial_code1': '598'},
{'name': 'Uzbekistan',
'dial_code': '+998',
'emoji': '🇺🇿',
'code': 'UZ',
'dial_code1': '998'},
{'name': 'Vanuatu',
'dial_code': '+678',
'emoji': '🇻🇺',
'code': 'VU',
'dial_code1': '678'},
{'name': 'Venezuela, Bolivarian Republic of Venezuela',
'dial_code': '+58',
'emoji': '🇻🇪',
'code': 'VE',
'dial_code1': '58'},
{'name': 'Vietnam',
'dial_code': '+84',
'emoji': '🇻🇳',
'code': 'VN',
'dial_code1': '84'},
{'name': 'Virgin Islands, British',
'dial_code': '+1284',
'emoji': '🇻🇬',
'code': 'VG',
'dial_code1': '1284'},
{'name': 'Virgin Islands, U.S.',
'dial_code': '+1340',
'emoji': '🇻🇮',
'code': 'VI',
'dial_code1': '1340'},
{'name': 'Wallis and Futuna',
'dial_code': '+681',
'emoji': '🇼🇫',
'code': 'WF',
'dial_code1': '681'},
{'name': 'Yemen',
'dial_code': '+967',
'emoji': '🇾🇪',
'code': 'YE',
'dial_code1': '967'},
{'name': 'Zambia',
'dial_code': '+260',
'emoji': '🇿🇲',
'code': 'ZM',
'dial_code1': '260'},
{'name': 'Zimbabwe',
'dial_code': '+263',
'emoji': '🇿🇼',
'code': 'ZW',
'dial_code1': '263'}];

class countrycodes{
    getCountryCodes(){
        return COUNTRY_CODES;
    }
}

export default new countrycodes();