import { useState } from 'react';
import Utils from '../utils';
import FieldBox from '../base/utils/fieldbox';
import Linkbutton from '../base/utils/linkbutton';
import { redirect } from 'react-router-dom';
import api from '../api';
import { useNavigate } from "react-router-dom";

export default function AppSignUp(props) {
    const [fields, setFields] = useState({firstName : "", lastName : "", email : "", phoneNumber : "", password : "", passwordC : "", remember : true});
    const [error, setError] = useState({});
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();

    const errors = {
        firstName : "Please enter a first name",
        lastName : "Please enter a last name",
        email : "Please enter a valid email address", 
        phoneNumber : "Please enter a valid phone number",
        password : "Please enter a password",
        passwordC : "Please confirm the password",
    }

    function handleFieldState(key, value) {
        switch (key) {
              default:
                  setFields({...fields, [key] : value.trim()});
                  validateField(key, value.trim());
                  break;
        }
    }

    function validateField(key, value){
        switch (key) {
              case "email":
              case "phone":
                  if(!Utils.validateField(key, value)) {
                      setError({...error, [key] : errors[key]});
                      return false;
                  }else{
                      setError({...error, [key] : ""});
                      return true;
                  }
                  break;
            case "passwordC":
                if(!value || value.length == 0) {
                  setError({...error, [key] : errors[key]});
                  return false;
                }else if(fields.password !== value){
                    setError({...error, [key] : "The passwords do not match", password : "The passwords do not match"});
                    return false;
                }else{
                    setError({...error, [key] : ""});
                    return true;
                }
                break;
              case "aadhar_file1":
              case "aadhar_file2":
              case "license":
              case "photo":
                  return true;
                  break;
              default:
                  if(!value || value.length == 0) {
                      setError({...error, [key] : errors[key]});
                      return false;
                  }else{
                      setError({...error, [key] : ""});
                      return true;
                  }
                  break;
        }
    }
  
      function validateAllFields(){
          let isValid = true;
          
          Object.keys(fields).forEach((key, index) => {
              console.log(key, validateField(key, fields[key]));
              if(!validateField(key, fields[key])){
                  isValid = false;
              }
          });
          return isValid;
      }

    function handleButtonClick(buttonType) {
        if(validateAllFields()){
            api.signup(fields.email, fields.password, fields.firstName, fields.lastName, fields.phoneNumber).then(function (response) {
                if(response.data.status){
                    // localStorage.setItem("user_id", response.data.data.id);
                    // localStorage.setItem("custom_token", response.data.data.custom_token);
                    // localStorage.setItem("access_token", response.data.data.access_token.token);
                    // localStorage.setItem("token_expiration_date", response.data.data.access_token.expiration_date);
                    // localStorage.setItem("logged_in_at", new Date());
                    // localStorage.setItem("email", fields.email);
                    // utils.setSavedObject("user", response.data.data.user);
                    // if(response.data.data.org){
                    //     utils.setSavedObject("org", response.data.data.org);
                    //     localStorage.setItem("org_id", response.data.data.org.id);
                    // }
                    // utils.setSavedList("roles", response.data.data.user.roles);
                    navigate("/login");
                       
                }else{
                    setError({...error, "global" : response.data.message});
                    setPageStatus("initial");
                }
                })
                .catch(function (error) {
                    setError({...error, "global" : error.response.data.message});
                    setPageStatus("initial");
                })
                .finally(function () {
                });
        }
    }

    return (
      <>
        {/* {
          {
          'initial':  */}
              {/* <div className="accountbaseinner border-box signup">
                  <div className="accountbaseinnerleft border-box">
                      <div className="inner border-box" style={{width : "70%"}}>
                          <h1>Sign Up</h1>
                          <p>Create your Super Admin account</p>
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="firstName" title="First Name" value={firstName} size="small"
                                  onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.firstName} />  
                            </div>
                            <div style={{width : "20px"}}></div>
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="lastName" title="Last Name" value={lastName} size="small"
                                  onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.lastName} />  
                            </div>
                          </div>
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="email" title="email" value={email} size="small"
                                  onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.email} />  
                            </div>
                            <div style={{width : "20px"}}></div>
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="phoneNumber" title="Phone Number" value={phoneNumber} size="small"
                                  onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.phoneNumber} />  
                            </div>
                          </div>
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="password" title="password" value={password} size="small"
                                  onSetFieldState={handleFieldState} type="password" errorDisplay="yes" errorText={error.password}/>
                            </div>
                            <div style={{width : "20px"}}></div>
                            <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                <FieldBox objectKey="passwordC" title="confirm password" value={passwordC} size="small"
                                  onSetFieldState={handleFieldState} type="password" errorDisplay="yes" errorText={error.passwordC}/>
                            </div>
                          </div>
                          
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                              <button className="bttn large solid one" onClick={(e) => handleButtonClick("signup")}>Sign up</button>
                          </div>
                      </div>
                      
                  </div>
                  <div className="accountbaseinnerright border-box">
                  </div>
              </div> */}

              <div className="mainview border-box bgcolorGrayE1 flex_vertical start_horizontal center_vertical">
                    <h1 className='weight100'>Sign up</h1>
                    <p className='weight200'>Create your Super Admin account.</p>
                    <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                        <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                            <FieldBox objectKey="firstName" title="First Name" value={fields.firstName} size="large"
                                onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.firstName} />  
                        </div>
                        <div style={{width : "20px"}}></div>
                        <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                            <FieldBox objectKey="lastName" title="Last Name" value={fields.lastName} size="large"
                                onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.lastName} />  
                        </div>
                    </div>
                    <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                        <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                            <FieldBox objectKey="email" title="email" value={fields.email} size="large"
                                onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.email} />  
                        </div>
                        <div style={{width : "20px"}}></div>
                        <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                            <FieldBox objectKey="phoneNumber" title="Phone Number" value={fields.phoneNumber} size="large"
                                onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.phoneNumber} />  
                        </div>
                    </div>
                    <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                        <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                            <FieldBox objectKey="password" title="password" value={fields.password} size="large"
                                onSetFieldState={handleFieldState} type="password" errorDisplay="yes" errorText={error.password}/>
                        </div>
                        <div style={{width : "20px"}}></div>
                        <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                            <FieldBox objectKey="passwordC" title="confirm password" value={fields.passwordC} size="large"
                                onSetFieldState={handleFieldState} type="password" errorDisplay="yes" errorText={error.passwordC}/>
                        </div>
                    </div>

                    {/* <div className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                        <FieldBox objectKey="email" title="email" value={fields.email}
                            onSetFieldState={handleFieldState} type="text" size="large"
                            errorDisplay="yes" errorText={error.email} />  
                    </div>
                    <div className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                        <FieldBox objectKey="password" title="password" value={fields.password} size="large"
                            onSetFieldState={handleFieldState} type="password" errorDisplay="yes" errorText={error.password}/>
                    </div> */}
                    {/* <div className="fieldouter border-box flex_horizontal end_horizontal center_vertical">
                        <a href="/account/password/reset" className="colorOne300span weight400" style={{fontSize : "14px"}}>Forgot your password?</a>
                    </div> */}
                    <div className="fieldouter border-box flex_horizontal start_horizontal center_vertical colorThree500span" style={{fontSize : "14px"}}>
                        {error.global ? error.global : ""}
                    </div>
                    <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                        <button className="bttn large solid one" style={{width : "100%"}} onClick={(e) => handleButtonClick("signup")}>Sign up</button>
                    </div>
                </div>
                <div className="extraview border-box flex_vertical start_horizontal center_vertical full_width">
                    {/* <div className="one border-box flex_horizontal start_horizontal center_vertical">
                        <a href="/account/password/reset" className="colorOne300span weight400">Forgot your password?</a>
                    </div>
                    <div style={{height : "10px"}} /> */}
                    <div className="one border-box flex_horizontal start_horizontal center_vertical">
                        Already have an account? &nbsp; <a href="/account/login" className="colorOne300span weight600">Sign in</a>
                    </div>
                </div>
              {/* , */}
          {/* // 'redirect': <Navigate to="/dashboard" />,
          }[pageStatus]
      } */}
      </>
    );
}