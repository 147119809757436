import utils from "../utils";
import { ContentList } from "./components_middle/ContentList";
import { ContentSplit } from "./components_middle/ContentSplit";
import { ContentTopbar } from "./components_middle/ContentTopbar";
import { ContentSchedule } from "./components_middle/ContentSchedule";
import { useFormState } from "react-dom";
import { ContentChat } from "./components_middle/ContentChat";
import { ContentChatArchives } from "./components_middle/ContentChatArchives";

export const ContentMiddle = (props) => {

    const getMiddleContentComponent = () =>{
        let contentMain = <></>;
        console.log("contentMainprops", props);
        switch(props.section.key) {
            case "dashboard":
                contentMain = <></>;
                break;
            case "interviews":
                switch(props.tab.key) {
                    case "updates":
                        contentMain = <ContentSplit {...props} />;
                        break;
                    case "schedule":
                        contentMain = <ContentSchedule {...props} />;
                        break;
                    case "management":
                        contentMain = <ContentSplit {...props} />;
                        break;
                    default:
                        contentMain = <></>;
                        break;
                }
                break;
            case "candidates":
                switch(props.tab.key) {
                    case "management":
                        contentMain = <ContentList {...props} />;
                        break;
                    case "add":
                        contentMain = <ContentSplit {...props} />;
                        break;
                    case "edit":
                        contentMain = <ContentSplit {...props} />;
                        break;
                    default:
                        contentMain = <></>;
                        break;
                }
                break;
            case "chats":
                switch(props.tab.key) {
                    case "live":
                        contentMain = <ContentChat {...props} />;
                        break;
                    case "archives":
                        contentMain = <ContentChatArchives {...props} />;
                        break;
                    default:
                        contentMain = <></>;
                        break;
                }
                break;
            default:
                contentMain = <></>;
                break;
        }
        console.log("contentMain", contentMain);
        return contentMain;
    }

    let contentTopbar = <ContentTopbar {...props} />;
    let contentMain = props.section && props.tab ? getMiddleContentComponent() : <></>;
    

    return (
        <>
            <div className="middlebase border-box flex_vertical start_vertical center_horizontal">
                {contentTopbar}
                <div className="contentbase border-box clearfix full_width spacer flex_vertical start_vertical center_horizontal">
                    {contentMain}
                </div>
            </div>
        </>
    );
}