import React from 'react';
import ChoiceBoxMulti from './choiceboxmulti';
import ChoiceBox from './choicebox';
import { SelectPretty } from './selectpretty';
import { DatePretty } from './datepretty';

export default function FieldBox(props) {

    var className = "fieldd border-box";
    var className1 = "fieldBoxInner1 border-box weight300";
    var className3 = "fieldBoxInner3 border-box weight300 colorThree500span"
    if (props.size){
        className += " " + props.size;
        className1 += " " + props.size;
        className3 += " " + props.size;
    }
    if (props.errorDisplay === "yes" && props.errorText){
        className += " error";
    }
    if (props.size){
        className += " " + props.size;
    }

    var style = {};
    if(props.type == "textarea"){
        style = {minHeight : props.minHeight ? props.minHeight : "100px"};
    }
    if(props.style){
        style = {...style, ...props.style};
    }
    
    function handleSelectPrettyClick(type, id, text){
        props.onSetFieldState(props.objectKey, id);
    }

    function handleDatePrettyClick(type, value){
        props.onSetFieldState(props.objectKey, value);
    }

    function handleChoiceBoxMultiClick(type, id, text){
        props.onSetFieldState(props.objectKey, id);
    }

    function handleChoiceBoxClick(type, id, text, selected){
        props.onSetFieldState(props.objectKey, id, "choicebox");
    }

    function handleChange(e){
      console.log("state1", e.target.value, props);
      props.onSetFieldState(props.objectKey, e.target.value);
    }

    function handleSearchChange(e){
      props.onSetFilter(props.choiceType, e.target.value);
    }

    function handleFileChange(e) {
        var file = e.target.files[0];
        props.onSetFieldState(props.objectKey, file);
        console.log("statefile", file);
    }

    

      return(
        <React.Fragment>
            {props.noTitle ? <React.Fragment /> : 
            <div className={className1}>
                {/* {props.title} {props.mandatory ? 
                  <React.Fragment>&nbsp;&nbsp;<span style={{fontSize : "10px"}} className="weight700 colorThree500span">✺✵</span></React.Fragment> : 
                  ""} */}
                  {props.title} {props.mandatory ? 
                  <React.Fragment>&nbsp;&nbsp;<span style={{fontSize : "10px"}} className="weight700 colorThree500span">✵</span></React.Fragment> : 
                  ""}
            </div>
            }
            {props.type == "choiceboxes" && props.choice_search ?
              <input type="text" placeholder="Type to filter" className="fieldd tiny border-box weight100" style={{width : "200px"}}
                value={props.filters && props.filters[props.choiceType] ? props.filters[props.choiceType] : ""}
                onChange={(e) => handleSearchChange(e)}
                // onKeyDown={_handleKeyDown} 
                />
             : <React.Fragment />}
            <div className="fieldBoxInner2 border-box" style={props.noTitle ? {marginTop : "0px"} : {marginTop : "5px"}}>
                {
                    {
                    'text': <input type={props.inputtype ? (props.inputtype == "number" ? "number" : "text") : "text"} 
                            placeholder={props.noTitle ? props.title : ""} 
                            className={className}
                            value={props.value} onChange={(e) => handleChange(e)}
                            // style={props.backgroundImage ? {
                            //     background: 'url("'+props.backgroundImage+'") no-repeat left',
                            //     backgroundSize: "12px"} : {}} 
                            />,
                    'password': <input type="password" placeholder="" 
                            className={className}
                            value={props.value} onChange={(e) => handleChange(e)} />,
                    'textarea': <textarea type="text" placeholder="" 
                            className={className}
                            onChange={(e) => handleChange(e)} 
                            style={{minHeight : props.minHeight ? props.minHeight : "100px"}}>{props.value}</textarea>,
                    'select': <select value={props.value} onChange={handleChange} 
                                className={className}>
                                {props.choices}
                            </select>,
                    'selectpretty': <SelectPretty type={props.choiceType} size={props.size} title={props.title}
                                        objects={props.choices} inlineTitle={props.inlineTitle}
                                        selected={props.selectedValue}
                                        onClick={handleSelectPrettyClick} noTitle={true} />,
                    'datepretty': <DatePretty type={props.objectKey} size={props.size} title={props.title}
                                        inlineTitle={props.inlineTitle}
                                        value={props.value} limits={props.limits} nullDateAllowed={props.nullDateAllowed == true ? true : false}
                                        onClick={handleDatePrettyClick} noTitle={true} />,
                    'date': <input type="date" placeholder="" 
                            className={className}
                            value={props.value} onChange={(e) => handleChange(e)} />,
                    'time': <input type="time" placeholder="" 
                            className={className}
                            value={props.value} onChange={(e) => handleChange(e)} />,
                    'file': <input type="file" placeholder="" 
                            className={className}
                            onChange={(e) => handleFileChange(e)} />,
                    'choiceboxes' : props.choices ? props.choices.map((obj) =>
                            <ChoiceBox type={props.choiceType} id={obj.id} text={obj.title} 
                            selected={props.selectedValues ? props.selectedValues.indexOf(obj.id) != -1 : false} 
                            onClick={handleChoiceBoxClick} />) : <React.Fragment />,
                    'choiceboxmulti' : <ChoiceBoxMulti type={props.choiceType} 
                            objects={props.choices} 
                            selected={props.selectedValue}
                            onClick={handleChoiceBoxMultiClick} />,
                    }[props.type]
                }
            </div>
            {props.errorDisplay === "yes" ? 
            <div className={className3}>
                  {props.errorText}
            </div> : <React.Fragment />}
        </React.Fragment>
            /* <div className="styleVendorPaymentRequestProcess second border-box weight700" style={{fontSize : "16px"}}>
              {props.data.inventory}
            </div> */
            /* <div className="styleVendorPaymentRequestProcess second border-box">
              <input type="number" min="0" placeholder="" className="fieldd small border-box" style={{width : "100px"}}
                        value={props.data.quantity} onChange={(e) => handleChange(e)} />
            </div> */
            /* <div className="styleVendorPaymentRequestProcess third border-box">
              <ChoiceBoxMulti type="transfer_status" 
                objects={[
                    {title : "YES", id : "yes"}, {title : "NO", id : "no"}]} 
                selected={props.data.transfer_status}
                onClick={that.handleChoiceBoxMultiClick} />
            </div> */
      );
  }