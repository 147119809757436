import { useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "../../base/utils/windowdimensions";
import { PanelLeft } from "./chat/PanelLeft";
import { PanelMiddle } from "./chat/PanelMiddle";
import apidata from "../../base/utils/data/apidata";
import { Overlay } from "../../base/utils/overlay/overlay";
import { PanelRight } from "./chat/PanelRight";
import { Loader } from "../../base/utils/loader";
import constants from "../../constants";
import FieldBox from "../../base/utils/fieldbox";

export const ContentChatArchives = (props) => {
    const [entriesperpage, setEntriesPerPage] = useState("25");
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [paramsFilter, setParamsFilter] = useState({status : "archived", timeline : "all", date_from : null, date_to : null});

    const [selectedThread, setSelectedThread] = useState(NaN);
    const [searchQuery, setSearchQuery] = useState("");
    const [threadsList, setThreadsList] = useState([]);
    const [allModels, setAllModels] = useState({});
    const [divK, setDivK] = useState({});
    const [divM, setDivM] = useState({});
    const [divH, setDivH] = useState({});
    const [isCustomerTyping, setIsCustomerTyping] = useState(false);
    const [shouldScroll, setShouldScroll] = useState(false);
    const [overlay, setOverlay] = useState(null);
    const [isPanelMiddleLoading, setIsPanelMiddleLoading] = useState(false);

    const chatRef = useRef(null);

    let isChecked = false;

    
    let windowDimensions = useWindowDimensions();
    // console.log("windowDimensions", useWindowDimensions());
    // console.log("reloadingforwindowDimensions");

    // useWindowDimensions((windowDimensions) => console.log("windowDimensions", windowDimensions));


    useEffect(() => {
    if(isChecked) return;
        isChecked = true;
        fetchData(entriesperpage, page, paramsFilter);
    
    }, []);

    useEffect(() => {
    if(chatRef.current && shouldScroll)
        chatRef.current.scrollTo({ behavior: "smooth", top: chatRef.current.scrollHeight});
        setShouldScroll(false);
    }, [shouldScroll]);

    useEffect(() => {
        if(chatRef.current)
            chatRef.current.scrollTo({ behavior: "smooth", top: chatRef.current.scrollHeight});
    }, [selectedThread]);

    const choiceBoxMultiClickHandler = (type, id, text) => {
        console.log(type, id, text)
        if (type == "entriesperpage") {
            handleEntriesChange(id);
        }
        // else if (type == "displaymode") {
        //     setDisplayMode(id);
        //     if(id == "grid"){
        //         setGridColumnsCount(getGridColumnsCount(windowDimensions));
        //     }else if(id == "list"){
        //         setGridColumnsCount(1);
        //     }
        // }
    }

    const handleEntriesChange = (value) => {
        setEntriesPerPage(value);
        setPage(1);
        fetchData(value, 1, paramsFilter);
    }

    const handleFilterChange = (type, value) => {
        console.log("datedateX1", type, value);
        if(["date_from", "date_to"].indexOf(type) > -1 && value){
            var newFilters = {...paramsFilter, [type] : value, timeline : "custom"};
            console.log("datedateX2", newFilters);
            setParamsFilter(newFilters);
        }else if(type == "timeline"){
            if(value == "custom"){
                setParamsFilter({...paramsFilter, [type] : value});
                return;
            }else{
                let timelinerange = constants.getDateRangeFromTimeline(value);
                var newFilters = {...paramsFilter, date_from : timelinerange.dateFrom, date_to : timelinerange.dateTo, timeline : value};
                console.log("datedateX3", newFilters);
                setParamsFilter(newFilters);
            }
            
        }

        fetchData(entriesperpage, page, newFilters);
    }

    function handleFieldState(key, value) {
        if(Object.keys(paramsFilter).indexOf(key) > -1 && value){
            var comparison = value == paramsFilter[key];
            if(["date_from", "date_to"].indexOf(key) > -1){
                comparison = constants.get_TimeComparison(value, paramsFilter[key]);
            }
            if(!comparison){
                handleFilterChange(key, value);
            }
        }
    }


    async function fetchData(limit, pageNo, params_filter, isReload = true){
        setIsLoading(true);
        const res = await apidata.fetchMonitorContentListData(props.section.key, props.tab.key, limit, pageNo, params_filter, isReload);
        setFetchedData(res.status, res.data ? res.data : []);
    }
    
    function setFetchedData(status, data){
        if(status === "success"){
            var temp_threadsList = [];
            var temp_allModels = {};
            var div_lasttimes= {};
            var div_lastmessages= {};
            var div_lastmessagetimes = {};
            
            data.forEach((thread) => {
                var code = thread.id + "";
                var currentModel = null;
                var models = [];
                var messages_count = 0;

                thread.messages.forEach((object) => {
                    if(!currentModel || (currentModel && !constants.get_TimeComparison(currentModel.time, object.timestamp.$date))){
                        var textH = "";var textM = "";
                        var message= {
                            senderName:null, role:null, time:object.timestamp.$date, textH : textH, textM : textM
                        };
                        models.push(message);
                        div_lasttimes[code] = message['time'];
                        div_lastmessages[code] = textM;
                        div_lastmessagetimes[code] = message['time'];
                    }

                    var textH = '';var textM = '';
                    if (object.notification){
                        textH = object.type.toUpperCase(); textM = object.text;
                    } else{
                        textH = ''; textM = object.text;
                    }

                    var message= {
                        senderName:object.role, role:object.role, time:object.timestamp.$date, textH : textH, textM : textM,
                    }
                    models.push(message);
                    messages_count++;
                    div_lasttimes[code] = object.timestamp.$date;
                    div_lastmessages[code] = textM;
                    div_lastmessagetimes[code] = object.timestamp.$date;
                    currentModel = message;
                });

                let obj = {
                    comm_type : thread.comm_type, 
                    name : thread.name, 
                    customer_id : thread.customer_id,
                    mobile : thread.mobile, 
                    assigned_executive : thread.assigned_executive, 
                    new : false, 
                    first_customer_message_time : null, 
                    first_executive_message_time : null,
                    customer_info : thread.customer ? {customer_id : thread.customer.id, display_name : thread.customer.display_name} : null,
                    control_status : thread.control_status,
                    last_time : div_lasttimes[code],
                    last_message : div_lastmessages[code],
                    last_messagetime : div_lastmessagetimes[code],
                    id : thread.id + "",
                    messages_count : messages_count,
                    country : thread.country && thread.country_code ? {name : thread.country, code : thread.country_code} : null,
                };
                
                temp_threadsList.push(obj);
                
                temp_allModels[code] = models;
            });
            temp_threadsList.sort(function(a, b) {
                return b.last_messagetime - a.last_messagetime;
            });
            temp_threadsList.sort(function(a, b) {
                    return b.last_messagetime - a.last_messagetime;
            });
            setThreadsList([...temp_threadsList]);
            setAllModels({...temp_allModels});
        }else{
            // setContentData([]);
        }
        setIsLoading(false); 
    }

    const setChatState = (threads_list = null, all_models = null, divK = null, divM = null, divH = null, isEmpty = false, thread_id = null) => {
        console.log("setChatState", threads_list, all_models, divK, divM, divH, isEmpty, thread_id);
        if(threads_list){
            setThreadsList([...threads_list]);
        }
        if(all_models){
            setAllModels({...all_models});
        }
        if(divK){
            setDivK({...divK});
        }
        if(divM){
            setDivM({...divM});
        }
        if(divH){
            setDivH({...divH});
        }
        console.log("viewtypeXX", "setChatState", thread_id, selectedThread);
        if(thread_id && thread_id == selectedThread){
            setShouldScroll(true);
        }
        // setAllModels([...all_models[thread_id]]);
        // setDivK([...var_divK[thread_id]]);
        // setDivM([...var_divM[thread_id]]);
        // setDivH([...var_divH[thread_id]]);
        // setIsEmpty(var_isEmpty ? true : false);
        // scrollToBottom();
    }

      

    const searchQueryHandler = (query) => {
        setSearchQuery(query);
        // chat_utils_admin.searchThreads(query, setChatState);
    }

    const threadClickHandler = (thread_id) => {
        setSelectedThread(thread_id);
        // API.getChatUserDetails(tag, this);
        // API.getSupportChatArchivesByKey(tag, this);
        // API.getOrderDetailsPaginate(tag, [], this);
        // API.tabClickCall(tag, that);
      }

    const rowClickHandler = () => {

    }
    const buttonClickHandler = (buttonType, data=null) => {
        switch (buttonType) {
            case "navigation":
                var pageN = page;
                if(data == "prev"){
                    if(page > 1){
                        pageN = page - 1;
                        setPage(pageN);
                    }
                }else{
                    pageN = page + 1;
                    setPage(pageN);
                }
                fetchData(entriesperpage, pageN, paramsFilter);
                break;
            case "archive":
                initiateOverlay("confirmation1", data);
                break;
            case "handoff":
                initiateOverlay("confirmation1", data);
                break;
            case "chat_initiate":
                initiateOverlay("chat_initiate");
                break;
            default:
                break
        }
    }

    const initiateOverlay = (type, data=null) => {
        setOverlay({isShowing : true, type : type, data : data});
    }

    const onOverLayClose = () => {
        setOverlay(null);
    }

    const overlayButtonClickHandler = (buttonType, data=null) => {
        if (buttonType == "button_positive"){
            switch(data.key){
                case "handoff":
                    console.log("handingoff");
                    onOverLayClose();
                    // handoff(data.thread_id);
                    break;
                case "archive":
                    console.log("handingoff", "archive");
                    onOverLayClose();
                    // archiveThread(data.thread_id);
                    break;
            }
        }
    }


    return (
        <>
        {
            {
                true : <Loader innerstyle={{width : "20%"}}/>,
                false : 
                <>
                    <div className="contentchatbase border-box full_height full_width flex_vertical start_vertical center_horizontal">
                        <div className="header border-box full_width flex_horizontal center_vertical center_horizontal">
                            <div className="filters border-box flex_horizontal center_vertical start_horizontal">
                                {/* <SelectPretty size="medium" title="View" type="view" inlineTitle={true}
                                    objects={props.tab.filtersPrimary.view.map(o => ({title : o.title, id : o.id}))} 
                                    selected={paramsFilter.view}
                                    onClick={handleSelectPrettyClick} /> */}
                                <FieldBox objectKey="timeline" title="Timeline" selectedValue={paramsFilter.timeline} size="medium"
                                            onSetFieldState={handleFieldState} type="selectpretty"
                                            choiceType="timeline" noTitle={true} inlineTitle={true}
                                            choices={props.tab.filtersPrimary.timeline.map(o => ({title : o.title, id : o.id}))}
                                            errorDisplay="no"/>
                                {/* {paramsFilter.timeline == "custom" ? <> */}
                                <FieldBox objectKey="date_from" title="From" value={paramsFilter.date_from} size="medium"
                                            onSetFieldState={handleFieldState} type="datepretty"
                                            noTitle={true} inlineTitle={true} limits="noFuture" nullDateAllowed={true}
                                            errorDisplay="no"/>
                                <FieldBox objectKey="date_to" title="To" value={paramsFilter.date_to} size="medium"
                                            onSetFieldState={handleFieldState} type="datepretty"
                                            noTitle={true} inlineTitle={true} limits="noFuture" nullDateAllowed={true}
                                            errorDisplay="no"/>
                                {/* </> : <></>} */}
                            </div>
                            <div className="spacer" />
                            {/* <button className="bttn small solid one" onClick={(e) => buttonClickHandler("chat_initiate")}>Initiate</button> */}
                        </div>
                        
                        <div className="main border-box clearfix spacer full_width flex_horizontal center_vertical center_horizontal">
                            <PanelLeft 
                                handleThreadClick={threadClickHandler} selectedThread={selectedThread}
                                searchQuery={searchQuery} onSetSearchQuery={searchQueryHandler} 
                                threadsList={threadsList} allModels={allModels}
                                buttonClickHandler={buttonClickHandler} choiceBoxMultiClickHandler={choiceBoxMultiClickHandler}
                                entriesperpage={entriesperpage} page={page}
                                pagetab = "archives" />
                            <PanelMiddle chatRef={chatRef} selectedThread={selectedThread}
                                selectedThreadDetails={threadsList.find((thread) => thread.id == selectedThread)}
                                searchQuery={searchQuery} onSetSearchQuery={searchQueryHandler}
                                threadsList={threadsList} allModels={allModels} 
                                // onArchiveThread={onArchiveThread}
                                // onHandoff={onHandoff}
                                isLoading={isPanelMiddleLoading}
                                buttonClickHandler={buttonClickHandler}
                                rowClickHandler={rowClickHandler} 
                                // messageSendHandler={messageSendHandler}
                                // typingAlertHandler={typingAlertHandler}
                                isCustomerTyping={isCustomerTyping}
                                pagetab = "archives" />
                            <PanelRight 
                                selectedThread={selectedThread}
                                selectedThreadDetails={threadsList.find((thread) => thread.id == selectedThread)}
                                threadsList={threadsList} allModels={allModels}
                                pagetab = "archives" />
                        </div>
                        
                        {/* <div className="footer border-box full_width flex_horizontal center_vertical center_horizontal bgcolorGrayE1">
                            
                        </div> */}
                    </div>
                    {overlay && overlay.isShowing ? <Overlay type={overlay.type} data={overlay.data} onOverLayClose={onOverLayClose} overlayButtonClickHandler={overlayButtonClickHandler} /> : <></>}
                </>
            }[isLoading]
        }
        </>
        
    );
}