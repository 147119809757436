import { Navigate, useNavigate, useParams } from 'react-router-dom';
import './orgadmin.css';
import { useEffect, useState } from 'react';
import constants from '../constants';
import utils from '../utils';

export default function AppOrgAdminSection(props) {
    const navigate = useNavigate();
    const {section} = useParams();

    let loaded = false;

    const [liveSection, setLiveSection] = useState(section);

    let sectionData = constants.getSidebarFooterSections(section);

    let content = <></>;
    if(utils.isLoggedIn() == false){
        content = <Navigate to="/account" />
    }else{
        if(!sectionData) {
            content = <Navigate to="/org/integrations" />
            // content = <Navigate to="/integrations/pages/shopify" />
        }else{
            let tabData = constants.getSidebarFooterTabByIndex(section, 0);
            content = <Navigate to={"/org/" + section + "/pages/" + tabData.key} />
        }
    }

    // const navigationHandler = () => {
    //     loaded = true;
    //     if(utils.isLoggedIn() == false){
    //         navigate("/account");
    //     }else{
    //         if(!sectionData) {
    //             navigate("/monitor/dashboard");
    //         }else{
    //             let tabData = constants.getMonitorTabByIndex(section, 0);
    //             navigate("/monitor/" + section + "/pages/" + tabData.key);
    //         }
    //     }
    // }

    // useEffect(() => {
    //     navigationHandler();
    // }, []);

    
    return (
        <>
            {content}
        </>
    );


    
}