import { useEffect, useState } from "react";
import { EntriesPerPage } from '../../base/utils/entriesperpage';
import { Pagination } from "../../base/utils/pagination";
import { DisplayMode } from "../../base/utils/displaymode";
import { Spacer } from "../../base/utils/spacer";
import apidata from "../../base/utils/data/apidata";
import { ContentListItems } from "./ContentListItems";
import { useWindowDimensions } from "../../base/utils/windowdimensions";
import { ContentSplitOne } from "./ContentSplitOne";
import constants from "../../constants";

export const ContentSplit = (props) => {
    const [contentData, setContentData] = useState({});
    const [entriesperpage, setEntriesPerPage] = useState("30");
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(NaN);
    const [paramsFilter, setParamsFilter] = useState({});
    const [displayMode, setDisplayMode] = useState("grid");
    const [gridColumnsCount, setGridColumnsCount] = useState(3);
    const [widthOne, setWidthOne] = useState("30%");


    let isChecked = false;

    let windowDimensions = useWindowDimensions();
    // console.log("windowDimensions", useWindowDimensions());
    // console.log("reloadingforwindowDimensions");

    // useWindowDimensions((windowDimensions) => console.log("windowDimensions", windowDimensions));

    useEffect(() => {
      if (isChecked) return;
      isChecked = true;
      
      fetchData();
      
    }, []);

    useEffect(() => {
        console.log("windowDimensions", windowDimensions);
        if(displayMode == "grid"){
            setGridColumnsCount(getGridColumnsCount(windowDimensions));
        }
    }, [windowDimensions]);

    const choiceBoxMultiClickHandler = (type, id, text) => {
        console.log(type, id, text)
        if (type == "entriesperpage") {
            handleEntriesChange(id);
        }else if (type == "displaymode") {
            setDisplayMode(id);
            if(id == "grid"){
                setGridColumnsCount(getGridColumnsCount(windowDimensions));
            }else if(id == "list"){
                setGridColumnsCount(1);
            }
        }
    }

    const getGridColumnsCount = (windowDimensions) => {
        console.log("getGridColumnsCount", windowDimensions);
        if((windowDimensions.width-200) < 1024){
            return 1;
        }else if((windowDimensions.width-200) < 1600){
            return 2;
        }else{
            return 3;
        }
    }

    const buttonClickHandler = (buttonType, data) => {
        console.log("handleButtonClick", buttonType, data);
        switch (buttonType) {
            case "navigation":
                var pageN = page;
                if(data == "prev"){
                    if(page > 1){
                        pageN = page - 1;
                        setPage(pageN);
                    }
                }else{
                    pageN = page + 1;
                    setPage(pageN);
                }
                fetchData(entriesperpage, pageN);
                break;
            default:
                break;
        }
    }

    const handleEntriesChange = (value) => {
        setEntriesPerPage(value);
        setPage(1);
        fetchData(value, 1);
    }

    const handleFilterChange = (type, value) => {
      console.log("handleFilterChange", type, value);
        paramsFilter[type] = value;
        setParamsFilter(paramsFilter);
        setPage(1);
        setEntriesPerPage("30");
        fetchData("30", 1, paramsFilter);
    }


    const twoFooter = () => {
        let shouldLoadFooter = false;
        switch(props.section.key){
            case "interviews":
                switch(props.tab.key){
                    case "management":
                        shouldLoadFooter = true;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        if(shouldLoadFooter){
            return (
                <div className="footer border-box full_width flex_horizontal center_vertical center_horizontal bgcolorGrayE1">
                    <EntriesPerPage
                        onClick={choiceBoxMultiClickHandler}
                        selectedValue={entriesperpage ? entriesperpage.toLowerCase() : "30"}
                    />
                    <Spacer/>
                    <Pagination buttonClickHandler={buttonClickHandler} page={page} />
                    <Spacer/>
                    <DisplayMode
                        onClick={choiceBoxMultiClickHandler}
                        selectedValue={displayMode ? displayMode.toLowerCase() : "list"}
                    />
                </div>
            );
        }
        return <></>;
    }

    async function fetchData(limit = "30", pageNo = 1, params_filter = {}, isReload = true){
        setIsLoading(true);
        switch(props.section.key){
            case "interviews":
                switch(props.tab.key){
                    case "updates":
                        let range = constants.getDateRangeFromTimeline("today");
                        const res = await apidata.fetchInterviewData({date_from : range.dateFrom.toDateString(), date_to : range.dateTo.toDateString()});
                        console.log("resX", res);
                        setFetchedData(res.status, res.data ? {interviews : res.data} : {});
                        let set_ids = res.data ? [... new Set(res.data.map((item) => item.interview_set_id))] : [];
                        const res1 = await apidata.fetchInterviewSetData({ids : set_ids.join(",")});
                        console.log("res1", res1);
                        setFetchedData(res1.status, res1.data ? {interview_sets : res1.data} : {});
                        return;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        const res = await apidata.fetchMonitorContentSplitData(props.section.key, props.tab.key, limit, pageNo, params_filter, isReload);
        setFetchedData(res.status, res.data ? res.data : []);
    }
  
    function setFetchedData(status, data){
        if(status === "success"){
            setContentData({...contentData, data});
        }else{
            // setContentData({});
        }
        setIsLoading(false); 
    }


    return (
        <>
            <div className="contentsplitbase border-box full_height full_width flex_vertical start_vertical center_horizontal">
                <div className="main border-box clearfix spacer full_width full_height flex_horizontal start_vertical center_horizontal">
                    <div className="one border-box clearfix full_height flex_vertical start_vertical center_horizontal bgcolorGrayE1" style={{width : widthOne}}>
                        <ContentSplitOne {...props} data={contentData} isLoading={isLoading} displayMode={displayMode} gridColumnsCount={gridColumnsCount} />
                    </div>
                    <div style={{width : "15px"}} />
                    <div className="two border-box spacer clearfix full_height flex_vertical start_vertical center_horizontal bgcolorGrayE1">
                        {/* <div className="header border-box full_width flex_horizontal center_vertical center_horizontal">

                        </div> */}
                        {/* <ContentListItems {...props} data={contentData} isLoading={isLoading} displayMode={displayMode} gridColumnsCount={gridColumnsCount} /> */}
                        <Spacer />
                        {twoFooter()}
                    </div>
                </div>
            </div>
        </>
    );
}