import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#f1ae37',
    },
    '& .MuiRating-iconHover': {
      color: '#f1ae37',
    },
  });

export const Rating1 = (props) => {
    let size = props.size ? props.size : "small";
    let value = props.value ? props.value : 0;
    return(
        <Stack spacing={0}>
            <StyledRating precision={0.1} name="rating1" defaultValue={value} size={size} max={1} readOnly />
        </Stack>
    );
};