import { ClickAwayListener } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

export const SelectPretty = (props) => {

    const boxRef = useRef(null);

    const [clicked, setClicked] = useState(false);
    const [offsetTop, setOffsetTop] = useState(0);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [offsetHeight, setOffsetHeight] = useState(0);

    let className = "prettybox select border-box";
    let classNameInner = "inner border-box flex_horizontal space_between_horizontal center_vertical";

    useEffect(() => {
      setOffsetTop(boxRef.current.offsetTop);
      setOffsetLeft(boxRef.current.offsetLeft);
      setOffsetHeight(boxRef.current.offsetHeight);

      console.log("offsets", offsetTop, offsetLeft, offsetHeight);
    }, [boxRef]);

    if(props.size){
        className += " " + props.size;
    }

    const boxClickHandler = () => {
      setClicked(!clicked);
    }

    const optionClickHandler = (type, id, text, selected) => {
      setClicked(false);
      if(!selected){
          props.onClick(type, id, text);
      }
    }

    const clickAwayHandler = () => {
      setClicked(false);
    }

    console.log("selectpretty", props);

    return(
      <div className={className} ref={boxRef}>
          <div className={clicked ? classNameInner + " clicked" : classNameInner} onClick={boxClickHandler}>
              <div className="text border-box"><span className="weight300">{props.inlineTitle ? props.title : <></>}</span>{props.selected ? <span className="weight300"> : </span> : <></>}<span className="weight500">{props.selected ? props.objects.find(o => o.id == props.selected).title : <></>}</span></div>
              <div className="arrow border-box"><i className="ti ti-triangle-inverted"></i></div>
          </div>
          {clicked ?
          <ClickAwayListener onClickAway={clickAwayHandler}>
          <div className="dropdown border-box absolute" style={{top : offsetTop+offsetHeight+2, left : offsetLeft+10, zIndex : "950", backgroundColor : "#ffffff"}}>
              {props.objects.map((obj) =>
                  <SelectPrettyOption 
                    type={props.type} id={obj.id} text={obj.title} key={obj.id}
                    selected={props.selected == obj.id} onClick={optionClickHandler} />
              )}
          </div>
          </ClickAwayListener> : <></>}
      </div>
    );
}

const SelectPrettyOption = (props) => {
  let className = "row border-box flex_horizontal space_between_horizontal center_vertical";
  let classNameText = "text border-box";
  if (props.selected){
    className += " selected";
    classNameText += " weight400";
  }else{
    classNameText += " weight300";
  }

  const clickHandler = () => {
    // if(!props.selected){
        props.onClick(props.type, props.id+"", props.text, props.selected);
    // }
  }

      return(
        <>
            <div className={className} onClick={clickHandler}>
                <div className={classNameText}>{props.text}</div>
            </div>
        </>
      );
}