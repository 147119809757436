import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import './chat.css';
import constants from '../constants';
import utils from '../utils';
import parse from 'html-react-parser';
import { getDate } from 'date-fns';
import { render } from '@testing-library/react';


export default function ChatRow(props) {
    const navigate = useNavigate();

    let isChecked = false;

    let date = new Date(props.data.time);
    let dateString = constants.get_TimeString(date);

    useEffect(() => {
        isChecked = true;
        // fetchData();

        console.log("viewType", props.viewType, props);
        
      }, []);

    function buttonClickHandler(button, extra) {
        // setLiveSection(section);
    }

    const getMessageText = (type) => {
        var className = "text border-box clearfix fontspx13 weight300";
        if(type == "leftalign"){
            className += " leftalign";
        }else{
            className += " rightalign";
        }
        if(props.searchQuery && props.data.textM.toLowerCase().indexOf(props.searchQuery.toLowerCase()) != -1){
            className += " bgcolorTwo100 colorGrayE240span";
        }else{
            if(type == "leftalign"){
                className += " bgcolorGrayE40 colorGrayE220span";
            }else{
                className += " bgcolorGrayE15 colorGrayE220span";
            }
        }

        var textContent = <></>;
        if(props.data.isTyping){
            textContent = <span className="weight700 colorOne500span">Typing ...</span>
        }else{
            console.log("text", props.data.textM, utils.linkify(props.data.textM));
            // return (
                // console.log("text", utils.urlify(text)),
                // utils.urlify(text)
                // props.searchQuery ? parse(utils.highlight(utils.linkify(text), props.searchQuery)) : parse(utils.linkify(text))
                textContent = parse(utils.linkify(props.data.textM))
                
                // text
                // text && text.includes("http") ? <a href={text} target="_blank">{text}</a> : <div>{text}</div>
            // );    
        }

        return (
            <div className={className}>{textContent}</div>
        )

        // if(type == "leftalign"){
        //     return (
        //         <div className={props.searchQuery && props.data.textM.toLowerCase().indexOf(props.searchQuery.toLowerCase()) != -1 ? "text border-box clearfix leftalign bgcolorTwo100 colorGrayE240span fontspx13 weight300" : "text border-box clearfix leftalign bgcolorGrayE40 colorGrayE220span fontspx13 weight300"}>{getSenderText(props.data.textM)}</div>
        //     )
        // }else{
        //     return (
        //         <div className={props.searchQuery && props.data.textM.toLowerCase().indexOf(props.searchQuery.toLowerCase()) != -1 ? "text border-box clearfix rightalign bgcolorTwo100 colorGrayE240span fontspx13 weight300" : "text border-box clearfix rightalign bgcolorGrayE15 colorGrayE220span fontspx13 weight300"}><span>{getSenderText(props.data.textM)}</span></div>
        //     )
        // }
    }

    const getBuffer = () => {
        return (
            props.nextModel && props.data.role == props.nextModel.role ? null : (<div className="buffer" />)
        )
    }

    const getDateTimeBuffer = () => {
        dateString = constants.get_DateString1Date(date);
        return (
            <div className="message clearfix border-box">
                <div className="buffer" />
                <div className="divider centeralign border-box clearfix weight700 flex_horizontal center_vertical">
                    <div className="dividerline bgcolorGrayE100 spacer"></div>
                    <div className="dividertext colorGrayE100span fontspx15 weight600">{dateString}</div>
                    <div className="dividerline bgcolorGrayE100 spacer"></div>
                </div>
                <div className="buffer" />
            </div>
        )
    }

    const getSenderImage = (role, senderView) => {
        var src = "";
        var size = "";

        if (senderView == "noImgName"){
            return(<></>)
        }

        switch(senderView){
            case "noName":
                size = "15px";
                break;
            case "full":
                size = "40px";
                break;
            default:
                break;
        }
        switch(role){
            case "assistant":
                src = "/images/assistant_icon.png";
                break;
            case "user":
                src = "/images/user_icon.png";
                break;
            default:
                break;
        }

        return (
            <div className="senderimage border-box"><img height={size} width={size} src={src} /></div>
        )
    }

    const getSenderName = (renderType, role, senderView) => {
        var name = "";
        var alignment = "";
        
        if (senderView == "full"){
            if(role == "assistant"){
                name = props.data.senderName;
                if(renderType == "customer"){alignment = " leftalign";}
                else if(renderType == "admin"){alignment = " rightalign";}
            }else if(role == "user"){
                if(renderType == "customer"){name = "You"; alignment = " rightalign";}
                else if(renderType == "admin"){name = props.customerName ? props.customerName : props.data.senderName; alignment = " leftalign";}
            }
            return(
                <div className={"sendername border-box blackspan weight500 fontspx13 capitalized" + alignment}>{name}</div>
            )
        }else{
            return (<></>)
        }
    }

    const getSenderTime = (renderType, role, senderView) => {
        var alignment = "";
        
        if(senderView == "noImgName"){
            return(<></>)
        }else{
            if(role == "assistant"){
                if(renderType == "customer"){alignment = " leftalign";}
                else if(renderType == "admin"){alignment = " rightalign";}
            }else if(role == "user"){
                if(renderType == "customer"){alignment = " rightalign";}
                else if(renderType == "admin"){alignment = " leftalign";}
            }
            return(
                <div className={"sendertime border-box weight400 fontspx10 colorGrayE110span" + alignment}>{dateString}</div>
            )
        }
    }

    const getSenderNameAndTime = (renderType, role, senderView) => {
        return(
            <div className="sendertext border-box clearfix">
                {getSenderName(renderType, role, senderView)}
                {getSenderTime(renderType, role, senderView)}
            </div>
        )
    }

    const getSender = (renderType, role, senderView) => {
        if(senderView == "noImgName"){
            return(<></>)
        }else{
            if(renderType == "customer"){
                if(role == "assistant"){
                    return(
                        <div className="sender border-box clearfix">
                            {getSenderImage(role, senderView)}
                            {getSenderNameAndTime(renderType, role, senderView)}
                        </div>
                    )
                }else if(role == "user"){
                    return(
                        <div className="sender border-box clearfix">
                            {getSenderNameAndTime(renderType, role, senderView)}
                            {getSenderImage(role, senderView)}
                        </div>
                    )
                }
            }else if(renderType == "admin"){
                if(role == "assistant"){
                    return(
                        <div className="sender border-box clearfix">
                            {getSenderNameAndTime(renderType, role, senderView)}
                            {getSenderImage(role, senderView)}
                        </div>
                    )
                }else if(role == "user"){
                    return(
                        <div className="sender border-box clearfix">
                            {getSenderImage(role, senderView)}
                            {getSenderNameAndTime(renderType, role, senderView)}
                        </div>
                    )
                }
            }
        }
    }


    const getRender = (renderType) => {
        if(!props.data.role){return(<>{getDateTimeBuffer()}</>)}

        var messageAlignment = "";
        var senderView = "";
        var messageBoxClassName = "";

        if(props.prevModel && props.prevModel.role == props.data.role){
            if(constants.get_TimeComparison1(props.prevModel.time, props.data.time)){
                senderView = "noImgName";
            }else{
                senderView = "noName";
            }
        }else{
            senderView = "full";
        }



        if(renderType == "customer"){
            if(props.data.role == 'assistant'){
                messageAlignment = "leftalign";
                messageBoxClassName = "others rightalign border-box";
            }else{
                messageAlignment = "rightalign";
                messageBoxClassName = "self leftalign border-box";
            }
        }else if(renderType == "admin"){
            if(props.data.role != 'assistant'){
                messageAlignment = "leftalign";
                messageBoxClassName = "others rightalign border-box";
            }else{
                messageAlignment = "rightalign";
                messageBoxClassName = "self leftalign border-box";
            }
        }

        return(
            <div className="message clearfix border-box">
                <div className={messageBoxClassName}>
                    {getSender(renderType, props.data.role, senderView)}
                    {getMessageText(messageAlignment)}
                    {getBuffer()}
                </div>
            </div>
        );
    }

  
    return (
        <>
            {
                getRender(props.viewType)
            }
        </>
    );
}