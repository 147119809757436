import { useEffect, useState } from "react";
import FieldBox from "../../../base/utils/fieldbox";
import ChatRow from "../../../chat/ChatRow";
import { Loader } from "../../../base/utils/loader";
import { LightTooltip } from "../../../base/utils/tooltip/lighttooltip";

export const PanelMiddle = (props) => {

    const [text, setText] = useState("");
    const [canSend, setCanSend] = useState(true);

    const fieldStateHandler = (key, value) => {
        switch (key) {
            case "searchquery":
                props.onSetSearchQuery(value);
                break;
            default:
                break;
        }
    }

    // const getContent = () => {
    //     return (
    //         const rows = arr.map((obj, index) =>
    //             <Row tag={that.props.tag} onRowClick={this.props.onRowClick} scrollChat={this.props.scrollChat} searchText={this.props.searchText}
    //                />
    //           );
    //     )
    // }

    const getCustomerName = () => {
        let thread = props.selectedThreadDetails;
        return thread && thread.customer_info && thread.customer_info.display_name ? thread.customer_info.display_name : "Customer";
    }

    const getCustomerPhoneNumber = () => {
        let thread = props.selectedThreadDetails;
        return thread && thread.customer_id ? thread.customer_id : null;
    }

    const getCommType = () => {
        let thread = props.selectedThreadDetails;
        return thread && thread.comm_type ? thread.comm_type : null;
    }

    const getRowsArray = () => {
        return props.allModels[props.selectedThread] ? props.allModels[props.selectedThread] : [];
    }

    const getCanSend = () => {
        let thread = props.selectedThreadDetails;
        console.log('typingstateXY', thread);
        return thread && thread.control_status == "human" ? true : false;
    }

    // const buttonClickHandler = (buttonType, data=null) => {
    //     console.log("buttonType", buttonType);
    //     switch (buttonType) {
    //         case "archive":
    //             props.onArchiveThread(props.selectedThread);
    //             // console.log("thread", props.selectedThread);
    //             break;
    //         // case "handoff":
    //         //     props.onHandoff(props.selectedThread);
    //         //     // console.log("thread", props.selectedThread);
    //         //     break;
    //         default:
    //             break
    //     }
    // }

    const handleSendClick = (text) => {
        var message = text;
        if(message.trim() != ""){
          // this.sendieRef.current.value = "";
            setText("");
        //   this.props.onGlobalSend(message);
            props.messageSendHandler(props.selectedThread, message);
        }
        
      }
    
      const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          var message = text;
          if(message.trim() != ""){
            // this.sendieRef.current.value = "";
                setText("");
            // this.props.onGlobalSend(message);
                props.messageSendHandler(props.selectedThread, message);
          } 
        }
      }

    const handleTextChange = (e) => {
        // console.log(e.target.value);
        setText(e.target.value);
        // console.log("typingstate", e.target.value.trim(), e.target.value.trim() != "" ? true : false);
        props.typingAlertHandler(props.selectedThread, e.target.value.trim() != "" ? true : false);
    }

    return (
        <>
        {
            {
                true: 
                    <Loader innerstyle={{width : "20%"}}/>,
                false: 
                    <>
                        <div className="panelmiddle border-box flex_vertical start_vertical center_horizontal full_height spacer bgcolorGrayE5">
                            {props.selectedThread ?
                            <div className="header border-box flex_horizontal center_vertical center_horizontal full_width">
                                    <span>
                                        {getCommType() == "whatsapp" 
                                            ? 
                                        <LightTooltip title="WhatsApp"><i className="ti ti-brand-whatsapp"></i></LightTooltip>
                                            : 
                                        <LightTooltip title="Web Chat"><i className="ti ti-world"></i></LightTooltip>}
                                    </span>
                                    <div style={{width : "10px"}}></div>
                                    <span className="weight600 fontspx20">{getCustomerName()}</span>
                                    <div style={{width : "10px"}}></div>
                                    <span className="weight200 fontspx14">{getCustomerPhoneNumber() ? "("+getCustomerPhoneNumber()+")" : ""}</span>
                                    <div className="spacer" />
                                    {props.pagetab == "live" ?
                                    <>
                                    <button className="bttn small border three" onClick={(e) => props.buttonClickHandler("archive", {key : "archive", thread_id : props.selectedThread, title : "Archive?", subtitle : "Are you sure you wish to archive the thread?", title_button_positive : "Yes", title_button_negative : "No"})}>Archive</button>
                                    <button className="bttn small solid one" onClick={(e) => props.buttonClickHandler("handoff", {key : "handoff", thread_id : props.selectedThread, title : "Take over?", subtitle : "Are you sure you wish to take over the control of the thread?", title_button_positive : "Yes", title_button_negative : "No"})}>Take Over</button>
                                    </> : <></>}
                            </div>
                            : <></>}
                            <div className="main border-box flex_vertical center_vertical center_horizontal full_width spacer clearfix">
                                    <div className="chatbox border-box full_width full_height" 
                                        ref={props.chatRef}>
                                        {getRowsArray() && getRowsArray().length != 0 ? 
                                            getRowsArray().map((obj, index) =>
                                                <ChatRow key={index} data={obj} onRowClick={props.rowClickHandler}
                                                    searchQuery={props.searchQuery && props.searchQuery.trim() != "" ? props.searchQuery : null}
                                                    viewType="admin" customerName={getCustomerName()}
                                                    //  scrollChat={scrollToBottom}
                                                    prevModel={getRowsArray().indexOf(obj) == 0 ? null : getRowsArray()[getRowsArray().indexOf(obj)-1]} 
                                                    nextModel={getRowsArray().indexOf(obj) == (getRowsArray().length-1) ? null : getRowsArray()[getRowsArray().indexOf(obj)+1]} 
                                                />
                                            )
                                            :
                                            <></>
                                        }
                                        {props.isCustomerTyping ? 
                                                <ChatRow key="typing" 
                                                    data={{
                                                        role : "user", isTyping : true, time : (new Date()).getTime(),
                                                        senderName : "user"
                                                    }} 
                                                    onRowClick={props.rowClickHandler}
                                                    viewType="admin"
                                                    customerName={getCustomerName()}
                                                    //  scrollChat={scrollToBottom}
                                                    prevModel={getRowsArray()[getRowsArray().length-1]} 
                                                    nextModel={null} 
                                                /> : <></>}
                                    </div>
                            </div>
                            
                            {props.pagetab == "live" ? 
                            <div className="chatinput border-box leftalign clearfix full_width flex_horizontal center_vertical center_horizontal bgcolorGrayE1">
                                {props.selectedThread ? (getCanSend() ? 
                                    <>
                                        <div className="inputbox border-box full_height leftalign clearfix spacer flex_horizontal center_vertical center_horizontal">
                                            <textarea name="sendie" id="sendie" placeholder="Type a message" className="fieldd noactive border-box small" value={text} onKeyDown={_handleKeyDown} 
                                                onChange={(e) => handleTextChange(e)} 
                                            ></textarea>
                                        </div>
                                        <div className="inputbuttonbox border-box full_height centeralign clearfix flex_horizontal center_vertical center_horizontal">
                                            <button className="bttn medium solid one" onClick={(e) => handleSendClick()}>Send</button>
                                        </div>
                                    </> : 
                                    <>
                                        <div className="border-box clearfix full_width full_height flex_horizontal center_vertical center_horizontal weight300 fontspx14 colorGrayE200span bgcolorGrayE10" style={{padding : "0 20px"}}>
                                            <span>This conversation is currently being managed by the Thorin AI Assistant. You can take over control by clicking the&nbsp;<span className="weight500">TAKE OVER</span>&nbsp;button at the top.</span>
                                        </div>
                                    </>)
                                :
                                <></>
                                }
                            </div>
                            : <></>}
                        </div>
                    </>,
            }[props.isLoading]
        }
        </>
        
    )
}