import { Navigate, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './account.css';
import Bottombar from '../bottombar/bottombar';
import Topbar from '../topbar/topbar';
import AppLogin from './AppLogin';
import AppSignUp from './AppSignUp';
import {AppPasswordReset} from './AppPasswordReset';
import {AppPasswordSet} from './AppPasswordSet';
import {AppLogout} from './AppLogout';
import { useEffect } from 'react';
import utils from '../utils';


export default function Account(props) {
    const navigate = useNavigate();

    let content;
    console.log("account page", props);

    if(utils.isLoggedIn()){
        if(props.page == "logout"){
            content = <AppLogout />;
        }else{
            content = <Navigate to="/monitor"/>;
        }
    }else{
        switch(props.page) {
            case "signin":
                content = <AppLogin />;
                break;
            case "signup":
                content = <AppSignUp />;
                break;
            case "password_reset":
                content = <AppPasswordReset />;
                break;
            case "password_set":
                content = <AppPasswordSet />;
                break;
            case "logout":
                content = <AppLogout />;
                break;
            default:
                content = <AppLogin />;
                break;
        }
    }

    let content_full = <></>;
    content_full = 
        <>
            <div className="accountbase border-box bgcolorGrayE16">
                <div className="accountbaseinner border-box login flex_vertical center_horizontal center_vertical">
                    <Topbar type="account" {...props} />
                    {content}
                    <Bottombar type="account" {...props} />
                </div>
            </div>
        </>;
  
    return (
        <>
        {content_full}
        </>
    );

}
