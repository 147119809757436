import { useEffect, useState } from 'react';
import Utils from '../utils';
import FieldBox from '../base/utils/fieldbox';
import Linkbutton from '../base/utils/linkbutton';
import { redirect } from 'react-router-dom';
import api from '../api';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

export const AppPasswordSet = (props) => {
    const [userID, setUserID] = useState("");
    const [fields, setFields] = useState({password : "", passwordC : ""});
    const [error, setError] = useState({});
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();

    const errors = {
        password : "Please enter a password", 
        passwordC : "Please confirm the password",
    }

    let isChecked = false;

    let { token } = useParams();

    useEffect(() => {
        if (isChecked) return;
        isChecked = true;
        api.passwordSetCheck(token).then(function (response) {
            if(response.data.status){
                console.log("response", response.data.data);
                setUserID(response.data.data.user_id);
            }else{
                setError({...error, "global" : response.data.message});
            }
            })
            .catch(function (error) {
                setError({...error, "global" : error.response.data.message})
                // setPageStatus("initial");
            })
            .finally(function () {
            });
        
    }, []);

    function handleFieldState(key, value) {
        switch (key) {
            default:
                setFields({...fields, [key] : value.trim()});
                validateField(key, value.trim());
                break;
        }
    }

    function validateField(key, value){
        switch (key) {
              case "passwordC":
                    console.log(fields.password, value);
                  if(!value || value.length == 0) {
                      setError({...error, [key] : errors[key]});
                      return false;
                  }else if(fields.password !== value){
                    setError({...error, "passwordC" : "", "password" : "", "global" : "The passwords do not match"});
                    return false;
                }else{
                    setError({...error, "passwordC" : "", "password" : "", "global" : ""});
                    return true;
                }
                break;
              default:
                  if(!value || value.length == 0) {
                      setError({...error, [key] : errors[key]});
                      return false;
                  }else{
                      setError({...error, [key] : ""});
                      return true;
                  }
                  break;
        }
    }

    function validateAllFields(){
        let isValid = true;
        
        Object.keys(fields).forEach((key, index) => {
            console.log(key, validateField(key, fields[key]));
            if(!validateField(key, fields[key])){
                isValid = false;
            }
        });
        return isValid;
    }

    function handleButtonClick(buttonType) {
        if(validateAllFields()){
            api.passwordSet(userID, fields.password).then(function (response) {
                if(response.data.status){
                    console.log("response", response.data);
                    setPageStatus("success")
                    // navigate("/account/password/set");
                    
                }else{
                    setError({...error, "password" : response.data.message});
                    setPageStatus("initial");
                }
                })
                .catch(function (error) {
                    setError({...error, "global" : error.response.data.message});
                    setPageStatus("initial");
                })
                .finally(function () {
                });
        }
    }



    return (
      <>
        {
            {'initial':
                <>
                    <div className="mainview border-box bgcolorGrayE1 flex_vertical start_horizontal center_vertical">
                        <h1 className='weight100'>Set your new password</h1>
                        <div className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                            <FieldBox objectKey="password" title="new password" value={fields.password}
                                onSetFieldState={handleFieldState} type="password" size="large"
                                errorDisplay="yes" errorText={error.password} />  
                        </div>
                        <div className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                            <FieldBox objectKey="passwordC" title="confirm password" value={fields.passwordC}
                                onSetFieldState={handleFieldState} type="password" size="large"
                                errorDisplay="yes" errorText={error.passwordC} />  
                        </div>
                        <div className="fieldouter border-box flex_horizontal start_horizontal center_vertical colorThree500span" style={{fontSize : "14px"}}>
                            {error.global ? error.global : ""}
                        </div>
                        <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                            <button className="bttn large solid one" style={{width : "100%"}} onClick={(e) => handleButtonClick("set")}>Continue</button>
                        </div>
                    </div>
                </>
              ,
              
              'success':
                <>
                    <div className="mainview border-box bgcolorGrayE1 flex_vertical start_horizontal center_vertical">
                        <h1 className='weight100'>You've successfully changed your password</h1>
                        <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                            <Linkbutton style={{width : "100%"}} text="Return to sign in" href="/account/signin" newTab="no" type="large1" button_type="solid" />
                            {/* <button className="bttn large solid one" style={{width : "100%"}} onClick={(e) => handleButtonClick("set")}>Continue</button> */}
                        </div>
                    </div>
                </>
            }[pageStatus]
        }
      </>
    );
}