import { useEffect, useRef, useState } from "react";
import apidata from "../../base/utils/data/apidata";
import FieldBox from "../../base/utils/fieldbox";
import { Loader } from "../../base/utils/loader";
import utils from "../../utils";

export const ContentIntegrationStore = (props) => {

    const [fields, setFields] = useState({storeURL : ""});
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [pageStatus, setPageStatus] = useState("initial");
    const [stores, setStores] = useState(utils.getSavedList("stores") ? utils.getSavedList("stores") : []);
    
    let isChecked = false;

    useEffect(() => {
      if (isChecked) return;
      isChecked = true;
      
      fetchData({store_type : 'shopify'});
      
    }, []);

    const errors = {
        storeURL : "Please enter a valid Store URL",
    }

    function handleFieldState(key, value) {
        switch (key) {
              default:
                  setFields({...fields, [key] : value.trim()});
                  validateField(key, value.trim());
                  break;
        }
    }
  
    function validateField(key, value){
        switch (key) {
              case "phone":
                  if(!utils.validateField(key, value)) {
                      setError({...error, [key] : errors[key]});
                      return false;
                  }else{
                      setError({...error, [key] : ""});
                      return true;
                  }
                  break;
              default:
                  if(!value || value.length == 0) {
                      setError({...error, [key] : errors[key]});
                      return false;
                  }else{
                      setError({...error, [key] : ""});
                      return true;
                  }
                  break;
        }
    }
  
      function validateAllFields(){
          let isValid = true;
          
          Object.keys(fields).forEach((key, index) => {
              console.log(key, validateField(key, fields[key]));
              if(!validateField(key, fields[key])){
                  isValid = false;
              }
          });
          return isValid;
      }

      function handleButtonClick(buttonType) {
        if(validateAllFields()){
            shopifyConnectionInitiate({storeURL : fields.storeURL});    
        }
      }

    async function fetchData(params_filter = {}, isReload = true){
        setIsLoading(true);
        const res = await apidata.fetchStores(params_filter, isReload);
        setFetchedData(res.status, res.data ? res.data : []);
    }
  
    function setFetchedData(status, data){
        if(status === "success"){
            setStores(data);
        }else{
            setStores([]);
        }
        setIsLoading(false);
    }

    async function shopifyConnectionInitiate(data){
        setIsLoading(true);
        const res = await apidata.shopifyConnectionInitiate(data);
        postAPICall(res.status, res.data ? res.data : {}, res.message ? res.message : "");
    }
    
    const postAPICall = (status, data, message="") => {
        if(status == "success"){
            // setIsLoading(false);
            console.log("data", data);
            window.location.replace(data.auth_url);
        }else{
            setIsLoading(false);
            setError({...error, "global" : message});
        }
    }

    return (
        <>
        {isLoading ? <Loader innerstyle={{width : "20%"}}/> : 
            <div className="contentintegrationstorebase border-box full_height full_width flex_vertical start_vertical center_horizontal">
                <div className="header border-box full_width flex_horizontal center_vertical space_between_horizontal">
                    <div className="title border-box">{stores.length} {utils.getPluralText("store", stores.length)} active</div>
                </div>
                
                <div className="main border-box spacer clearfix full_width flex_vertical start_vertical start_horizontal">
                    {stores.length == 1 && stores[0].status == "installed" ?
                        <div className="border-box full_width flex_vertical start_horizontal center_vertical fontspx20 weight600">
                            Congratulations! 🎉 <br></br>
                            <span className="weight300 fontspx18">Your store at <a className="colorOne500span weight600" href={stores[0].shop} target="_blank" style={{textDecoration : "underline"}}>{stores[0].shop}</a> is now installed and syncing.</span>
                        </div>
                    : <></>}
                    <div style={{height : "100px"}} />
                    <div style={{width : "400px"}} className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                        <FieldBox objectKey="storeURL" title="Store URL" value={fields.storeURL}
                            onSetFieldState={handleFieldState} type="text" size="large"
                            errorDisplay="yes" errorText={error.storeURL} />  
                    </div>
                    <div className="fieldouter border-box flex_horizontal start_horizontal center_vertical colorThree500span" style={{fontSize : "14px"}}>
                        {error.global ? error.global : ""}
                    </div>
                    <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                        <button className="bttn large solid one" style={{width : "100%"}} onClick={(e) => handleButtonClick("proceed")}>Proceed</button>
                    </div>
                </div>
            </div>
            }
        </>
    );
}