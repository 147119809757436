import React from 'react';
import {Redirect, Link, useHistory, withRouter} from 'react-router-dom';

class ChoiceBox extends React.Component {
    constructor(props) {
      super(props);
      this.state = {selected : this.props.selected};
      this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
      console.log("clickcheck");
        var selected = !this.state.selected;
        this.setState({selected : selected})
    //   var selected = !this.props.selected;
      // this.setState({selected : selected});
      this.props.onClick(this.props.type, this.props.id+"", this.props.text, selected);
    }

    render(){
      console.log("checktypetype" , this.props.selected);
      // var that = this;
      return(
        <React.Fragment>
            <div className="choicebox border-box centeralign uppercased weight700" style={{marginRight : "5px"}}>
              <div className={this.props.selected ? "choiceboxinner choiceboxinnerselected border-box centeralign" : "choiceboxinner border-box centeralign"} 
                  onClick = {(e) => this.handleClick()}>{this.props.text}</div>
            </div>
        </React.Fragment>
      );
    }
}

export default ChoiceBox;