import { useEffect, useState } from 'react';
import Utils from '../utils';
import FieldBox from '../base/utils/fieldbox';
import Linkbutton from '../base/utils/linkbutton';
import { redirect } from 'react-router-dom';
import api from '../api';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

export const AppLogout = (props) => {
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();

    let isChecked = false;

    let { token } = useParams();

    console.log("isLoggedIn", "loggingout")

    useEffect(() => {
        if (isChecked) return;
        isChecked = true;
        
        localStorage.clear();
        sessionStorage.clear();
        setPageStatus("success");
        navigate("/account")
        
    }, []);

    return (
      <>
        <div className="accountbaseinner border-box password_success">
            <div className="accountbaseinnerleft border-box" style={{width : "30%"}}>
            </div>
            <div className="accountbaseinnerleft border-box" style={{width : "40%"}}>
                <div className="inner border-box">
                    <h1 className="colorOne500span weight700">Goodbye :(</h1>
                    <p className="centeralign" style={{fontSize : "12px"}}>
                    {
                        {'initial':
                        "Logging you out ...",
                        
                        'success':
                        "Redirecting ...",
                        }[pageStatus]
                    }
                    </p>
                    
                </div>
                
            </div>
            <div className="accountbaseinnerright border-box" style={{width : "30%"}}>
            </div>
        </div>
      </>
    );
}
