import constants from '../../constants';
import { child, get, getDatabase, off, onChildAdded, onChildRemoved, onValue, orderByChild, orderByKey, push, query, ref, remove, set, startAt } from 'firebase/database';
import utils from '../../utils';

var divM= {};
var divH= {};
var divK= {};
var div_lastkeys= {};
var div_lasttimes= {};
var div_lastmessages= {};
var div_lastmessagetimes = {};

var org_id = localStorage.getItem("org_id");

var thread_codes = [];

var threads_list = [];

var all_models = {};

var fbListeners1 = [];
var fbListeners2 = [];
var fbListeners3 = [];
var fbListeners4 = [];


const database = constants.getFirebaseDatabase();

var chatRef = ref(database, 'threads/'+org_id);
var queueRef = ref(database, 'queue/tasks');

var customer_last_message = null;

class chat_utils_admin{
    loadInitial(onSetChatState, onSetTypingState, returnNow, selectedTab){
        divM= {};divH= {};divK= {};
        div_lastkeys= {};div_lasttimes= {};div_lastmessages= {};div_lastmessagetimes = {};
        thread_codes = [];threads_list = [];all_models = {};

        let listener7 = onChildRemoved(chatRef, (data) => {
            if(data.key in all_models){
                for(var l in fbListeners3){
                    if (l['ref'] == child(chatRef, data.key)){
                        off(child(l['ref'], l['path']), l['type'], l['listener']);
                    }
                }
                delete divM[data.key];delete divH[data.key];delete divK[data.key];
                delete div_lastkeys[data.key];
                delete div_lasttimes[data.key];
                delete div_lastmessages[data.key];
                delete div_lastmessagetimes[data.key];
                delete all_models[data.key];
                
                let objTL = threads_list.find(o => o.id === data.key);
                var indexTL = threads_list.indexOf(objTL);
                threads_list.splice(indexTL, 1);
                
                let indexTC = thread_codes.indexOf(data.key);
                thread_codes.splice(indexTC, 1);
                
                onSetChatState(threads_list, all_models, divK, divM, divH);
            }
        });

        fbListeners4.push({'path' : null, 'ref' : chatRef, 'type' : 'child_removed', 'listener' : listener7});

        let listener3 = onChildAdded(chatRef, (data) => {
            if (thread_codes.indexOf(data.key) == -1){
                var childRef= child(chatRef, data.key);

                var models= [];var texts = [];var textHs = [];var mKeys = [];
                
                div_lastkeys[data.key] = null;
                div_lasttimes[data.key] = null;
                div_lastmessages[data.key] = null;
                div_lastmessagetimes[data.key] = null;

            
                var currentModel = null;
                var messageCount = 0;

                var first_customer_message_time = null;
                var first_executive_message_time = null;
                var lastCustomerTime = null;
                var lastAgentMsg    = false;
            
                data.child("chat").forEach(function(child){
                    if(!currentModel || (currentModel && !constants.get_TimeComparison(currentModel['time'], child.val().time))){
                        var textH = "";var textM = "";
                        
                        var message= {
                            senderName:null,
                            role:null,
                            time:child.val().time,
                            textH : textH,
                            textM : textM
                        };

                        models.push(message);

                        textHs.push(textH);texts.push(textM);mKeys.push(null);
                        div_lastkeys[data.key] = null;
                        div_lasttimes[data.key] = message['time'];
                        div_lastmessages[data.key] = textM;
                        div_lastmessagetimes[data.key] = message['time'];
                    }

                    var textH = '';var textM = '';
                    
                    if (child.val().notification){
                        textH = child.val().type.toUpperCase();
                        textM = child.val().text;
                    } else{
                        textH = '';
                        textM = child.val().text;
                    }

                    var message= {
                        senderName:child.val().senderName,
                        role:child.val().role,
                        time:child.val().time,
                        textH : textH,
                        textM : textM,
                    }
                    models.push(message);

                    currentModel = message;

                    textHs.push(textH);texts.push(textM);mKeys.push(child.key);
                    div_lastkeys[data.key] = child.key;
                    div_lasttimes[data.key] = child.val().time;
                    div_lastmessages[data.key] = textM;
                    div_lastmessagetimes[data.key] = child.val().time;
                    
                    if(child.val().role){ 
                        if( child.val().role != 'assistant'){
                            messageCount += 1;
                        }
                    }    
                    if (child.val().senderName){
                        if (child.val().role != "assistant"){
                            if(first_executive_message_time){
                                lastAgentMsg = false;
                                lastCustomerTime = child.val().time;
                            }else{
                                lastCustomerTime = null;
                                first_customer_message_time = child.val().time;
                            }
                        }else if (!lastAgentMsg && first_customer_message_time && child.val().role == "assistant"){
                            if(!lastAgentMsg && lastCustomerTime){
                                first_customer_message_time = lastCustomerTime;
                            }
                            first_executive_message_time = child.val().time;
                            lastAgentMsg = true;
                        }
                    }
                });
                divM[data.key] = texts;divH[data.key] = textHs;divK[data.key] = mKeys;

                var code1 = data.key;

                thread_codes.push(code1);

                let obj = {
                    'source' : data.child("source").val(), 
                    'comm_type' : data.child("comm_type").val(), 
                    'name' : data.child("name").val(), 
                    'customer_id' : data.child("customer_id").val(),
                    'mobile' : data.child("mobile").val(), 
                    'assigned_executive' : data.child('assigned_executive').val(), 
                    'new' : data.child("chat").val() == null ? true : (messageCount == 2) ? true : false, 
                    'first_customer_message_time' : first_customer_message_time, 
                    'first_executive_message_time' : first_executive_message_time,
                    'customer_info' : data.child("customer_info").val(),
                    'control_status' : data.child('control_status').val(),
                    'country' : data.child('country').val(),
                    // 'is_customer_typing' : data.child("is_customer_typing").val(),
                };
                
                threads_list.push(obj);
                
                var thread_title = data.key;

                if(obj != null && obj.name != null){
                    thread_title = obj.name;
                }

                if(obj != null){
                    var indexObj = threads_list.indexOf(obj);
                    obj['last_time'] = div_lasttimes[code1];
                    obj['last_message'] = div_lastmessages[code1];
                    obj['last_messagetime'] = div_lastmessagetimes[code1];
                    obj['id'] = data.key;
                    obj['unread'] = 0;
                    threads_list[indexObj] = obj;    
                }

                threads_list.sort(function(a, b) {
                        return b.last_messagetime - a.last_messagetime;
                });
                threads_list.sort(function(a, b) {
                        return b.last_messagetime - a.last_messagetime;
                });
            
                var unread = 0

                if (data.child("admin").child("last_read").child(localStorage.getItem("user_id")).val() != null){	
                    var time = data.child("admin").child("last_read").child(localStorage.getItem("user_id")).val().time;	
                    for (var i = models.length-1; i >= 0; i--){	
                        var message = models[i];	
                        if (message.time >= time && message.senderName != 'bot'){	
                            unread += 1;	
                        }else{	
                            break;	
                        }	
                    }
                }else{	
                    unread = models.length;	
                }
                if(obj != null){	
                    var indexObj = threads_list.indexOf(obj);	
                    obj['unread'] = unread;	
                    threads_list[indexObj] = obj;	
                }

                all_models[code1] = models;

                onSetChatState(threads_list, all_models, divK, divM, divH);            
            
                var chat_start_key = "";
                if(data.key in div_lastkeys && div_lastkeys[data.key] != null){
                    chat_start_key = div_lastkeys[data.key];
                }

                onValue(query(child(childRef, "is_customer_typing")), (snapshot3) => {
                    console.log("typingstate", snapshot3.val());
                    let obj1 = threads_list.find(o => o.id === code1);
                    var indexObj1 = threads_list.indexOf(obj1);
                    if(obj1 != null){
                        if(snapshot3.val()){
                            obj1['is_typing_user'] = true;
                            onSetTypingState("user", true, data.key);
                        }else{
                            if('is_typing_user' in obj1){
                                delete obj1['is_typing_user'];
                            }
                            onSetTypingState("user", false, data.key);
                        }
                        threads_list[indexObj1] = obj1;
                        onSetChatState(threads_list);
                    }    
                });

                onValue(query(child(childRef, "control_status")), (snapshot7) => {
                    console.log("typingstateX", snapshot7.val());
                    let obj1 = threads_list.find(o => o.id === code1);
                    var indexObj1 = threads_list.indexOf(obj1);
                    if(obj1 != null){
                        // if(snapshot7.val() && snapshot7.val() == "human"){
                        //     obj1['canType'] = true;
                        //     // onSetTypingState("user", true, data.key);
                        // }
                        obj1['control_status'] = snapshot7.val();
                        // else{
                        //     if('is_typing_user' in obj1){
                        //         delete obj1['is_typing_user'];
                        //     }
                        //     onSetTypingState("user", false, data.key);
                        // }
                        threads_list[indexObj1] = obj1;
                        onSetChatState(threads_list);
                    }    
                });

                onValue(query(child(childRef, "customer_info")), (snapshot8) => {
                    console.log("typingstateX", snapshot8.val());
                    let obj1 = threads_list.find(o => o.id === code1);
                    var indexObj1 = threads_list.indexOf(obj1);
                    if(obj1 != null){
                        obj1['customer_info'] = snapshot8.val();
                        threads_list[indexObj1] = obj1;
                        onSetChatState(threads_list);
                    }    
                });

                
                let listener5 = onChildAdded(query(child(childRef, "chat"), orderByKey(), startAt(chat_start_key)), (child) => {                
                    if (data.key in divK){
                        var exists = false;
                        for (var i = 0; i < divK[data.key].length; i++){
                            if (child.key == divK[data.key][i]){
                                exists = true;
                                break;
                            }
                        }
                    
                        if (!exists){
                            var texts = divM[data.key];var textHs = divH[data.key];var mKeys = divK[data.key];
                            
                            var textH = '';var textM = '';
                            var newMessageCount = 0;
                            var isExectiveMsg   = false; 
                                
                            if (child.val().notification){
                                textH = child.val().type.toUpperCase();
                                textM = child.val().text;
                            } else{
                                textH = '';
                                textM = child.val().text;
                            }
        
                            var datanew= all_models[data.key];
                            var newobject= {
                                senderName : child.val().senderName,
                                role : child.val().role,
                                time : child.val().time,
                                textH : textH,
                                textM : textM,
                            };

                            if(datanew.length == 0 || (datanew.length > 0 && !constants.get_TimeComparison(datanew[datanew.length-1]['time'], child.val().time))){
                                var textH = "";var textM = "";
                                                        
                                var message= {
                                    senderName:null,
                                    role:null,
                                    time:child.val().time,
                                    textH : textH,
                                    textM : textM
                                };
        
                                datanew.push(message);
        
                                textHs.push(textH);texts.push(textM);mKeys.push(null);
                                divM[data.key] = texts;divH[data.key] = textHs;divK[data.key] = mKeys;
                                div_lastkeys[data.key] = null;
                                div_lasttimes[data.key] = message['time'];
                                div_lastmessages[data.key] = textM;
                                div_lastmessagetimes[data.key] = message['time'];
                            }

                            datanew.push(newobject);
                        
                            textHs.push(textH);texts.push(textM);mKeys.push(child.key);
                            divM[data.key] = texts;divH[data.key] = textHs;divK[data.key] = mKeys;
                        
                            if(child.val().role){ 
                                if( child.val().role != 'assistant' && !child.val().defaultMessage){
                                    newMessageCount += 1;
                                }
                            }   
                            if (child.val().senderName){
                                if (child.val().role != "assistant"){
                                    customer_last_message = child.val().time;
                                }else if (customer_last_message && child.val().role == "assistant"){
                                    first_executive_message_time = child.val().time;
                                    isExectiveMsg = true;
                                }
                            }

                                        

                            div_lasttimes[data.key] = child.val().time;
                            div_lastmessages[data.key] = textM;
                            div_lastmessagetimes[data.key] = child.val().time;
                        
                            let obj = threads_list.find(o => o.id === data.key);
                            if(obj != null){
                                var indexObj = threads_list.indexOf(obj);
                                obj['last_time'] = div_lasttimes[data.key];
                                obj['last_message'] = div_lastmessages[data.key];
                                if(newMessageCount > 0)
                                    obj['new'] = false;
                                obj['last_messagetime'] = div_lastmessagetimes[data.key];
                                    
                                if(isExectiveMsg && customer_last_message){
                                    obj['first_executive_message_time'] = first_executive_message_time;
                                    obj['first_customer_message_time'] = customer_last_message;
                                    customer_last_message = null;
                                }
                                threads_list[indexObj] = obj;
                            }
                                        
                            all_models[data.key] = datanew
                                        
                    
                            if (selectedTab == data.key){
                                if(child.val().role != 'assistant'){
                                    var new_date = new Date();
                                    var new_time = new_date.getTime();
                                    set(child(chatRef, code1+'/admin/last_read/'+utils.getUserID()), {time:new_time});
                                    var unread = 0;
                                    if(obj != null && 'unread' in obj && obj['unread'] != null){
                                        unread = obj['unread'];
                                    }
                                    unread += 1;

                                    if (unread > 0){
                                        if(obj != null){
                                            var indexObj1 = threads_list.indexOf(obj);
                                            obj['unread'] = unread;
                                            threads_list[indexObj1] = obj;
                                        }
                                    }
                                }
                            }else{
                                if(child.val().role != 'assistant'){
                                    var unread = 0;
                                    if(obj != null && 'unread' in obj && obj['unread'] != null){
                                        unread = obj['unread'];
                                    }
                                    unread += 1;

                                    if (unread > 0){
                                        if(obj != null){
                                            var indexObj1 = threads_list.indexOf(obj);
                                            obj['unread'] = unread;
                                            threads_list[indexObj1] = obj;
                                        }
                                    }
                                }
                            }
                    
                            threads_list.sort(function(a, b) {
                                    return b.last_messagetime - a.last_messagetime;
                            });
                            threads_list.sort(function(a, b) {
                                    return b.last_messagetime - a.last_messagetime;
                            });
                                            
                            let length = all_models && data.key ? all_models[data.key].length : 0;
                            console.log("setChatStateXXX", data.key);
                            onSetChatState(threads_list, all_models, divK, divM, divH, false, data.key);
                        }                    
                    }
                });
                fbListeners3.push({'path' : 'chat', 'ref' : childRef, 'type' : 'child_added', 'listener' : listener5});
            }
        });
        fbListeners1.push({'path' : null, 'ref' : chatRef, 'type' : 'child_added', 'listener' : listener3});
    }


    handleThreadClick(tag, onSetChatState, threads_array){
        let obj = threads_array.find(o => o.id === tag);
        var date = new Date();
        var time = date.getTime();

        div_lasttimes[tag] = time;
		if(obj != null){
			var indexObj = threads_list.indexOf(obj);
            obj['unread'] = 0;
            obj['last_time'] = div_lasttimes[tag];
            obj['scrolling'] = false;
			threads_list[indexObj] = obj;
        }
        if(obj != null){
			var indexObj = threads_array.indexOf(obj);
            obj['unread'] = 0;
            obj['last_time'] = div_lasttimes[tag];
			threads_array[indexObj] = obj;
        }
        let length = all_models && tag && tag in all_models ? all_models[tag].length : 0;
        
        set(child(chatRef, tag+'/admin/last_read/'+utils.getUserID()), {time:time});
        
        onSetChatState(threads_array);
    }


    sendMessage(mText, selectedTab, onSetChatState){
        var code = selectedTab;

        var date = new Date();
        
        var dateString = constants.get_DateString(date);

        var time = date.getTime();

        var texts = divM[selectedTab];var textHs = divH[selectedTab];var mKeys = divK[selectedTab];

        var datanew= all_models[selectedTab];


        if(datanew.length == 0 || (datanew.length > 0 && !constants.get_TimeComparison(datanew[datanew.length-1]['time'], time))){
            var textH = "";var textM = "";
                    
            var message= {
                senderName:null,
                role:null,
                time:time,
                textH : textH,
                textM : textM
            };

            datanew.push(message);

            textHs.push(textH);texts.push(textM);mKeys.push(null);
            divM[code] = texts;divH[code] = textHs;divK[code] = mKeys;
            div_lastkeys[code] = null;
            div_lasttimes[code] = message['time'];
            div_lastmessages[code] = textM;
            div_lastmessagetimes[code] = message['time'];
                
        }

        var textH = '';var textM = mText;

        div_lasttimes[code] = time;
        div_lastmessages[code] = textM;
        div_lastmessagetimes[code] = time;

        let obj = threads_list.find(o => o.id === code);
        if(obj != null){
            var indexObj = threads_list.indexOf(obj);
            obj['last_time'] = div_lasttimes[code];
            obj['last_message'] = div_lastmessages[code];
            obj['new'] = false;
            obj['last_messagetime'] = div_lastmessagetimes[code];
            if(customer_last_message){    
                obj['first_executive_message_time'] = time;
                obj['first_customer_message_time'] = customer_last_message;
                customer_last_message = null;
            }
                
            threads_list[indexObj] = obj;
        }

        var chatRefPush = push(child(chatRef, code+"/chat"));

        texts.push(mText);textHs.push('');mKeys.push(chatRefPush.key);
        divM[selectedTab] = texts;divH[selectedTab] = textHs;divK[selectedTab] = mKeys;

            
        var newobject= {
            senderName : 'assistant',
            role : 'assistant',
            time : time,
            textM : textM,
            textH : textH,
        };
        datanew.push(newobject);
            
        all_models[selectedTab] = datanew
        
        set(chatRefPush, {
            text: mText,
            senderName : 'assistant',
            role : 'assistant',
            time : time,
        });

        set(child(chatRef, code+"/last_updated"), time);
            
        threads_list.sort(function(a, b) {
                return b.last_messagetime - a.last_messagetime;
        });
        threads_list.sort(function(a, b) {
                return b.last_messagetime - a.last_messagetime;
        });
        
        let length = all_models && selectedTab ? all_models[selectedTab].length : 0;
        
        remove(child(chatRef, code+"/is_assistant_typing"));

        onSetChatState(threads_list, all_models, divK, divM, divH, false, code);

        return {text: mText, senderName : "assistant", role : "assistant", time : time};
    }

    
    searchThreads(val, onSetChatState){
        if (val != null && val.trim() != "") {
    
            let obj1 = threads_list.filter(o => (o.name && o.name.toLowerCase().indexOf(val.toLowerCase()) != -1));
            let obj2 = threads_list.filter(o => (o.mobile ? (o.mobile.toLowerCase().indexOf(val.toLowerCase()) != -1) : false ));
            let obj3 = threads_list.filter(o => (o.customer_id ? (o.customer_id.toLowerCase().indexOf(val.toLowerCase()) != -1) : false ));
            let obj4 = threads_list.filter(o => (o.customer_info && o.customer_info.display_name ? (o.customer_info.display_name.toLowerCase().indexOf(val.toLowerCase()) != -1) : false ));
            // let obj3 = thread_people.filter(o => (o.people == "" ? false : (o.people.toLowerCase().indexOf(val.toLowerCase()) != -1) ));


            var codes = [];

            obj1.forEach(function(obj) { 
                codes.push(obj.id);
            });
            obj2.forEach(function(obj) {
                codes.push(obj.id);
            });
            obj3.forEach(function(obj) {
                codes.push(obj.id);
            });
            obj4.forEach(function(obj) {
                codes.push(obj.id);
            });

            for (var key in divM) {
                var texts = divM[key];
                if (texts.find(function(str) {return (str && str.toLowerCase().indexOf(val.toLowerCase()) != -1);})){
                    codes.push(key);
                }
            }

            for (var key in divH) {
                var textHs = divH[key];
                if (textHs.find(function(str) {return (str && str.toLowerCase().indexOf(val.toLowerCase()) != -1);})){
                    codes.push(key);
                }
            }

            var new_codes = codes.filter(function(item, pos, self) {
                  return self.indexOf(item) == pos;
            });

            var newArray = threads_list.filter(function(item) {
                return new_codes.includes(item.id);    
            });

            onSetChatState(newArray);
        } else {

            threads_list.sort(function(a, b) {
                  return b.last_messagetime - a.last_messagetime;
            });
            threads_list.sort(function(a, b) {
                  return b.last_messagetime - a.last_messagetime;
            });

            onSetChatState(threads_list);

        }
    }


    isTypingAlert(thread_id, isTyping){
        if(isTyping)
            set(child(chatRef, thread_id+"/is_assistant_typing"), true);
        else
            remove(child(chatRef, thread_id+"/is_assistant_typing"));
    }

    clearListeners(){
        for(var l1 in fbListeners1){
            if(l1){
                off(l1['ref'], l1['type'], l1['listener']);
            }
        }
        for(var l2 in fbListeners4){
            if(l2){
                off(l2['ref'], l2['type'], l2['listener']);
            }
        }
        for(var l3 in fbListeners2){
            if(l3){
                off(ref(database, l3['path']), l3['type'], l3['listener']);
            }
        }
        for(var l4 in fbListeners3){
            if(l4){
                off(child(l4['ref'], l4['path']), l4['type'], l4['listener']);
            }
        }
    }

}

export default new chat_utils_admin();