import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom";
import FourOFour from "./404";
import utils from "../../utils";
import AppInterview from "../../interview/AppInterview";
import InterviewCandidate from "../../interview/InterviewCandidate";
import AppAccount from "../../account/AppAccount";
import Account from "../../account/Account";
import AppMonitor from "../../monitor/AppMonitor";
import Monitor from "../../monitor/Monitor";
import AppMonitorSection from "../../monitor/AppMonitorSection";
import AppChat from "../../chat/AppChat";
import ChatBase from "../../chat/ChatBase";
import AppOrgAdmin from "../../orgadmin/AppOrgAdmin";
import AppOrgAdminSection from "../../orgadmin/AppOrgAdminSection";
import OrgAdmin from "../../orgadmin/OrgAdmin";

export const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ utils.isLoggedIn() ? <Navigate to="/dashboard/home"/> : <Navigate to="/login"/> } />
                <Route path="/" element={<Navigate to="chat" />} />
                {/* <Route path="/interview" element={ <AppAccount type="interview"/> } /> */}
                <Route path="/chat/" element={ <AppChat /> } >
                    <Route path=":org_id/:thread_id" element={<ChatBase type="chat" />} />
                </Route>
                {/* <Route path="/interview/" element={ <AppInterview /> } >
                    <Route path=":id/candidate" element={<InterviewCandidate type="interview_candidate" />} />
                </Route> */}
                
                
                <Route path="/monitor/" element={<AppMonitor />}>
                    <Route path="" element={<Navigate to="dashboard" />} />
                    <Route path=":section" element={<AppMonitorSection />} />
                    <Route path=":section/pages" element={<AppMonitorSection />} />
                    <Route path=":section/pages/:tab" element={<Monitor />} />
                    {/* <Route path="customers" element={<Dashboard section="pages" subsection="customers" />} />
                    <Route path="action/customers/customers/edit/:id" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="customer" actionType="edit" />} />
                    <Route path="customers/:currentTab/:id" element={<Dashboard section="pages" subsection="customers" dashboardType="detail" />} />
                    <Route path="finance" element={<Dashboard section="pages" subsection="finance" />} />
                    <Route path="finance/:currentTab/:id" element={<Dashboard section="pages" subsection="finance" dashboardType="detail" />} />
                    <Route path="tickets" element={<Dashboard section="pages" subsection="tickets" />} />
                    <Route path="users" element={<Dashboard section="pages" subsection="users" />} />
                    <Route path="oems" element={<Dashboard section="pages" subsection="oems" />} />
                    <Route path="action/customers/customers/add" element={<Dashboard section="pages" subsection="customers" dashboardType="action" actionObject="customer" actionType="add" />} />
                    <Route path="action/oems/oems/add" element={<Dashboard section="pages" subsection="oems" dashboardType="action" actionObject="oem" actionType="add" />} />
                    <Route path="action/users/users/add" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="sb" actionType="add" />} />
                    <Route path="action/users/users/edit/:id" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="sb" actionType="edit" />} />
                    <Route path="users/:currentTab/:id" element={<Dashboard section="pages" subsection="users" dashboardType="detail" />} />                     */}
                </Route>

                <Route path="org" element={<AppOrgAdmin />}>
                    <Route path="" element={<Navigate to="integrations" />} />
                    <Route path=":section" element={<AppOrgAdminSection />} />
                    <Route path=":section/pages" element={<AppOrgAdminSection />} />
                    <Route path=":section/pages/:tab" element={<OrgAdmin />} />
                    {/* <Route path=":section/pages" element={<AppMonitorSection />} />
                    <Route path=":section/pages/:tab" element={<Monitor />} /> */}
                    {/* <Route path="customers" element={<Dashboard section="pages" subsection="customers" />} />
                    <Route path="action/customers/customers/edit/:id" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="customer" actionType="edit" />} />
                    <Route path="customers/:currentTab/:id" element={<Dashboard section="pages" subsection="customers" dashboardType="detail" />} />
                    <Route path="finance" element={<Dashboard section="pages" subsection="finance" />} />
                    <Route path="finance/:currentTab/:id" element={<Dashboard section="pages" subsection="finance" dashboardType="detail" />} />
                    <Route path="tickets" element={<Dashboard section="pages" subsection="tickets" />} />
                    <Route path="users" element={<Dashboard section="pages" subsection="users" />} />
                    <Route path="oems" element={<Dashboard section="pages" subsection="oems" />} />
                    <Route path="action/customers/customers/add" element={<Dashboard section="pages" subsection="customers" dashboardType="action" actionObject="customer" actionType="add" />} />
                    <Route path="action/oems/oems/add" element={<Dashboard section="pages" subsection="oems" dashboardType="action" actionObject="oem" actionType="add" />} />
                    <Route path="action/users/users/add" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="sb" actionType="add" />} />
                    <Route path="action/users/users/edit/:id" element={<Dashboard section="pages" subsection="users" dashboardType="action" actionObject="sb" actionType="edit" />} />
                    <Route path="users/:currentTab/:id" element={<Dashboard section="pages" subsection="users" dashboardType="detail" />} />                     */}
                </Route>

                <Route path="/account/" element={<AppAccount />}>
                    <Route path="" element={<Navigate to="login" />} />
                    <Route path="login" element={<Navigate to="/account/signin" />} />
                    <Route path="signin" element={<Account page="signin" />} />
                    <Route path="signup" element={ <Account page="signup"/> } />
                    <Route path="password/reset" element={ <Account page="password_reset"/> } />
                    <Route path="password/set/:token" element={ <Account page="password_set"/> } />
                    <Route path="logout" element={ <Account page="logout"/> } />
                </Route>
                <Route path="/error-page" element={ <FourOFour /> } />
                <Route path="/redirect" element={ <Navigate to="/error-page" /> } />
                <Route path="/not-found" element={ <FourOFour /> } />
                {/* <Route path="*" element={ <Navigate to="/not-found" /> } /> */}
                <Route path="*" element={ <FourOFour /> } />
            </Routes>
        </BrowserRouter>
    );
}