import { Link } from "react-router-dom";
import { AnimationLive } from "../base/utils/animation_live";
import Linkbutton from "../base/utils/linkbutton";
import { companyName } from "../constants";
import { Spacer } from "../base/utils/spacer";

export default function Topbarunauth(props) {

  // let className  = "topbarinner border-box interviewcandidate";
  // if (props.status == "live"){
  //   className += " live";
  // }

  

  let content = <></>;
  if (false){
    content = 
      <div className="topbarinner border-box unauth">
          <div className="topbarinnerleft border-box weight700 whitespan">
              Mirkwood
          </div>
          <Spacer/>
          <div className="topbarinnermiddle border-box weight600 whitespan">
            <span className="border-box colorGrayE1span weight600" style={{fontSize : "15px"}}>LIVE</span>
            <div style={{width : "20px"}} />
            <div className="animationlive border-box full_height flex_horizontal center_vertical center_horizontal" style={{paddingTop : "5px"}}><AnimationLive /></div>
            {props.candidate ? 
              <><div style={{width : "20px"}} />
              <span className="border-box colorGrayE14span weight300" style={{fontSize : "12px"}}>{props.candidate.first_name} {props.candidate.last_name}'s interview</span>
              </>
              : <></>}
          </div>
          <Spacer/>
          <div className="topbarinnerright border-box">
            <button className="bttn border six medium">Exit</button>
          </div>
      </div>
  }else{
    content = 
      <div className="topbarinner border-box unauth">
          <div className="topbarinnerleft border-box weight700 colorGrayE256span">
              <Link to="/">{companyName}</Link>
          </div>
          <Spacer/>
          <div className="topbarinnerright border-box">
              {/* {props.page !== "login" ? <Linkbutton text="Log in" href="/" newTab="no" type="topbar_large" button_type="transparent" /> : <></>}
              {props.page !== "signup" ? <Linkbutton text="Sign up" href="/signup" newTab="no" type="topbar_large" button_type="solid" /> : <></>} */}
          </div>
      </div>
  }

  return (
    <>{content}</>
  );
}