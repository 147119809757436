import React, { useState } from 'react';
import { Spacer } from '../spacer';
import FieldBox from '../fieldbox';
import utils from '../../../utils';
import apidata from '../data/apidata';
import { Loader } from '../loader';

export const Confirmation1 = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    
    return(
        <>
        {
            {
                true: 
                    <Loader innerstyle={{width : "20%"}}/>,
                false: 
                    <div className="main border-box flex_vertical start_horizontal center_vertical full_width">
                        <h3 className='weight100'>{props.data.title}</h3>
                        <p className='weight200 fontspx14'>{props.data.subtitle}</p>
                                                
                        <div style={{height : "20px"}}></div>
                        <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical full_width">
                            <button className="bttn medium solid three" onClick={(e) => props.buttonClickHandler("close")}>{props.data.title_button_negative}</button>
                            <Spacer />
                            <button className="bttn medium solid one" onClick={(e) => props.buttonClickHandler("button_positive", props.data)}>{props.data.title_button_positive}</button>
                        </div>
                    </div>,
            }[isLoading]
        }
        </>
    )
}



