import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import './chat.css';
import Topbar from '../topbar/topbar';
import chat_utils from './utils/chat_utils';
import ChatRow from './ChatRow';
import apidata from '../base/utils/data/apidata';

export default function ChatBase(props) {
    const navigate = useNavigate();

    const [status, setStatus] = useState("initial");
    const [divK, setDivK] = useState([]);
    const [divM, setDivM] = useState([]);
    const [divH, setDivH] = useState([]);
    const [allModels, setAllModels] = useState([]);
    const [isEmpty, setIsEmpty] = useState(true);
    const [live, setLive] = useState(true);
    const [canSend, setCanSend] = useState(true);
    const [text, setText] = useState("");
    const [isAssistantTyping, setIsAssistantTyping] = useState(false);
      
    // middleContent: NaN,
    // 	showMiddleContent: false,
    //   rightOrder: NaN,
    //   showLoader : false,
    //   searchText : NaN,
    //   selectedRow: "",
    //   is_typing_executive : false,

    //   reloading : false,
    
    let { org_id, thread_id } = useParams();

    const chatRef = useRef(null);

    let isChecked = false;

    useEffect(() => {
        sessionStorage.setItem("org_id", org_id);
        sessionStorage.setItem("thread_id", thread_id);
        if(isChecked) return;
        isChecked = true;
        chat_utils.loadInitialMain(setChatState, setTypingState, false);
        // fetchData();
        
      }, []);

    useEffect(() => {
        if(chatRef.current)
            chatRef.current.scrollTo({ behavior: "smooth", top: chatRef.current.scrollHeight});
    }, [allModels]);

    useEffect(() => {
        if(chatRef.current)
            chatRef.current.scrollTo({ behavior: "smooth", top: chatRef.current.scrollHeight});
    }, [isAssistantTyping]);

    let content_topbar;
    // let content_bottombar;
    content_topbar = <Topbar type={props.type} status={status} thread_id={thread_id} />;
    // content_bottombar = <Bottombar type={props.type} status={status} />;

    const buttonClickHandler = (button, extra) => {
        // setLiveSection(section);
    }

    const handleRowClick = () => {

    }

    const handleSendClick = () => {
        var message = text;
        if(message.trim() != ""){
          // this.sendieRef.current.value = "";
          setText("");
        //   this.props.onGlobalSend(message);
            let message_obj = chat_utils.sendMessage(message, setChatState);
            postMessage(message_obj);
        }
        
      }
    
      const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          var message = text;
          if(message.trim() != ""){
            // this.sendieRef.current.value = "";
            setText("");
            // this.props.onGlobalSend(message);
            let message_obj = chat_utils.sendMessage(message, setChatState);
            postMessage(message_obj);
          } 
        }
      }

    const postMessage = async (message) => {
        const res = await apidata.postMessage({thread_id : thread_id, message : message});
        // setFetchedData(res.status, res.data ? res.data : []);
    }
    
    
    const handleTextChange = (e) => {
    // console.log(e.target.value);
        setText(e.target.value);
        console.log("typingstate", e.target.value.trim(), e.target.value.trim() != "" ? true : false);
        chat_utils.isTypingAlert(e.target.value.trim() != "" ? true : false);
    // if(!this.props.isEmpty){
    //   // if(["9990903321", "8446620331", "8585968363", "0008887771"].includes(sessionStorage.getItem("id"))){
    //   if(true){
    //     if(!this.props.isMobileVerification && e.target.value.trim() != ""){
    //       ChatUtilsLogic.isTypingAlert(true);
    //     }else{
    //       ChatUtilsLogic.isTypingAlert(false);
    //     }
    //   }else{
    //     if(e.target.value.trim() != ""){
    //       ChatUtils.isTypingAlert(true);
    //     }else{
    //       ChatUtils.isTypingAlert(false);
    //     }
    //   }
        
    // }
    }

    const setChatState = (all_models, var_divK, var_divM, var_divH, var_isEmpty) => {
        console.log("setChatState", all_models[thread_id]);
        setAllModels([...all_models[thread_id]]);
        setDivK([...var_divK[thread_id]]);
        setDivM([...var_divM[thread_id]]);
        setDivH([...var_divH[thread_id]]);
        setIsEmpty(var_isEmpty ? true : false);
        // scrollToBottom();
    }

    const setTypingState = (role, is_typing) => {
        console.log("setTypingState", role, is_typing);
        if(role == "assistant"){
            setIsAssistantTyping(is_typing);
            // setIsAssistantTyping(true);
        }
    }
  
    return (
        <>
        <div className="chatouter border-box full_width full_height flex_vertical center_vertical center_horizontal">
            {content_topbar}
            <div className="chatbase border-box full_width spacer flex_vertical center_vertical center_horizontal clearfix">
                <div className="chatbox border-box full_width full_height" 
                    ref={chatRef}>
                    {allModels && allModels.length != 0 ? 
                        allModels.map((obj, index) =>
                            <ChatRow key={index} data={obj} onRowClick={handleRowClick}
                                viewType="customer"
                                //  scrollChat={scrollToBottom}
                                prevModel={allModels.indexOf(obj) == 0 ? null : allModels[allModels.indexOf(obj)-1]} 
                                nextModel={allModels.indexOf(obj) == (allModels.length-1) ? null : allModels[allModels.indexOf(obj)+1]} 
                            />
                        )
                        :
                        <></>
                    }
                    {isAssistantTyping ? 
                            <ChatRow key="typing" 
                                data={{
                                    role : "assistant", isTyping : true, time : (new Date()).getTime(),
                                    senderName : "assistant"
                                }} onRowClick={handleRowClick}
                                //  scrollChat={scrollToBottom}
                                prevModel={allModels[allModels.length-1]} 
                                nextModel={null} 
                                viewType="customer"
                            /> : <></>}
                    {/* <MiddleMain {...props} scrollChat={this.scrollToBottom} onImageClick={this.openImage} /> */}
                    
                </div>
            </div>

            <div className="chatinput border-box leftalign clearfix full_width flex_horizontal center_vertical center_horizontal">
                {canSend ? (<>
                    {/* <form method="post" action="" className="chatform" autocomplete="off" id="chatform" data-type='chat'> */}
                        <div className="inputbox border-box full_height leftalign clearfix spacer flex_horizontal center_vertical center_horizontal">
                            {/* <table className="stdtable"><tr><td valign="center"> */}
                            {/* <input type="text" name="sendie" id="sendie" placeholder="Type a message" className="field border-box small"/> */}
                            <textarea name="sendie" id="sendie" placeholder="Type a message" className="fieldd noactive border-box small" value={text} onKeyDown={_handleKeyDown} 
                                onChange={(e) => handleTextChange(e)} 
                                ></textarea>
                            {/* </td></tr></table> */}
                        </div>
                        
                        <div className="inputbuttonbox border-box full_height centeralign clearfix flex_horizontal center_vertical center_horizontal">
                            <button className="bttn medium solid one" onClick={(e) => handleSendClick()}>Send</button>
                        </div>
                    {/* </form> */}
                </>) : <></>}

                
            </div>

            {/* {content_bottombar} */}
        </div>
        </>
    );

}
