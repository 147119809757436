import React from 'react';
import utils from './utils';
import firebaseapp from './firebaseapp';
import { getDatabase } from 'firebase/database';
import constants_fixed from './constants_fixed';
// import { initializeApp } from 'firebase/app';

// export const firebaseApp = initializeApp(constants.getFirebaseConfig());

const databaseURL = "https://mirkwood-793fc-default-rtdb.asia-southeast1.firebasedatabase.app";

const firebase_config = {
    apiKey: "AIzaSyDVOIZo-K26JuUD-b7lxpkR0X-WvLnCfg0",
    authDomain: "mirkwood-793fc.firebaseapp.com",
    databaseURL: databaseURL,
    projectId: "mirkwood-793fc",
    storageBucket: "mirkwood-793fc.appspot.com",
    messagingSenderId: "917834021129",
    appId: "1:917834021129:web:1c272621733623aa30bbd8",
    measurementId: "G-JCS1RPXGB5"
  };

const database = getDatabase(firebaseapp, databaseURL);
export const companyName = "Checkout Companion";

export const deviceTypes = {
    MOBILE: 'MOBILE',
    TABLET: 'TABLET',
    DESKTOP: 'DESKTOP',
  };

const timeSegmentsDay = {
    early_morning : {title : "Early morning", timeline : {from : {hour : 0, minute : 0}, to : {hour : 6, minute : 0}}},
    morning : {title : "Morning", timeline : {from : {hour : 6, minute : 0}, to : {hour : 12, minute : 0}}},
    afternoon : {title : "Afternoon", timeline : {from : {hour : 12, minute : 0}, to : {hour : 18, minute : 0}}},
    evening : {title : "Evening", timeline : {from : {hour : 18, minute : 0}, to : {hour : 24, minute : 0}}},
};
  
//   const getDeviceTypeByWidth = () => {
//     let width = null;
//     if (window) width = window.innerWidth > 0 ? window.innerWidth : screen.width;
//     else width = 360;
  
//     if (width <= 1023) return deviceTypes.MOBILE;
//     if (width > 1023) return deviceTypes.DESKTOP;
//     // if (width > 768 && width <= 1023) return deviceTypes.TABLET;
//     // if (width > 1023) return deviceTypes.DESKTOP;
  
//     return deviceTypes.MOBILE;
//   };
  
//   export default getDeviceTypeByWidth;

var statuses = [];

const catalogue_heirarchy = {'car-brands' : "car-models", 'car-models' : "years"};

const REALM_API_KEY = "PJaZS91yZX4wuYwxugaAlaOu0Urzicy9gbFDftgEGq3hNtQGH7xt7YpS6Gof25Cy";

const base_url_comms = constants_fixed.getBaseURLPR();
const base_url = constants_fixed.getBaseURL();
const base_url_spares = constants_fixed.getBaseURLPR();
const base_url_other = constants_fixed.getBaseURLPR();

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];




const statuses_interview = {
    proposed: {title : "Proposed", color : "var(--colorOne500)"},
    initial: {title : "Initial", color : "var(--colorOne500)"},
    yet_to_start: {title : "Yet to Start", color : "var(--colorOne300)"},
    started: {title: "Live", color : "var(--colorOne500)"},
    completed: {title : "Completed", color : "var(--colorOne500)"},
};

const guidelines_interview = [
    {
        title : "Check your internet connection",
        text : "Make sure your internet connection is stable and running at a reasonable speed.",
        icon : <i className="ti ti-router"></i>,
    },
    {
        title : "Check your audio/video input",
        text : "Check that your microphone and system camera are live and working well. Also check that your browser has the permission to access your microphone and camera. You can test them on the next screen.",
        icon : <i className="ti ti-device-computer-camera"></i>,
    },
    {
        title : "Keep your audio/video on",
        text : "Make sure you do not turn off your microphone or camera during the interview.",
        icon : <i className="ti ti-microphone"></i>,
    },
    {
        title : "Take notes",
        text : "On the next screen, you can write down any notes that you think you might need for the interview.",
        icon : <i className="ti ti-notes"></i>,
    },
    {
        title : "Be mindful of the time",
        text : "There are set time-limits for every question. Please be careful of the time and try to finish your answer within the time-limit.",
        icon : <i className="ti ti-hourglass-high"></i>,
    },
]



const monitorSections = [
    {
        section : "Dashboard", key : "dashboard", icon : <i className="ti ti-layout-dashboard"></i>,
        status : "active",
        middleTabs : [
            {key : "home", title : "Home"},]
    },
    {
        section : "Chats", key : "chats", icon : <i className="ti ti-messages"></i>,
        status : "active",
        middleTabs : [
            {key : "live", title : "Live"},
            {key : "archives", title : "Archives",
                filtersPrimary: {
                    timeline : [
                                {title : "All", id : "all"},
                                {title : "Today", id : "today"},
                                {title : "Yesterday", id : "yesterday"},
                                {title : "Last 3 days", id : "last3days"},
                                {title : "Last 7 days", id : "last7days"},
                                {title : "Last 15 days", id : "last15days"},
                                {title : "Last 30 days", id : "last30days"},
                                {title : "Custom", id : "custom"}
                            ]
                },
            },]
    },
    {
        section : "Interviews", key : "interviews", icon : <i className="ti ti-video-plus"></i>,
        status : "inactive",
        middleTabs : [
            {key : "updates", title : "Updates"},
            {key : "schedule", title : "Schedule", 
                filtersPrimary: {
                    timeline : [
                                {title : "Today", id : "today"},
                                {title : "Tomorrow", id : "tomorrow"},
                                {title : "Next 3 days", id : "next3days"},
                                {title : "Next 7 days", id : "next7days"},
                                {title : "Next 15 days", id : "next15days"},
                                {title : "Next 30 days", id : "next30days"},
                                {title : "Custom", id : "custom"}
                            ]
                    },
            },
            {key : "history", title : "History"},
            {key : "management", title : "Manage"},]
    },
    {
        section : "Live", key : "live", icon : <i className="ti ti-broadcast"></i>,
        status : "active",
        middleTabs : [
            {key : "home", title : "Home"},]
    },
    {
        section : "Candidates", key : "candidates", icon : <i className="ti ti-users"></i>,
        status : "inactive",
        middleTabs : [
            {key : "updates", title : "Updates"},
            // {key : "interviewed", title : "Interviewed"},
            {key : "screened", title : "Screened"},
            // {key : "all", title : "All"},
            // {key : "hot", title : "Hot", color : "var(--colorThree900)", icon : <i className="ti ti-flame"></i>},
            // {key : "warm", title : "Warm"},
            // {key : "cold", title : "Cold"},
            {key : "management", title : "Manage"},]
    },
];
const sidebarFooterSections = [
    {
        title : "Integrations", key : "integrations",
        middleTabs : [
            {key : "shopify", title : "Shopify"},
            {key : "whatsapp", title : "WhatsApp"},
            ]
    },
    {
        title : "Help & Support", key : "support",
        middleTabs : [
            {key : "docs", title : "Docs"},
            ]
    },
    {
        title : "Settings", key : "settings",
        middleTabs : [
            {key : "profile", title : "Profile"},
            ]
    },
]
const subsectionPageContent = [
    // {key : "tickets", role : "superadmin", subtitle : "List of tickets opened by customer"},
    {key : "customers", roles : ["superadmin", "oem_superadmin", "state_head", "city_head"], subtitle : "List of customers"},
    {key : "finance", roles : ["superadmin", "oem_superadmin", "state_head", "city_head"], subtitle : "Payments and Invoices"},
    {key : "users", roles : ["superadmin", "oem_superadmin", "state_head", "city_head"], subtitle : "View your Team"},
    {key : "oems", roles : ["superadmin"], subtitle : "View your OEMs"},
];









class constants{
    getFirebaseDatabase(){
        return database;
    }
    getTimeSegmentsDay(){
        return timeSegmentsDay;
    }
    getDateRangeFromTimeline(timeline){
        let dateFrom = new Date();
        let dateTo = new Date();
        switch(timeline){
            case "all" : return {dateFrom : null, dateTo : null};
            case "today" : return {dateFrom : dateFrom, dateTo : dateTo};
            case "tomorrow" : dateFrom.setDate(dateFrom.getDate() + 1); dateTo.setDate(dateTo.getDate() + 1); return {dateFrom : dateFrom, dateTo : dateTo};
            case "next3days" : dateTo.setDate(dateTo.getDate() + 3); return {dateFrom : dateFrom, dateTo : dateTo};
            case "next7days" : dateTo.setDate(dateTo.getDate() + 7); return {dateFrom : dateFrom, dateTo : dateTo};
            case "next15days" : dateTo.setDate(dateTo.getDate() + 15); return {dateFrom : dateFrom, dateTo : dateTo};
            case "next30days" : dateTo.setDate(dateTo.getDate() + 30); return {dateFrom : dateFrom, dateTo : dateTo};
            case "yesterday" : dateFrom.setDate(dateFrom.getDate() - 1); dateTo.setDate(dateTo.getDate() - 1); return {dateFrom : dateFrom, dateTo : dateTo};
            case "last3days" : dateFrom.setDate(dateTo.getDate() - 3); return {dateFrom : dateFrom, dateTo : dateTo};
            case "last7days" : dateFrom.setDate(dateTo.getDate() - 7); return {dateFrom : dateFrom, dateTo : dateTo};
            case "last15days" : dateFrom.setDate(dateTo.getDate() - 15); return {dateFrom : dateFrom, dateTo : dateTo};
            case "last30days" : dateFrom.setDate(dateTo.getDate() - 30); return {dateFrom : dateFrom, dateTo : dateTo};
        }
    }
    getSidebarFooterSections(){
        return sidebarFooterSections;
    }
    getSidebarFooterSection(key){
        return sidebarFooterSections.find(o => o.key == key);
    }
    checkSidebarFooterTabsExist(section){
        return sidebarFooterSections.find(o => o.key == section).middleTabs ? true : false;
    }
    getSidebarFooterTabByIndex(section, index){
        return sidebarFooterSections.find(o => o.key == section).middleTabs[index];
    }
    getSidebarFooterTabByKey(section, tab){
        return sidebarFooterSections.find(o => o.key == section)?.middleTabs?.find(o => o.key == tab);
    }

    getMonitorSections(){
        return monitorSections.filter(o => o.status == "active");
    }
    getMonitorSection(key){
        return monitorSections.find(o => o.key == key);
    }
    checkMonitorTabsExist(section){
        return monitorSections.find(o => o.key == section).middleTabs ? true : false;
    }
    getMonitorTabByIndex(section, index){
        return monitorSections.find(o => o.key == section).middleTabs[index];
    }
    getMonitorTabByKey(section, tab){
        return monitorSections.find(o => o.key == section)?.middleTabs?.find(o => o.key == tab);
    }
    getInterviewStateCandidateLS(){
        const stateJSON = localStorage.getItem("interviewStateCandidate");
        return stateJSON ? JSON.parse(stateJSON) : null;
    }
    setInterviewStateCandidateLS(state){
        localStorage.setItem("interviewStateCandidate", JSON.stringify(state));
    }
    clearInterviewStateCandidateLS(){
        return localStorage.removeItem("interviewStateCandidate");
    }
    getGuidelinesInterview(){
        return guidelines_interview;
    }
    getStatusesInterview(){
        return statuses_interview;
    }
    getStatusInterview(status){
        return statuses_interview[status] ? statuses_interview[status].title : "NA";
    }
    getStatusColorInterview(status){
        return statuses_interview[status] ? statuses_interview[status].color : "var(--colorGrayE154)";
    }
    getRoles(){
        let roles = utils.getSavedList("roles");
        return roles.map(o => o.toUpperCase()).join(", ");
    }
    getPrimaryRole(){
        let roles = utils.getSavedList("roles");
        if (roles.includes("superadmin")) return "superadmin";
        if (roles.includes("oem_superadmin")) return "oem_superadmin";
        if (roles.includes("state_head")) return "state_head";
        if (roles.includes("city_head")) return "city_head";
    }
    isStateHead(){
        let roles = utils.getSavedList("roles");
        if (roles.includes("state_head")) return true;
        return false;
    }
    isCityHead(){
        let roles = utils.getSavedList("roles");
        if (roles.includes("city_head")) return true;
        return false;
    }
    isAdmin(){
        let roles = utils.getSavedList("roles");
        if (roles.includes("superadmin") || roles.includes("oem_superadmin")) return true;
    }
    // getSidebarSections(){
    //     return sidebarSections;
    // }
    // getSidebarSubsections(role){
    //     console.log("roles", role);
    //     return sidebarSubsections.filter(o => o.roles.includes(this.getRole()));
    // }
    // getSidebarSubsectionsBySection(role, section){
    //     return sidebarSubsections.filter(o => o.section == section && o.roles.includes(this.getRole()));
    // }
    // getSidebarSubsectionByKey(role, key){
    //     console.log("hereXXRoles", this.getRole());
    //     return sidebarSubsections.find(o => o.key == key && o.roles.includes(this.getRole()));
    // }
    // getSubsectionContent(role, key){
    //     return subsectionPageContent.find(o => o.key == key && o.roles.includes(this.getRole()));
    // }


    getDeviceTypes(){
        return deviceTypes;
    }
    getDeviceTypeByWidth = () => {
        let width = null;
        if (window) width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
        else width = 360;
      
        if (width <= 767) return deviceTypes.MOBILE;
        if (width > 767) return deviceTypes.DESKTOP;
        // if (width > 768 && width <= 1023) return deviceTypes.TABLET;
        // if (width > 1023) return deviceTypes.DESKTOP;
      
        return deviceTypes.MOBILE;
      };

    getRealmAPIKey(){
        return REALM_API_KEY;
    }
    getDatabaseURL(){
        return databaseURL;
    }
    getFirebaseConfig(){
        return firebase_config;
    }
    getBaseURLPR(){
        return base_url_comms;
    }
    getBaseURLOther(){
        return base_url_other;
    }
    getBaseURL(){
        return base_url;
    }
    getBaseURLSpares(){
        return base_url_spares;
    }
    orderTypes(){
        return {101 : "General", 100: "Preventive", 103: "Insurance", 102 : "Document"};
    }

    catalogueHeirarchy(){
        return catalogue_heirarchy;
    }
    catalogueHeirarchyByKey(key){
        return catalogue_heirarchy[key];
    }

    stores(){
        return JSON.parse(localStorage.getItem("stores"));
    }

    brands(){
        return JSON.parse(localStorage.getItem("brands"));
    }
    carBrands(){
        return JSON.parse(localStorage.getItem("car-brands"));
    }
    carModels(){
        return JSON.parse(localStorage.getItem("car-models"));
    }
    segments(){
        return JSON.parse(localStorage.getItem("segments"));
    }
    categories(){
        return JSON.parse(localStorage.getItem("categories"));
    }
    compatibility(){
        return JSON.parse(localStorage.getItem("compatibility"));
    }
    countries(){
        return JSON.parse(localStorage.getItem("countries"));
    }

    getSavedListItemByID(type, id){
        return JSON.parse(localStorage.getItem(type)).find(o => o._id == id);
    }

    setCompatibility(data){
        localStorage.setItem("compatibility", JSON.stringify(data));
    }

    setBrands(data){
        localStorage.setItem("brands", JSON.stringify(data));
    }

    setCarBrands(data){
        localStorage.setItem("car-brands", JSON.stringify(data));
    }

    setCarModels(data){
        localStorage.setItem("car-models", JSON.stringify(data));
    }

    setSegments(data){
        localStorage.setItem("segments", JSON.stringify(data));
    }

    setCategories(data){
        localStorage.setItem("categories", JSON.stringify(data));
    }

    setCountries(data){
        localStorage.setItem("countries", JSON.stringify(data));
    }
    
    setStatuses(data){
        localStorage.setItem("statuses", JSON.stringify(data));
    }

    setFleetsSummary(data){
        localStorage.setItem("fleets_summary", JSON.stringify(data));
    }

    setGaragesSummary(data){
        localStorage.setItem("garages_summary", JSON.stringify(data));
    }
    
    statuses(){
        return statuses;
    }

    getModelsByBrand(brand_id){
        // //console.log("model_id", model_id);
        var models = this.carModels().filter(o => o.brand_id == brand_id);
        return models;
    }

    getYearsByModel(model_id){
        // //console.log("model_id", model_id);
        var model = this.getSavedListItemByID("car-models", model_id);
        var compatibility = this.compatibility();
        // //console.log("compatibility", compatibility);
        compatibility = compatibility.filter(obj => obj.model == model.name).slice();
        // //console.log("compatibility1", compatibility);
        compatibility.sort(function(a, b) {
            return a.year - b.year;
        });
        const currentYear = (new Date()).getFullYear();
        if(model.name=="1000"){
            // //console.log("compatibilityX", compatibility);
        }
        var end_year = compatibility[compatibility.length-1].end_year ? (compatibility[compatibility.length-1].end_year.toLowerCase() == "now" ? currentYear : parseInt(compatibility[compatibility.length-1].end_year)) : currentYear;
        var years = [];
        for(var i = compatibility[0].year;i<=end_year;i++){
            // years.push({name : i+"", _id : Math.random().toString(36).slice(2)});
            years.push({name : i+"", _id : model_id + "_"+i});
        }
        // //console.log("years", years);
        return years;
    }

    getFuelsByYearAndModel(year_id, model_id){
        var split = year_id.split("_");
        var year = parseInt(split[1]);
        var model = this.getSavedListItemByID("car-models", split[0]);
        var compatibility = this.compatibility();
        // //console.log("compatibility", compatibility);
        var compatibility_cng = compatibility.filter(obj => obj.model == model.name && obj.fuel_engine.toLowerCase() == "cng").slice();
        var compatibility_petrol = compatibility.filter(obj => obj.model == model.name && obj.fuel_engine.toLowerCase() == "petrol").slice();
        var compatibility_diesel = compatibility.filter(obj => obj.model == model.name && obj.fuel_engine.toLowerCase() == "diesel").slice();
        // //console.log("compatibility_cng", compatibility_cng);
        // //console.log("compatibility_petrol", compatibility_petrol);
        // //console.log("compatibility_diesel", compatibility_diesel);
        compatibility_cng.sort(function(a, b) {
            return b.year - a.year;
        });
        compatibility_petrol.sort(function(a, b) {
            return b.year - a.year;
        });
        compatibility_diesel.sort(function(a, b) {
            return b.year - a.year;
        });
        const currentYear = (new Date()).getFullYear();
        var fuels = [];
        for(var i = 0;i<compatibility_cng.length;i++){
            // //console.log("cng", compatibility_cng[i]);
            var start_year = compatibility_cng[i].year;
            var end_year = compatibility_cng[i].end_year ? (compatibility_cng[i].end_year.toLowerCase() == "now" ? currentYear : parseInt(compatibility_cng[i].end_year)) : currentYear;
            if(year <= end_year && year >= start_year){
                fuels.push({name : "CNG", _id : year_id + "_"+"CNG"});
                break;
            }
        }
        for(var i = 0;i<compatibility_petrol.length;i++){
            var start_year = compatibility_petrol[i].year;
            var end_year = compatibility_petrol[i].end_year ? (compatibility_petrol[i].end_year.toLowerCase() == "now" ? currentYear : parseInt(compatibility_petrol[i].end_year)) : currentYear;
            if(year <= end_year && year >= start_year){
                fuels.push({name : "Petrol", _id : year_id + "_"+"Petrol"});
                break;
            }
        }
        for(var i = 0;i<compatibility_diesel.length;i++){
            var start_year = compatibility_diesel[i].year;
            var end_year = compatibility_diesel[i].end_year ? (compatibility_diesel[i].end_year.toLowerCase() == "now" ? currentYear : parseInt(compatibility_diesel[i].end_year)) : currentYear;
            if(year <= end_year && year >= start_year){
                fuels.push({name : "Diesel", _id : year_id + "_"+"Diesel"});
                break;
            }
        }
        // //console.log("fuels", fuels);
        return fuels;
    }

    getVariantsByYearAndModelAndFuel(year_id, model_id, fuel_id){
        // //console.log(fuel_id);
        var split = fuel_id.split("_");
        var fuel = split[2];
        var year = parseInt(split[1]);
        var model = this.getSavedListItemByID("car-models", split[0]);
        var compatibility = this.compatibility();
        var compatibility = compatibility.filter(obj => obj.model == model.name && obj.fuel_engine.toLowerCase() == fuel.toLowerCase()).slice();
        compatibility.sort(function(a, b) {
            return b.year - a.year;
        });
        const currentYear = (new Date()).getFullYear();
        var variants = [];
        for(var i = 0;i<compatibility.length;i++){
            // //console.log("fuel", compatibility[i]);
            var start_year = compatibility[i].year;
            var end_year = compatibility[i].end_year ? (compatibility[i].end_year.toLowerCase() == "now" ? currentYear : parseInt(compatibility[i].end_year)) : currentYear;
            if(year <= end_year && year >= start_year){
                variants.push({name : compatibility[i].varient, _id : fuel_id + "_"+compatibility[i].varient+"_"+compatibility[i].compatibility_group_id, compatibility_group_id : compatibility[i].compatibility_group_id});
            }
        }
        return variants;
    }

    getAllVariantNamesByModel(model_id){
        // //console.log(fuel_id);
        var model = this.getSavedListItemByID("car-models", model_id);
        var compatibility = this.compatibility();
        var compatibility = compatibility.filter(obj => obj.model == model.name).slice();
        var variants = Array.from(new Set(compatibility.map(o => o.varient.trim())));
        return variants;
    }

    


    getIconByCategoryName(name){
        var cat = this.categories().find(o => o.name == name);
        return cat ? cat.icon : null;
    }


    getMessagingThreadPersonsString(persons){
        var personsSliced = persons.slice();
        //console.log("personsSlicked", personsSliced);
        var personsString = "";
        for (var i=0;i<personsSliced.length;i++){
            personsString += (personsSliced[i].first_name + " " + personsSliced[i].last_name);
            if(i == 0 && personsSliced.length == 2){
                personsString += ", "
            }else if(i == 2 & personsSliced.length > 2){
                personsString += " & " + (personsSliced.length - 2) + " others"
                break;
            }
        }
        //console.log("personsSlicked", personsString);
        return personsString;
    }

    getMessagingThreadPersons(persons){
        var personsSliced = persons.slice();
        return personsSliced;
    }

    getMessagingThreadOrg(orgs){
        var orgsSliced = orgs.slice();
        return orgsSliced.filter(o => o.id != localStorage.getItem("org_id"));
    }

    getCountryByID(id){
        return this.countries().find(o => o.id == id);
    }


    getKeywordsList(text) {
        var wordList = text.split(' ');
        var keywordsList = [];
        while (wordList.length > 0) {
            keywordsList = keywordsList.concat(this.genKeyWords(wordList));
            wordList.shift();
        }
        return keywordsList;
    }
    
    genKeyWords(wordsList) {
        var res = [wordsList.join(' ')];
        if (wordsList.length > 1) {
            return res.concat(this.genKeyWords(wordsList.slice(0, -1)));
        } else {
            return res;
        }
    }

    calcDateDiffInMinutes(dateA, dateB) {
        // console.log("diff", dateA - dateB, this.getTimeDifferenceFromMill(dateA - dateB));
        // return Math.floor(((dateA.getTime() - dateB.getTime()) / 1000) % 60); // TODO CALCULATIONS HERE
        return this.getTimeDifferenceFromMill1(dateA - dateB);
    }

    get_DateX = function(date1){
        var nowdate = new Date();
        var date = new Date(date1);
        date = new Date(date - 330 * 60000);
        
        // var month = date.getMonth() + 1;
        // var day = date.getDate();
        // var hour = date.getHours();
        // var minute = date.getMinutes();
        // var ampm = hour < 12 ? "AM" : "PM";
        // if (hour >= 12) {
        //     hour = hour - 12;
        // }
        // if (hour == 0) {
        //     hour = 12;
        // }
        // if (date <= 9){
        //     date="0"+date;
        // }
        // if (hour <= 9){
        //     hour="0"+hour;
        // }
        // if (minute <= 9){
        //     minute="0"+minute;
        // }
        // if (month <= 9){
        //     month="0"+month;
        // }
        // if (day <= 9){
        //     day="0"+day;
        // }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        // var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

        //         return dateString;
        return date;
    };

    get_DateXWithBuffer = function(date1, buffer){
        var nowdate = new Date();
        var date = new Date(date1);
        date = new Date(date - (330 * 60 * 1000) + (buffer * 1000));
        
        // var month = date.getMonth() + 1;
        // var day = date.getDate();
        // var hour = date.getHours();
        // var minute = date.getMinutes();
        // var ampm = hour < 12 ? "AM" : "PM";
        // if (hour >= 12) {
        //     hour = hour - 12;
        // }
        // if (hour == 0) {
        //     hour = 12;
        // }
        // if (date <= 9){
        //     date="0"+date;
        // }
        // if (hour <= 9){
        //     hour="0"+hour;
        // }
        // if (minute <= 9){
        //     minute="0"+minute;
        // }
        // if (month <= 9){
        //     month="0"+month;
        // }
        // if (day <= 9){
        //     day="0"+day;
        // }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        // var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

        //         return dateString;
        return date;
    };


    getAbsoluteISTTime(date){
        var dateGMT = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        var dateIST = new Date(dateGMT.getTime() + 330*60000);
        return dateIST;
    }
    


    getTimeDifferenceFromMill(diff){
        // console.log("diff1", diff);
        var secs = Math.floor(diff/1000);
        if (secs < 60){
            return secs+"s";
        }else{
            var mins = Math.floor(secs/60);
            if (mins < 60){
                secs = secs % 60;
                return mins+"m"+ " " + secs+"s";
            }else{
                var hours = Math.floor(mins/60);
                if (hours < 24){
                    mins = mins % 60;
                    return hours+"h"+ " " + mins+"m";
                }else{
                    var days = Math.floor(hours/24);
                    hours = hours % 24;
                    return days+"d"+ " " + hours+"h";
                }
                // mins = mins % 60;
                // return hours+"h"+ " " + mins+"m";
            }
        }
    }

    getTimeDifferenceFromMill1(diff){
        // console.log("diff1", diff);
        var secs = Math.floor(diff/1000);
        var mins = Math.floor(secs/60);
        var hours = Math.floor(mins/60);
        var days = Math.floor(hours/24);

        if (secs < 60){
            return String(secs).padStart(2, "0")+"s";
        }else{
            if (mins < 60){
                secs = secs % 60;
                return String(mins).padStart(2, "0")+"m"+ " " + String(secs).padStart(2, "0")+"s";
            }else{
                if (hours < 24){
                    mins = mins % 60;
                    secs = secs % 60;
                    return String(hours).padStart(2, "0")+"h"+ " " + String(mins).padStart(2, "0")+"m" + " " + String(secs).padStart(2, "0")+"s";
                }else{
                    hours = hours % 24;
                    mins = mins % 60;
                    secs = secs % 60;
                    return days+"d"+ " " + String(hours).padStart(2, "0")+"h" + " " + String(mins).padStart(2, "0")+"m" + " " + String(secs).padStart(2, "0")+"s";
                }
                // mins = mins % 60;
                // return hours+"h"+ " " + mins+"m";
            }
        }
    }

    get_TimeString = function(date){
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9){
            date="0"+date;
        }
        if (hour <= 9){
            hour="0"+hour;
        }
        if (minute <= 9){
            minute="0"+minute;
        }
        
        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

                return timeString;
    };

    get_DateString = function(date){
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9){
            date="0"+date;
        }
        if (hour <= 9){
            hour="0"+hour;
        }
        if (minute <= 9){
            minute="0"+minute;
        }
        if (month <= 9){
            month="0"+month;
        }
        if (day <= 9){
            day="0"+day;
        }

        var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        var timeString = hour + ":" + minute + " " + ampm;
        dateString += timeString;

                return dateString;
    };

    get_DateStringX = function(date1){
        var nowdate = new Date();
        var date = new Date(date1);
        date = new Date(date - 330 * 60000);
        
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9){
            date="0"+date;
        }
        if (hour <= 9){
            hour="0"+hour;
        }
        if (minute <= 9){
            minute="0"+minute;
        }
        if (month <= 9){
            month="0"+month;
        }
        if (day <= 9){
            day="0"+day;
        }

        var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        var timeString = hour + ":" + minute + " " + ampm;
        dateString += timeString;

                return dateString;
    };

    get_DateStringX1 = function(date1){
        var nowdate = new Date();
        var date = new Date(date1);
        date = new Date(date - 330 * 60000);
        
        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (month <= 9){
            month="0"+month;
        }
        if (day <= 9){
            day="0"+day;
        }

        var dateString = day + "/" + month + "/" + date.getFullYear();

                return dateString;
    };


    get_DateString1 = function(date1, shortMonth = false){
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9){
            date="0"+date;
        }
        if (hour <= 9){
            hour="0"+hour;
        }
        if (minute <= 9){
            minute="0"+minute;
        }
        if (month <= 9){
            month="0"+month;
        }
        if (day <= 9){
            day="0"+day;
        }

        if (date.getDate() == nowdate.getDate() && date.getMonth() == nowdate.getMonth() && date.getYear() == nowdate.getYear()){
            return hour + ":" + minute + " " + ampm;
        }else{
            if (date.getYear() != nowdate.getYear()){
                return (shortMonth ? monthNamesShort[month-1] : monthNames[month-1]) + " " + date.getFullYear();
            }else{
                return day + " " + (shortMonth ? monthNamesShort[month-1] : monthNames[month-1]); 
            }
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        // var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

        //         return dateString;
    };

    get_DateString1Date = function(date1){
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (date <= 9){
            date="0"+date;
        }
        if (month <= 9){
            month="0"+month;
        }
        if (day <= 9){
            day="0"+day;
        }

        if (date.getDate() == nowdate.getDate() && date.getMonth() == nowdate.getMonth() && date.getYear() == nowdate.getYear()){
            return "Today";
        }else{
            if (date.getYear() != nowdate.getYear()){
                return day + " " + monthNames[month-1] + " " + date.getFullYear();
            }else{
                return day + " " + monthNames[month-1]; 
            }
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        // var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

        //         return dateString;
    };

    get_DateString2 = function(date1){
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9){
            date="0"+date;
        }
        if (hour <= 9){
            hour="0"+hour;
        }
        if (minute <= 9){
            minute="0"+minute;
        }
        if (month <= 9){
            month="0"+month;
        }
        if (day <= 9){
            day="0"+day;
        }


        var dateString = day + " " + monthNames[month-1] + " " + date.getFullYear() + ", ";
        var timeString = hour + ":" + minute + " " + ampm;
        dateString += timeString;

                return dateString;
    };


    get_DateString3 = function(date1){
        var nowdate = new Date();
        var date = new Date(date1);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var ampm = hour < 12 ? "AM" : "PM";
        if (hour >= 12) {
            hour = hour - 12;
        }
        if (hour == 0) {
            hour = 12;
        }
        if (date <= 9){
            date="0"+date;
        }
        if (hour <= 9){
            hour="0"+hour;
        }
        if (minute <= 9){
            minute="0"+minute;
        }
        if (month <= 9){
            month="0"+month;
        }
        if (day <= 9){
            day="0"+day;
        }

        var dateString = "";

        if (date.getDate() == nowdate.getDate() && date.getMonth() == nowdate.getMonth() && date.getYear() == nowdate.getYear()){
            dateString = "Today";
        }else{
            if (date.getYear() != nowdate.getYear()){
                dateString = day + " " + monthNames[month-1] + " " + date.getFullYear();
            }else{
                dateString = day + " " + monthNames[month-1]; 
            }
        }

        return dateString + ", " + hour + ":" + minute + " " + ampm;

        
    };


    get_TimeComparison = function(date1, date2){
        var date11 = new Date(date1);
        var date22 = new Date(date2);

        if (date11.getDate() == date22.getDate() && date11.getMonth() == date22.getMonth() && date11.getYear() == date22.getYear()){
            return true;
        }else{
            return false;
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        // var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

        //         return dateString;
    };

    get_TimeComparison1 = function(date1, date2){
        var date11 = new Date(date1);
        var date22 = new Date(date2);

        if (date11.getDate() == date22.getDate() && date11.getMonth() == date22.getMonth() && date11.getYear() == date22.getYear() && date11.getHours() == date22.getHours() && date11.getMinutes() == date22.getMinutes()){
            return true;
        }else{
            return false;
        }

        // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
        // var timeString = hour + ":" + minute + " " + ampm;
        // dateString += timeString;

        //         return dateString;
    };


    get_DateComparison = function(epoch, type){
        var date = new Date(epoch);
        var datenow = new Date();
        switch (type){
            case "today" : {
                //console.log("datenow", datenow, date);
                if (date.getDate() == datenow.getDate() && date.getMonth() == datenow.getMonth() && date.getFullYear() == datenow.getFullYear()){
                    //console.log("true");
                    return true;
                }
                break;
            }
            case "yesterday" : {
                datenow.setDate(datenow.getDate() - 1);
                //console.log("datenow", datenow, date);
                if (date.getDate() == datenow.getDate() && date.getMonth() == datenow.getMonth() && date.getFullYear() == datenow.getFullYear()){
                    //console.log("true");
                    return true;
                }
                break;
            }
            case "lastweek" : {
                datenow.setDate(datenow.getDate() - 7);
                //console.log("datenow", datenow, date);
                if(epoch > datenow.getTime()){
                    //console.log("true");
                    return true;
                }
                break;
            }
            case "lastmonth" : {
                datenow.setMonth(datenow.getMonth() - 1)
                //console.log("datenow", datenow, date);
                if(epoch > datenow.getTime()){
                    //console.log("true");
                    return true;
                }
                break;
            }
            case "last6months" : {
                datenow.setMonth(datenow.getMonth() - 6)
                //console.log("datenow", datenow, date);
                if(epoch > datenow.getTime()){
                    //console.log("true");
                    return true;
                }
                break;
            }
            case "lastyear" : {
                //console.log("datenow", datenow.getFullYear());
                if (date.getFullYear() == datenow.getFullYear()-1){
                    //console.log("true");
                    return true;
                }
                break;
            }
            default : {
                return false;
            }
        }
        return false;
    }


    initializeTinyMCE(){
        // tinymce.init({
        //     selector: '#mytextarea'
        // });
    }


    base64toBlob(base64Data, contentType) {
        contentType = contentType || '';
        let sliceSize = 1024;
        let byteCharacters = atob(base64Data);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);
    
            let bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    






    getRowDataByTag(tag){
        console.log(tag, "tag");
        // return rowData[tag].filter(o => (!o.panel || o.panel != "right") && (!o.roles || o.roles.length == 0 || o.roles.indexOf(localStorage.getItem('role')) != -1));
        console.log(rowData[tag], "rowData");
        return rowData[tag] ? rowData[tag].filter(o => (!o.panel || o.panel != "details")) : [];
    }

    getValueFromKey(key, data){
        if(key.indexOf(".") != -1){
            var arr = key.split('.');
            var obj = data;
            for(var i = 0; i < arr.length; i++){
                obj = obj ? obj[arr[i]] : null;
            }
            return obj;
        }else{
            // console.log("fatt", key, data);
            return data ? data[key] : "";
        }
        
    }

    getRowDataValue(data, data1){
        if(data.extra_key1){
            return this.getRowDataValue1(data, this.getValueFromKey(data.key, data1), this.getValueFromKey(data.extra_key, data1), this.getValueFromKey(data.extra_key1, data1));
        }else if(data.extra_key){
            return this.getRowDataValue1(data, this.getValueFromKey(data.key, data1), this.getValueFromKey(data.extra_key, data1));
        }else{
            return this.getRowDataValue1(data, this.getValueFromKey(data.key, data1));
        }
    }
    // getRowDataValue(data, data1){
    //     var value = null;
    //     if(data.key.indexOf(".") != -1){
    //         var arr = data.key.split('.');
    //         var obj = data1;
    //         for(var i = 0; i < arr.length; i++){
    //             obj = obj ? obj[arr[i]] : null;
    //         }
    //         return this.getRowDataValue1(data, obj);
    //     }else{
    //         return this.getRowDataValue1(data, data1[data.key]);
    //     }
    // }

    getRowDataValue1(data, obj, extra_value = null, extra_value1 = null){
        console.log("dValue1X", data, obj);
        var value = null;
        if (data.type){
            if(obj){
                switch(data.type){
                    case "boolean":
                        value = obj ? "True" : "False";
                        break;
                    case "mongodate":
                        value = obj && obj["$date"] ? constants.get_DateStringX(obj["$date"]) : "";
                        break;
                    case "firebasedate":
                        value = constants.get_DateString2(obj);
                        break;
                    case "object_count":
                        value = Object.keys(obj).length;
                        break;
                    case "object_key_sum":
                        if (Object.values(obj).length == 0){
                            value = 0;
                        }else{
                            value = Object.values(obj).map(o => o[data.key_sum] ? parseInt(o[data.key_sum]) : 0).reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                        }
                        break;
                    case "array_key_sum":
                        var value1 = obj.map(o => o[data.key_sum] ? parseInt(o[data.key_sum]) : 0);
                        value = value1.length == 0 ? 0 : value1.reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                        break;
                    case "array_key_sum_with_filter":
                        var value1 = obj.filter(o => o[data.filter_key] == data.filter_key_value).map(o => o[data.key_sum] ? parseInt(o[data.key_sum]) : 0);
                        value = value1.length == 0 ? 0 : value1.reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                        break;
                    case "list_count":
                        value = obj.length;
                        break;
                    case "list_count_with_filter":
                        value = obj.filter(o => o[data.filter_key] == data.filter_key_value).length;
                        break;
                    case "list_count_unique_with_filter":
                        // value = [...new Set(obj.filter(o => o[data.filter_key] == data.filter_key_value).map(item => item[data.unique_key]))].length;
                        value = this.getSKUDataByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value).length;
                        break;
                    case "list_count_unique":
                        // value = [...new Set(obj.map(item => item[data.unique_key]))].length;
                        value = this.getSKUDataByGrouping(obj, data.unique_key, null, null).length;
                        break;
                    case "array_string":
                        value = obj.join();
                        break;
                    case "array_object_string":
                        value = obj.map(o => o[data.key_display]).join();
                        break;
                    case "array_object_string_unique":
                        value = [... new Set(obj.map(o => o[data.key_display]))].join();
                        break;
                    case "localstorage_fetch":
                        if (data.localstorage_id.includes(",")){
                            var list = [];
                            var split = data.localstorage_id.split(",");
                            for(var i = 0; i < split.length; i++){
                                list = this.getSavedList(split[i].trim()).find(o => o[data.key_fetch] == obj);
                                value = this.getValueFromKey(data.key_display, list);
                                if(value && value.trim() != ""){
                                    break;
                                }
                            }
                        }else{
                            var list = this.getSavedList(data.localstorage_id).find(o => o[data.key_fetch] == obj);
                            // value = this.getSavedList(data.localstorage_id).find(o => o[data.key_fetch] == obj)[data.key_display]
                            value = this.getValueFromKey(data.key_display, list);
                        }
                        break;
                    case "custom":
                        switch(data.type1){
                            case "name_with_initials_image":
                                let initials = utils.getInitialsFromName(obj);
                                value = 
                                    <div className="border-box flex_horizontal center_vertical start_horizontal">
                                        <div className="border-box flex_horizontal center_vertical center_horizontal" style={{width : 30, height : 30, borderRadius : 15, fontSize : "12px", backgroundColor : "var(--colorFour100)", color : "var(--colorFour700)", marginRight : "10px"}}>
                                            {initials}
                                        </div>
                                        {obj}
                                    </div>;
                                break;
                            case "name_and_vehicle_with_initials_image":
                                let initials1 = utils.getInitialsFromName(obj);
                                var vehicles, vehicle;
                                if (Array.isArray(extra_value)){
                                    vehicles = extra_value ? extra_value : [];
                                    vehicle = vehicles.length == 0 ? null : vehicles[vehicles.length - 1];
                                }else{
                                    vehicle = extra_value;
                                }
                                
                                value = vehicle ?
                                    <div className="border-box flex_horizontal center_vertical start_horizontal">
                                        <div className="border-box flex_horizontal center_vertical center_horizontal" style={{width : 30, height : 30, borderRadius : 15, fontSize : "12px", backgroundColor : "var(--colorFour100)", color : "var(--colorFour700)", marginRight : "10px"}}>
                                            {initials1}
                                        </div>
                                        <div className="border-box flex_vertical center_vertical start_horizontal">
                                            <div className="border-box weight500" style={{marginBottom : "2px", fontSize : "12px"}}>
                                                {obj}
                                            </div>
                                            <div className="border-box weight200" style={{fontSize : "10px"}}>
                                                {vehicle.brand_name + " " + vehicle.model_name}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    :
                                    <div className="border-box flex_horizontal center_vertical start_horizontal">
                                        <div className="border-box flex_horizontal center_vertical center_horizontal" style={{width : 30, height : 30, borderRadius : 15, fontSize : "12px", backgroundColor : "var(--colorFour100)", color : "var(--colorFour700)", marginRight : "10px"}}>
                                            {initials1}
                                        </div>
                                        <span className="weight500">{obj}</span>
                                    </div>;
                                break;
                            case "name_and_email_with_initials_image":
                                let initials2 = utils.getInitialsFromName(obj);
                                value = <div className="border-box flex_horizontal center_vertical start_horizontal">
                                            <div className="border-box flex_horizontal center_vertical center_horizontal" style={{width : 30, height : 30, borderRadius : 15, fontSize : "12px", backgroundColor : "var(--colorFour100)", color : "var(--colorFour700)", marginRight : "10px"}}>
                                                {initials2}
                                            </div>
                                            <div className="border-box flex_vertical center_vertical start_horizontal">
                                                <div className="border-box weight500" style={{marginBottom : "2px", fontSize : "12px"}}>
                                                    {obj}
                                                </div>
                                                <div className="border-box weight200" style={{fontSize : "10px"}}>
                                                    {extra_value}
                                                </div>
                                            </div>
                                            
                                        </div>;
                                break;
                            case "service_status":
                                let style1={width : "max-content", padding : "3px 5px", borderRadius : 5, fontSize : "11px", textTransform : "capitalize"};
                                switch(obj){
                                    case "pending":
                                        style1 = {...style1, backgroundColor : "var(--colorTwo100)", color : "var(--colorTwo900)"};
                                        break;
                                    case "ongoing":
                                        style1 = {...style1, backgroundColor : "var(--colorFour100)", color : "var(--colorFour700)"};
                                        break;
                                    case "completed":
                                        style1 = {...style1, backgroundColor : "var(--colorOne100)", color : "var(--colorOne700)"};
                                        break;
                                    case "cancelled":
                                        style1 = {...style1, backgroundColor : "var(--colorThree100)", color : "var(--colorThree700)"};
                                        break;
                                    default:
                                        style1 = {...style1, backgroundColor : "var(--colorGrayE14)", color : "var(--colorGrayE204)"};
                                        break;
                                }
                                value = obj ?
                                    <div className="border-box weight600" style={style1}>
                                        • {obj}
                                    </div>
                                    :
                                    "NA";
                                break;
                            case "sb_status":
                                var style2={width : "max-content", padding : "3px 5px", borderRadius : 5, fontSize : "11px", textTransform : "capitalize"};
                                switch(obj){
                                    case "pending":
                                        style2 = {...style2, backgroundColor : "var(--colorTwo100)", color : "var(--colorTwo900)"};
                                        break;
                                    case "ongoing":
                                        style2 = {...style2, backgroundColor : "var(--colorFour100)", color : "var(--colorFour700)"};
                                        break;
                                    case "active":
                                        style2 = {...style2, backgroundColor : "var(--colorOne100)", color : "var(--colorOne700)"};
                                        break;
                                    case "cancelled":
                                        style2 = {...style2, backgroundColor : "var(--colorThree100)", color : "var(--colorThree700)"};
                                        break;
                                    default:
                                        style2 = {...style2, backgroundColor : "var(--colorGrayE14)", color : "var(--colorGrayE204)"};
                                        break;
                                }
                                value = obj ?
                                    <div className="border-box weight600" style={style2}>
                                        • {obj}
                                    </div>
                                    :
                                    "NA";
                                break;
                            case "company":
                                let style3={width : "max-content", padding : "3px 5px", borderRadius : 5, fontSize : "11px", textTransform : "capitalize"};
                                style3 = {...style3, backgroundColor : "var(--colorFour100)", color : "var(--colorFour700)"};
                                value = obj ?
                                    <div className="border-box weight600" style={style3}>
                                        • {obj}
                                    </div>
                                    :
                                    "NA";
                                break;
                            case "skus":
                                if(data.unique_key){
                                    if(data.filter_key){
                                        value = this.getSKUDataByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                    }else{
                                        value = this.getSKUDataByGrouping(obj, data.unique_key);
                                    }
                                }else{
                                    value = obj;
                                }
                                break;
                            case "skus1":
                                if(data.filter_key){
                                    value = obj.filter(o => o[data.filter_key] == data.filter_key_value);
                                }else{
                                    value = obj;
                                }
                                break;
                            case "order_channel":
                                value = this.b2bAgents().find(o => o.customer_id == obj) ? "B2B" : "B2C";
                                break;
                            case "dashboard_roles_string1":
                                value = this.dashboardRoles().filter(o => Object.keys(obj).map(o => parseInt(o)).indexOf(o.user_id) != -1).map(o => o.user.name).join(', ');
                                break;
                            case "dashboard_roles_string2":
                                value = this.dashboardRoles().filter(o => obj.map(o => parseInt(o)).indexOf(o.user_id) != -1).map(o => o.user.name).join(', ');
                                break;
                            case "dsps":
                                var dsps = this.dsps();
                                value = obj.map(o => ({...o, dsp_name : dsps.find(o1 => o1.id == o.dsp_id).name}));
                                break;
                            case "dsps_check":
                                // var dsps = this.dsps();
                                // value = obj.map(o => ({...o, dsp_name : dsps.find(o1 => o1.id == o.dsp_id).name}));
                                value = obj.filter(o => o.dispatch_data != null && o.dispatch_data.status == "success" && o.dispatch_data.dsp_id != null).length > 0 ? "True" : "False"
                                break;
                            // case "dsps_details":
                            //     // var dsps = this.dsps();
                            //     // value = obj.map(o => ({...o, dsp_name : dsps.find(o1 => o1.id == o.dsp_id).name}));
                            //     value = 2;
                            //     break;
                            case "dsps_tracking_check":
                                // var dsps = this.dsps();
                                // value = obj.map(o => ({...o, dsp_name : dsps.find(o1 => o1.id == o.dsp_id).name}));
                                value = obj.filter(o => o.dispatch_data != null && o.dispatch_data.status == "success" && o.dispatch_data.dsp_id != null && o.dispatch_data.data && o.dispatch_data.data.tracking_data).length > 0 ? "True" : "False"
                                break;
                            case "dsps_tracking_check1":
                                // var dsps = this.dsps();
                                // value = obj.map(o => ({...o, dsp_name : dsps.find(o1 => o1.id == o.dsp_id).name}));
                                value = obj != null && obj.status == "success" && obj.dsp_id != null && obj.data && obj.data.tracking_data ? "True" : "False"
                                break;
                            case "item_quantity_inner":
                                value = Object.values(obj).map(o => o.item.quantity ? parseInt(o.item.quantity) : 0).reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                                break;
                            case "rfq_type":
                                value = obj ? obj : 1;
                                break;
                            case "vendors_rfq":
                                value = obj.map(o => ({...o, rfq_id : extra_value}));
                                break;
                            case "vendors_rfq2":
                                value = obj.map(o => ({...o, rfq_id : extra_value}));
                                break;
                            case "vendors_rfq_acc":
                                value = obj.map(o => ({...o, rfq_id : extra_value}));
                                break;
                            case "vendor_rfq_acc_global":
                                value = [{...obj, rfq_id : extra_value}];
                                break;
                            case "so_item_brands":
                                value = [...new Set(obj.map(o => o.sku_brand))].join(", ");
                                break;
                            case "order_ids_array_string":
                                value = obj.map(o => o.stockone_order_id).join(", ");
                                break;
                            case "order_customer_firstnames_array_string":
                                value = obj.map(o => o.user_details.first_name).join(", ");
                                break;
                            case "order_created_at_array_string":
                                //IMP
                                value = obj.map(o => constants.get_DateStringX(o.sales_order.created_at["$date"])).join(", ");
                                // value = ""
                                break;
                            case "dropship_vendor_status":
                                value = (obj && obj.status == "enabled") ? "Enabled" : "Disabled";
                                break;
                            case "dropship_bounce_time":
                                value = (obj && obj.bounce_time) ? obj.bounce_time+" days" : "0 days";
                                break;
                            case "dropship_daily_order_limit":
                                value = (obj && obj.daily_order_limit) ? obj.daily_order_limit+" orders" : "0 orders";
                                break;
                            case "dropship_payment_cycle":
                                value = (obj && obj.payment_cycle) ? obj.payment_cycle+" days" : "0 days";
                                break;
                            case "order_channel_array_string":
                                var thatCurrent = this;
                                value = obj.map(o => (thatCurrent.b2bAgents().find(o1 => o1.customer_id == o.created_by) ? "B2B" : "B2C")).join(", ");
                                break;
                            case "sales_invoices_numbers_es":
                                value = obj.map(o => (o.invoice_number)).join(", ");
                                break;
                            case "alt_parts_product_ids":
                                var list = obj ? obj.map(o => o.product_id_1 == extra_value ? o.product_id_2 : o.product_id_1) : [];
                                value = list.join(", ");
                                break;
                            case "alt_parts_product_ids_es":
                                var list = [].concat.apply([], obj.map(o => o.alt_parts ? o.alt_parts.map(o1 => o1.product_id_1 == o.product_id ? o1.product_id_2 : o1.product_id_1) : []));
                                // var list = obj ? obj.map(o => o.product_id_1 == extra_value ? o.product_id_2 : o.product_id_1) : [];
                                value = list.join(", ");
                                break;
                            case "alt_parts_count_es":
                                // var list = [].concat.apply([], obj.alt_parts.map())
                                var list = obj.map(o => o.alt_parts ? o.alt_parts.length : 0);
                                value = list.length == 0 ? 0 : list.reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                                // var list = obj ? obj.map(o => o.product_id_1 == extra_value ? o.product_id_2 : o.product_id_1) : [];
                                // value = list.join(", ");
                                break;
                            case "image_es":
                                value =  [].concat.apply([], obj.map(o => o.image_url));
                                break;
                            case "picklist_status":
                                // var bbs = obj.map(ob => [].concat.apply([], ob.boxes.map(o => Object.values(o.items).map(o1 => o1.quantity))).length == 0 ? 0 : [].concat.apply([], ob.boxes.map(o => Object.values(o.items).map(o1 => o1.quantity))).reduce((prev, next) => (parseInt(prev) + parseInt(next))));
                                // // var list = [].concat.apply([], obj.map(o => Object.values(o.items).map(o1 => o1.quantity)));
                                // var quantity_boxed = bbs.length == 0 ? 0 : bbs.reduce((prev, next) => (parseInt(prev) + parseInt(next)))
                                // value = quantity_boxed == 0 ? "Open" : "Closed";
                                value = obj.length > 0 ? "Closed" : "Open";
                                break;
                            case "item_warehouses":
                                if(data.filter_key){
                                    value = this.getItemWarehousesByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                }else{
                                    value = this.getItemWarehousesByGrouping(obj, data.unique_key);
                                }
                                break;
                            case "dpsp_order_statuses":
                                if(data.filter_key){
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                }else{
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key);
                                }
                                break;
                            case "bo_order_statuses":
                                if(data.filter_key){
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                }else{
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key);
                                }
                                break;
                            case "fo_order_statuses":
                                if(data.filter_key){
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                }else{
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key);
                                }
                                break;
                            case "dpsp_order_item_statuses":
                                if(data.filter_key){
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key, data.filter_key, data.filter_key_value);
                                }else{
                                    value = this.getItemStatusesByGrouping(obj, data.unique_key);
                                }
                                break;
                            case "vendor_all":
                                value = this.getSavedList("vendors").find(o => o["id"] == obj);
                                if(!value){
                                    value = this.getSavedList("acc_vendors").find(o => o["id"] == obj);
                                    if(value){
                                        value["vendor_type"] = "accessories";
                                    }
                                }else{
                                    value["vendor_type"] = "spares";
                                }
                                // value = [].concat.apply([], [this.getSavedList("vendors").find(o => o["id"] == obj), this.getSavedList("acc_vendors").find(o => o["id"] == obj)])
                                // value = [].concat.apply([], [this.getSavedList("vendors"), this.getSavedList("acc_vendors")]).find(o => o["id"] == obj)
                                break;
                            case "vendor":
                                value = this.getSavedList("vendors").find(o => o["id"] == obj)
                                break;
                            case "vendor2":
                                value = this.getSavedList("vendors2").find(o => o["id"] == obj)
                                break;
                            case "acc_vendor":
                                value = this.getSavedList("acc_vendors").find(o => o["id"] == obj)
                                break;
                            case "distributor":
                                value = this.getSavedList("distributors").find(o => o["id"] == obj)
                                break;
                            case "po_grns_items":
                                var list = obj ? [].concat.apply([], obj.map(o => o.items)) : [];
                                // console.log("obbb", obj, obj.grns, obj.grns ? obj.grns.map(o => o.items) : []);
                                value = this.getSKUDataByGrouping(list, "product_id", null, null).length;
                                break;
                            case "po_grns_quantity":
                                var list = obj ? [].concat.apply([], obj.map(o => o.items)) : [];
                                value = list.length;
                                break;
                            case "boxings_incomplete_items_count":
                                value = [].concat.apply([], Object.values(obj ? obj : {}).map(o => Object.values(o))).filter(o => o.quantity != 0).length;
                                break;
                            case "boxings_incomplete_items_quantity":
                                // var mItems = [].concat.apply([], Object.values(obj ? obj : {}).map(o => Object.values(o)));
                                // var fItems = mItems.filter(o => o.quantity != 0);
                                // var total_orders = [...new Set(fItems.map(o => o.item.so_order_id))].length
                                // var total_items = fItems.length;
                                // var total_items_orig = mItems.length;
                                // var total_quantity = mItems.map(o => o.quantity ? o.quantity : 0).reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                                // var total_quantity_orig = mItems.map(o => o.item.quantity ? o.item.quantity : 0).reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                                value = [].concat.apply([], Object.values(obj ? obj : {}).map(o => Object.values(o))).map(o => o.quantity ? o.quantity : 0).reduce((prev, next) => (parseInt(prev) + parseInt(next)));
                                break;
                            case "putaways_incomplete_items_count":
                                var list = [].concat.apply([], [].concat.apply([], Object.values(obj ? obj : {}).map(o => o.items)).filter(o => o != null).map(o => Object.values(o))).map(o => o.item.product_id);
                                console.log("listrr", list);
                                value = [...new Set(list)].length;
                                break;
                            case "putaways_incomplete_items_quantity":
                                var list = [].concat.apply([], [].concat.apply([], Object.values(obj ? obj : {}).map(o => o.items)).filter(o => o != null).map(o => Object.values(o))).map(o => o.quantity);
                                console.log("listrr", list);
                                value = list.length == 0 ? 0 : list.reduce((prev, next) => (parseInt(prev) + parseInt(next)))
                                break;
                            case "boxing_manifest_count":
                                // var value1 = obj.map(o => Object.values(o.items));
                                // value = [].concat.apply([], value1).length;
                                value = obj.filter(o => o.manifest && o.manifest != null).length;
                                break;
                            case "boxing_dispatched_count":
                                // var value1 = obj.map(o => Object.values(o.items));
                                // value = [].concat.apply([], value1).length;
                                value = obj.filter(o => o.dispatch_data && o.dispatch_data.is_dispatched).length;
                                break;
                            case "boxing_items_count":
                                // var value1 = obj.map(o => Object.values(o.items));
                                // value = [].concat.apply([], value1).length;
                                var list = [].concat.apply([], obj.map(o => Object.values(o.items)))
                                var value1 = [...new Map(list.map(item => [item["item"]["sku_code"], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o["item"]["sku_code"] == v["item"]["sku_code"]).length}))
                                // value = this.getSKUDataByGrouping([].concat.apply([], obj.map(o => Object.values(o.items))), "sku_code", null, null).length;
                                value = value1.length;
                                break;
                            case "boxing_items_quantity":
                                // var value1 = obj.map(o => Object.values(o.items));
                                // value = [].concat.apply([], value1).length;
                                var list = [].concat.apply([], obj.map(o => Object.values(o.items).map(o1 => o1.quantity)));
                                // var value1 = [...new Map(list.map(item => [item["item"]["sku_code"], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o["item"]["sku_code"] == v["item"]["sku_code"]).length}))
                                // value = this.getSKUDataByGrouping([].concat.apply([], obj.map(o => Object.values(o.items))), "sku_code", null, null).length;
                                // value = value1.length;
                                value = list.length == 0 ? 0 : list.reduce((prev, next) => (parseInt(prev) + parseInt(next)))
                                break;
                            case "boxing_items":
                                console.log("obb", data, obj)
                                var value1 = {};
                                for(var i = 0; i < obj.length; i++){
                                    var box = obj[i];
                                    box["boxing_batch_id"] = extra_value;                                 
                                    if(value1[box.so_order_id]){
                                        value1[box.so_order_id]["boxes"].push(box);
                                    }else{
                                        value1[box.so_order_id] = {boxes : [box], channel : this.b2bAgents().find(o => o.customer_id == extra_value1.find(o1 => o1.sales_order.so_order_id == box.so_order_id).created_by) ? "B2B" : "B2C", so_order_id : box.so_order_id, boxing_batch_id : extra_value};
                                    }
                                }
                                // value = this.getSKUDataByGrouping(obj, "so_order_id", null, null);
                                value = value1;
                                break;
                            case "boxing_items_es":
                                console.log("obb", data, obj)
                                var value1 = {};
                                value1 = {boxes : [], boxing_batch_id : extra_value};
                                for(var i = 0; i < obj.length; i++){
                                    var box = obj[i];
                                    box["boxing_batch_id"] = extra_value;                                 
                                    value1["boxes"].push(box);
                                }
                                // value = this.getSKUDataByGrouping(obj, "so_order_id", null, null);
                                value = value1;
                                break;
                            case "vpr_status":
                                console.log("obb", data, obj)
                                var value1 = {};
                                for(var i = 0; i < obj.length; i++){
                                    var box = obj[i];
                                    box["boxing_batch_id"] = extra_value;                                 
                                    if(value1[box.so_order_id]){
                                        value1[box.so_order_id]["boxes"].push(box);
                                    }else{
                                        value1[box.so_order_id] = {boxes : [box], so_order_id : box.so_order_id, boxing_batch_id : extra_value};
                                    }
                                }
                                // value = this.getSKUDataByGrouping(obj, "so_order_id", null, null);
                                value = value1;
                                break;
                            // case "rfq_vendor_response_details":
                            //     console.log("obb", data, obj)
                            //     var value1 = {};
                            //     for(var i = 0; i < obj.length; i++){
                            //         var box = obj[i];
                            //         box["boxing_batch_id"] = extra_value;                                 
                            //         if(value1[box.so_order_id]){
                            //             value1[box.so_order_id]["boxes"].push(box);
                            //         }else{
                            //             value1[box.so_order_id] = {boxes : [box], so_order_id : box.so_order_id, boxing_batch_id : extra_value};
                            //         }
                            //     }
                            //     value = value1;
                            //     break;
                            default:
                                value = obj;
                                break;
                        }
                        break;
                    default:
                        value = obj;
                        break;
                }
            }else{
                if(data.type == 'boolean'){
                    value = "False";
                }else if(data.type == "view_btn"){
                    value = "View";
                }
                else if(data.type == "custom"){
                    switch(data.type1){
                        case "sku_title":
                            console.log("sku_titleX", extra_value);
                            if (obj)
                                value = obj;
                            else
                                value = this.getDynamicSKUTitle("seat_cover", extra_value);
                            break;
                    }
                }
            }
        }else{
            value = obj;
        }
        return value;
    }

    
    getItemWarehousesByGrouping(list, unique_key, filter_key=null, filter_key_value=null){
        var that = this;
        if(filter_key){
            return [...new Set(list.filter(o => o[filter_key] == filter_key_value).map((v) => ((that.warehouses().find(o => o.id == v["warehouse_id"]) ? that.warehouses().find(o => o.id == v["warehouse_id"]).code : "NA") + " - " + (list.filter(o => o[filter_key] == filter_key_value && v["warehouse_id"] == o["warehouse_id"]).length).toString())))].join(", ");
            return [...new Map(list.filter(o => o[filter_key] == filter_key_value).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o.product_id == v.product_id).length}));
        }else{
            return [...new Set(list.map((v) => ((that.warehouses().find(o => o.id == v["warehouse_id"]) ? that.warehouses().find(o => o.id == v["warehouse_id"]).code : "NA") + " - " + (list.filter(o => v["warehouse_id"] == o["warehouse_id"]).length).toString())))].join(", ");
            return [...new Map(list.map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o.product_id == v.product_id).length}));
        }
    }

    getItemStatusesByGrouping(list, unique_key, filter_key=null, filter_key_value=null){
        var that = this;
        if(filter_key){
            return [...new Set(list.filter(o => o[filter_key] == filter_key_value).map((v) => (that.getItemStatusTitle(v["status1"] ? v["status1"] : v["status"]) + " - " + (v["status1"] ? list.filter(o => o[filter_key] == filter_key_value && v["status1"] == o["status1"]).length : list.filter(o => o[filter_key] == filter_key_value && v["status"] == o["status"] && !o["status1"]).length).toString())))].join(", ");
            return [...new Map(list.filter(o => o[filter_key] == filter_key_value).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o.product_id == v.product_id).length}));
        }else{
            return [...new Set(list.map((v) => (that.getItemStatusTitle(v["status1"] ? v["status1"] : v["status"]) + " - " + (v["status1"] ? list.filter(o => v["status1"] == o["status1"]).length : list.filter(o => v["status"] == o["status"] && !o["status1"]).length).toString())))].join(", ");
            return [...new Map(list.map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o.product_id == v.product_id).length}));
        }
    }

    getSKUDataByGrouping(list, unique_key, filter_key=null, filter_key_value=null, equals=true){
        if(filter_key){
            if (equals){
                return [...new Map(list.filter(o => o[filter_key] == filter_key_value).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key] && o[filter_key] == filter_key_value).length}));
            }else{
                return [...new Map(list.filter(o => o[filter_key] != filter_key_value).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key] && o[filter_key] != filter_key_value).length}));
            }
        }else{
            return [...new Map(list.map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key]).length}));
        }
    }

    getSKUDataByGroupingList(list, unique_key, filter_key=null, filter_key_values=[], equals=true){
        if(filter_key){
            if (equals){
                return [...new Map(list.filter(o => filter_key_values.indexOf(o[filter_key]) != -1).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key] && filter_key_values.indexOf(o[filter_key]) != -1).length}));
            }else{
                return [...new Map(list.filter(o => filter_key_values.indexOf(o[filter_key]) == -1).map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key] && filter_key_values.indexOf(o[filter_key]) == -1).length}));
            }
        }else{
            return [...new Map(list.map(item => [item[unique_key], item])).values()].map(({_id, ...v}) => ({...v, quantity: list.filter(o => o[unique_key] == v[unique_key]).length}));
        }
    }

    greeting(){
        var today = new Date()
        var curHr = today.getHours()
        // console.log("curHr", curHr);

        if (curHr < 12 && curHr >= 2) {
         return 'Good morning';
        } else if (curHr < 18 && curHr >= 12) {
            return 'Good afternoon';
        } else {
            return 'Good evening';
        }
    }

}






const rowData = {
    "oem_active" : [
        // {"key" : "name", "header" : "Name", style : {width : "30%"}, "type" : "custom", "type1" : "name_with_initials_image"},
        {"key" : "name", "header" : "Name", style : {width : "30%"}, "type" : "custom", "type1" : "name_with_initials_image"},
        {"key" : "phone_number", "header" : "Contact Number", style : {width : "20%"}},
        {"key" : "email", "header" : "Email Address", style : {width : "30%"}},
        {"key" : "location", "header" : "Location", style : {width : "20%"}},
        // {"key" : "view_btn", "header" : "", style : {width : "15%", color : "var(--colorOne500)", fontWeight : "600", cursor : "pointer"}, type : "view_btn"},
    ],
    "customers" : [
        // {"key" : "name", "header" : "Name", style : {width : "30%"}, "type" : "custom", "type1" : "name_with_initials_image"},
        {"key" : "name", "header" : "Name", style : {width : "30%"}, "type" : "custom", "type1" : "name_and_vehicle_with_initials_image", "extra_key" : "vehicles"},
        {"key" : "phone_number", "header" : "Contact Number", style : {width : "20%"}},
        {"key" : "email", "header" : "Email Address", style : {width : "30%"}},
        {"key" : "company", "header" : "Company", style : {width : "20%"}},
        // {"key" : "view_btn", "header" : "", style : {width : "15%", color : "var(--colorOne500)", fontWeight : "600", cursor : "pointer"}, type : "view_btn"},
    ],
    "services_active" : [
        {"key" : "user.name", "header" : "Customer", style : {width : "30%"}, "type" : "custom", "type1" : "name_and_vehicle_with_initials_image", "extra_key" : "vehicle"},
        {"key" : "invoice_details.service_name", "header" : "Service Type", style : {width : "30%"}},
        {"key" : "date", "header" : "Service Date", style : {width : "15%"}},
        {"key" : "status", "header" : "Status", style : {width : "15%"}, "type" : "custom", "type1" : "service_status"},
        {"key" : "service_buddy.name", "header" : "Service Buddy", style : {width : "30%"}, "type" : "custom", "type1" : "name_with_initials_image"},
    ],
    "services_completed" : [
        {"key" : "user.name", "header" : "Customer", style : {width : "30%"}, "type" : "custom", "type1" : "name_and_vehicle_with_initials_image", "extra_key" : "vehicle"},
        {"key" : "invoice_details.service_name", "header" : "Service Type", style : {width : "30%"}},
        {"key" : "date", "header" : "Service Date", style : {width : "15%"}},
        {"key" : "status", "header" : "Status", style : {width : "15%"}, "type" : "custom", "type1" : "service_status"},
        {"key" : "service_buddy.name", "header" : "Service Buddy", style : {width : "30%"}, "type" : "custom", "type1" : "name_with_initials_image"},
    ],
    "services_pending" : [
        {"key" : "user.name", "header" : "Customer", style : {width : "30%"}, "type" : "custom", "type1" : "name_and_vehicle_with_initials_image", "extra_key" : "vehicle"},
        {"key" : "invoice_details.service_name", "header" : "Service Type", style : {width : "30%"}},
        {"key" : "date", "header" : "Service Date", style : {width : "15%"}},
        {"key" : "status", "header" : "Status", style : {width : "15%"}, "type" : "custom", "type1" : "service_status"},
        {"key" : "service_buddy.name", "header" : "Service Buddy", style : {width : "30%"}, "type" : "custom", "type1" : "name_with_initials_image"},
    ],
    "payments_all" : [
        {"key" : "user.name", "header" : "Customer", style : {width : "30%"}, "type" : "custom", "type1" : "name_and_vehicle_with_initials_image", "extra_key" : "vehicle"},
        {"key" : "invoice_details.total_amount", "header" : "Order Amount", style : {width : "20%"}},
        {"key" : "date", "header" : "Service Date", style : {width : "20%"}},
        {"key" : "status", "header" : "Status", style : {width : "20%"}, "type" : "custom", "type1" : "service_status"},
        {"key" : "service_buddy.name", "header" : "Service Buddy", style : {width : "30%"}, "type" : "custom", "type1" : "name_with_initials_image"},
    ],
    "payments_pending" : [
        {"key" : "user.name", "header" : "Customer", style : {width : "30%"}, "type" : "custom", "type1" : "name_and_vehicle_with_initials_image", "extra_key" : "vehicle"},
        {"key" : "invoice_details.total_amount", "header" : "Order Amount", style : {width : "20%"}},
        {"key" : "date", "header" : "Service Date", style : {width : "20%"}},
        {"key" : "status", "header" : "Status", style : {width : "20%"}, "type" : "custom", "type1" : "service_status"},
        {"key" : "service_buddy.name", "header" : "Service Buddy", style : {width : "30%"}, "type" : "custom", "type1" : "name_with_initials_image"},
    ],
    "invoices" : [
        {"key" : "order_id", "header" : "Invoice", style : {width : "10%"}},
        {"key" : "date", "header" : "Date", style : {width : "15%"}},
        {"key" : "payment_details.payment_status", "header" : "Status", style : {width : "15%"}, "type" : "custom", "type1" : "service_status"},
        {"key" : "user.name", "header" : "Customer", style : {width : "30%"}, "type" : "custom", "type1" : "name_and_email_with_initials_image", "extra_key" : "user.email"},
        {"key" : "invoice_details.service_name", "header" : "Service Type", style : {width : "30%"}},
    ],
    "sb_active" : [
        {"key" : "sb_users.name", "header" : "Name", style : {width : "30%"}, "type" : "custom", "type1" : "name_and_email_with_initials_image", "extra_key" : "sb_users.nickname"},
        {"key" : "sb_users.status", "header" : "Status", style : {width : "15%"}, "type" : "custom", "type1" : "sb_status"},
        {"key" : "roles", "header" : "Role", style : {width : "20%"}, "type" : "array_string"},
        {"key" : "sb_users.email", "header" : "Email", style : {width : "20%"}},
        {"key" : "sb_users.employer.company_name", "header" : "Company", style : {width : "20%"}, "type" : "custom", "type1" : "company_name"},
    ],
    "sb_available" : [
        {"key" : "sb_users.name", "header" : "Name", style : {width : "30%"}, "type" : "custom", "type1" : "name_and_email_with_initials_image", "extra_key" : "sb_users.nickname"},
        {"key" : "sb_users.status", "header" : "Status", style : {width : "15%"}, "type" : "custom", "type1" : "sb_status"},
        {"key" : "roles", "header" : "Role", style : {width : "20%"}, "type" : "array_string"},
        {"key" : "sb_users.email", "header" : "Email", style : {width : "20%"}},
        {"key" : "sb_users.employer.company_name", "header" : "Company", style : {width : "20%"}, "type" : "custom", "type1" : "company_name"},
    ],
    "sb_offline" : [
        {"key" : "sb_users.name", "header" : "Name", style : {width : "30%"}, "type" : "custom", "type1" : "name_and_email_with_initials_image", "extra_key" : "sb_users.nickname"},
        {"key" : "sb_users.status", "header" : "Status", style : {width : "15%"}, "type" : "custom", "type1" : "sb_status"},
        {"key" : "roles", "header" : "Role", style : {width : "20%"}, "type" : "array_string"},
        {"key" : "sb_users.email", "header" : "Email", style : {width : "20%"}},
        {"key" : "sb_users.employer.company_name", "header" : "Company", style : {width : "20%"}, "type" : "custom", "type1" : "company_name"},
    ],
    "actionables" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}},
        {"key" : "type", "header" : "Type", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "ref_id", "header" : "Ref ID", style : {width : "90px"}},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "orders_list", "header" : "Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
    ],
    "periodic_tasks" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}},
        {"key" : "type", "header" : "Type", style : {width : "200px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "type", "header" : "Type", style : {width : "180px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "error_data", "header" : "Error Details", style : {width : "90px"}, "type" : "boolean"},
        // {"key" : "ref_id", "header" : "Ref ID", style : {width : "120px"}},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "error_data", "header" : "Error Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "periodic_task_error_details"},
        {"key" : "error_data.type", "header" : "Error Type", style : {width : "120px"}},
        {"key" : "error_data.text", "header" : "Error Desc", style : {width : "120px"}},
        // {"key" : "orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "orders_list", "header" : "Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "updated_at", "header" : "Updated at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
    ],
    "skus" : [
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "title", "header" : "Title", style : {width : "250px"}},
        {"key" : "sku_category", "header" : "Category", style : {width : "120px"}},
        {"key" : "sku_brand", "header" : "Brand", style : {width : "150px"}},
        {"key" : "inventory", "header" : "Stock", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "qty"},
        {"key" : "sku_code", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        {"key" : "mrp", "header" : "MRP", style : {width : "70px"}},
        {"key" : "price", "header" : "Price", style : {width : "70px"}},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "panel" : "right", "type" : "custom", "type1" : "alt_parts_product_ids", "extra_key" : "product_id"},
        {"key" : "image_url", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image"},
    ],
    "skus_dynamic" : [
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "title", "header" : "Title", style : {width : "250px"}},
        {"key" : "sku_category", "header" : "Category", style : {width : "120px"}},
        {"key" : "sku_brand", "header" : "Brand", style : {width : "150px"}},
        {"key" : "inventory", "header" : "Stock", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "qty"},
        {"key" : "sku_code", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        {"key" : "mrp", "header" : "MRP", style : {width : "70px"}},
        {"key" : "price", "header" : "Price", style : {width : "70px"}},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "panel" : "right", "type" : "custom", "type1" : "alt_parts_product_ids", "extra_key" : "product_id"},
        {"key" : "image_url", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image"},
    ],
    "warehouses" : [
        {"key" : "code", "header" : "Code", style : {width : "80px"}},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "address.city", "header" : "City", style : {width : "90px"}},
        {"key" : "address.state", "header" : "State", style : {width : "90px"}},
        {"key" : "address.pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "finance.bank_name", "header" : "Bank", style : {width : "150px"}},
        {"key" : "finance.bank_account", "header" : "Account No.", style : {width : "100px"}},
        {"key" : "finance.gstin", "header" : "GSTIN", style : {width : "150px"}},
        {"key" : "address.full_address", "header" : "Address", style : {width : "400px"}},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
    ],
    "inventory" : [
        {"key" : "part.product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "part.sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "qty", "header" : "Quantity", style : {width : "70px"}},
        {"key" : "frozen_stock", "header" : "Frozen Qty", style : {width : "70px"}},
        {"key" : "location", "header" : "Location", style : {width : "90px"}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "part.title", "header" : "Title", style : {width : "250px"}},
        {"key" : "part.sku_category", "header" : "Category", style : {width : "120px"}},
        {"key" : "part.sku_brand", "header" : "Brand", style : {width : "150px"}},
        {"key" : "sku_code", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        {"key" : "part.mrp", "header" : "MRP", style : {width : "70px"}},
        {"key" : "part.price", "header" : "Price", style : {width : "70px"}},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "alt_parts", "header" : "Alternative Parts", style : {width : "70px"}, "panel" : "right", "type" : "custom", "type1" : "alt_parts_product_ids", "extra_key" : "product_id"},
        {"key" : "part.image_url", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image"},
    ],
    "inventory_history" : [
        {"key" : "part.product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "part.sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "txn_type", "header" : "TXN Type", style : {width : "70px"}},
        {"key" : "qty_original", "header" : "Original Qty", style : {width : "70px"}},
        {"key" : "qty_final", "header" : "Final Qty", style : {width : "70px"}},
        {"key" : "qty_change", "header" : "Qty Change", style : {width : "70px"}},
        {"key" : "location", "header" : "Location", style : {width : "90px"}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "part.title", "header" : "Title", style : {width : "250px"}},
        {"key" : "part.sku_category", "header" : "Category", style : {width : "120px"}},
        {"key" : "part.sku_brand", "header" : "Brand", style : {width : "150px"}},
        {"key" : "part.mrp", "header" : "MRP", style : {width : "70px"}},
        {"key" : "part.price", "header" : "Price", style : {width : "70px"}},
        {"key" : "part.image_url", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
    ],
    "wh_transfer_orders" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "to_id", "header" : "TO ID", style : {width : "150px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "warehouse_from", "header" : "WH From", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "warehouse_to", "header" : "WH To", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},        
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "wh_layout" : [
        {"key" : "code", "header" : "Warehouse", style : {width : "80px"}},
        {"key" : "location", "header" : "Location", style : {width : "90px"}},
        {"key" : "location_type", "header" : "Location type", style : {width : "90px"}},
        {"key" : "zone", "header" : "Zone", style : {width : "90px"}},
        // {"key" : "coordinates", "header" : "Coordinates", style : {width : "100px"}},
        {"key" : "is_available", "header" : "Availability", style : {width : "70px"}, "type" : "boolean"},
        {"key" : "location", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        // {"key" : "ffmt_constant", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image", "constant_value" : "", "variable_values" : []},
    ],
    "orders" : [
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "order_status", "header" : "Status", style : {width : "90px"}},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "sales_orders" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "sales_order.invoice_number", "header" : "Invoice No.", style : {width : "120px"}},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "back_orders" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "filter_key" : "status", "filter_key_value" : "back", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "forward_orders" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "forward"},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "filter_key" : "status", "filter_key_value" : "forward", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "orders_eg" : [
        {"key" : "order_id", "header" : "Order ID", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "status", "header" : "Status", style : {width : "120px"}},
        {"key" : "client_customer_details.name", "header" : "Customer", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "client_customer_details.shipping_address.city", "header" : "City", style : {width : "90px"}},
        {"key" : "line_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "line_items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        // {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        // {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        
        // {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        // {"key" : "sales_invoices", "header" : "Sales Invoices", style : {width : "120px"}, "type" : "custom", "type1" : "sales_invoices_numbers_es"},
        
        {"key" : "client_customer_details.mobile", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},




        {"key" : "spares_order.sales_order.so_order_id", "header" : "Spares Sales Order ID", style : {width : "120px"}},
        {"key" : "spares_order.stockone_order_id", "header" : "Spares Order ID", style : {width : "100px"}},
        {"key" : "spares_order.order_lines", "header" : "Spares Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "spares_order.order_lines", "header" : "Spares Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        // {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "spares_order.sales_order.items", "header" : "Spares BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "spares_order.sales_order.items", "header" : "Spares FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "spares_order.sales_order.items", "header" : "Spares DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "spares_order.sales_order.items", "header" : "Spares Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        
        {"key" : "spares_order.sales_order.invoice_number", "header" : "Spares Invoice No.", style : {width : "120px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "spares_order.sales_order.items", "header" : "Spares Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        // {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},




        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "orders_distributor" : [
        {"key" : "order_id", "header" : "Order ID", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "status", "header" : "Status", style : {width : "120px"}},
        {"key" : "customer_details.name", "header" : "Customer", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        // {"key" : "client_customer_details.shipping_address.city", "header" : "City", style : {width : "90px"}},
        {"key" : "line_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "line_items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},

        // {"key" : "items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        // {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        
        
        
        
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        // {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        // {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        
        // {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        // {"key" : "sales_invoices", "header" : "Sales Invoices", style : {width : "120px"}, "type" : "custom", "type1" : "sales_invoices_numbers_es"},
        
        {"key" : "customer_details.mobile", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},

        // {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},




        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "orders_acc" : [
        {"key" : "order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "order_status", "header" : "Status", style : {width : "90px"}},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.mobile", "header" : "Phone", style : {width : "90px"}},
        {"key" : "user_details.user_id", "header" : "User ID", style : {width : "100px"}},
        {"key" : "dsp_tracking_details", "header" : "Tracking Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "acc_tracking_details"},
        {"key" : "order_lines", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "sales_orders_acc" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "user_details.name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "delivery_address.city", "header" : "City", style : {width : "90px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        // {"key" : "order_status", "header" : "Status", style : {width : "90px"}},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "sales_order.invoice_number", "header" : "Invoice No.", style : {width : "120px"}},
        {"key" : "user_details.mobile", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "back_orders_acc" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        // {"key" : "order_status", "header" : "Status", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "user_details.name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.mobile", "header" : "Phone", style : {width : "90px"}},
        {"key" : "user_details.user_id", "header" : "User ID", style : {width : "100px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "filter_key" : "status", "filter_key_value" : "back", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "forward_orders_acc" : [
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        // {"key" : "order_status", "header" : "Status", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "user_details.name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.mobile", "header" : "Phone", style : {width : "90px"}},
        {"key" : "user_details.user_id", "header" : "User ID", style : {width : "100px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "filter_key" : "status", "filter_key_value" : "forward", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],

    "distributors" : [
        {"key" : "code", "header" : "Code", style : {width : "90px"}},
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "contact_name", "header" : "Contact Name", style : {width : "180px"}},
        // {"key" : "brands", "header" : "Brands", style : {width : "150px"}, "type" : "array_string"},
        
        // {"key" : "gst_structure", "header" : "GST Structure", style : {width : "70px"}},
        {"key" : "type", "header" : "Type", style : {width : "90px"}},
        {"key" : "mobile", "header" : "Mobile", style : {width : "90px"}},
        {"key" : "gst_no", "header" : "GST Number", style : {width : "150px"}},
        // {"key" : "franchise", "header" : "Franchise", style : {width : "50px"}},
        {"key" : "shipping_city", "header" : "Shp City", style : {width : "90px"}},
        {"key" : "shipping_state", "header" : "Shp State", style : {width : "90px"}},
        {"key" : "shipping_pincode", "header" : "Shp PIN", style : {width : "70px"}},
        {"key" : "billing_city", "header" : "Blg City", style : {width : "90px"}},
        {"key" : "billing_state", "header" : "Blg State", style : {width : "90px"}},
        {"key" : "billing_pincode", "header" : "Blg PIN", style : {width : "70px"}},

        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "vendors" : [
        {"key" : "code", "header" : "Code", style : {width : "90px"}},
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "150px"}, "type" : "array_string"},
        {"key" : "city", "header" : "City", style : {width : "90px"}},
        {"key" : "state", "header" : "State", style : {width : "90px"}},
        {"key" : "pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "dropship_details", "header" : "Dropshipping Status", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_vendor_status"},
        {"key" : "dropship_details", "header" : "Order Bounce Time", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_bounce_time"},
        {"key" : "dropship_details", "header" : "Daily Order Limit", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_daily_order_limit"},
        {"key" : "dropship_details", "header" : "Payment Cycle", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_payment_cycle"},
        {"key" : "gst_structure", "header" : "GST Structure", style : {width : "70px"}},
        {"key" : "vendor_type", "header" : "Vendor Type", style : {width : "90px"}},
        {"key" : "mobile", "header" : "Mobile", style : {width : "90px"}},
        {"key" : "razorpayx_fund_id", "header" : "RazorPayX Fund ID", style : {width : "120px"}},
        {"key" : "gst_no", "header" : "GST Number", style : {width : "150px"}},
        {"key" : "gst_vendor_type", "header" : "GST Vendor Type", style : {width : "90px"}},
        {"key" : "franchise", "header" : "Franchise", style : {width : "50px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "vendors2" : [
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "owner_name", "header" : "Owner Name", style : {width : "120px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "150px"}, "type" : "array_string"},
        {"key" : "discount", "header" : "Discount", style : {width : "70px"}},
        {"key" : "address", "header" : "Address", style : {width : "180px"}},
        {"key" : "gstin", "header" : "GST Number", style : {width : "150px"}},
        {"key" : "aadhar", "header" : "Aadhar No", style : {width : "120px"}},
        {"key" : "city", "header" : "City", style : {width : "90px"}},
        {"key" : "pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "coordinates", "header" : "Coordinates", style : {width : "90px"}, "type" : "array_string"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "acc_vendors" : [
        {"key" : "code", "header" : "Code", style : {width : "90px"}},
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "150px"}, "type" : "array_string"},
        {"key" : "products", "header" : "Products", style : {width : "150px"}, "type" : "array_string"},
        {"key" : "dropship_details", "header" : "Dropshipping Status", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_vendor_status"},
        {"key" : "dropship_details", "header" : "Order Bounce Time", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_bounce_time"},
        {"key" : "dropship_details", "header" : "Daily Order Limit", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_daily_order_limit"},
        {"key" : "dropship_details", "header" : "Payment Cycle", style : {width : "80px"}, "type" : "custom", "type1" : "dropship_payment_cycle"},
        {"key" : "razorpayx_fund_id", "header" : "RazorPayX Fund ID", style : {width : "120px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    //   "bids" : [
    //     {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
    //     // {"key" : "is_processed", "header" : "Processed", style : {width : "80px"}, "type" : "boolean"},
    //     {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "items", "header" : "Qty", style : {width : "80px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
    //     {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
    //     {"key" : "vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "vendors", "header" : "Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
    //     {"key" : "accept_responses", "header" : "Accept Responses", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "status", "header" : "Status", style : {width : "80px"}, "type" : "boolean"},
    //     {"key" : "delivery_address.address_1", "header" : "Delivery Address", style : {width : "350px"}},
    //     {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
    //     {"key" : "type", "header" : "Type", style : {width : "80px"}},
    //     {"key" : "accept_responses", "header" : "Accept Responses", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vom_accept_responses"},
    //     {"key" : "status", "header" : "Status", style : {width : "90px"}, "panel" : "right", "type" : "custom", "type1" : "vom_status"},
    //     {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
    //     {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
    //   ],
    //   "putaway" : [
    //     {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
    //     {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
    //     {"key" : "grn_id", "header" : "GRN ID", style : {width : "250px"}},
    //     {"key" : "po_id", "header" : "PO ID", style : {width : "250px"}},
    //     {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
    //     {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "120px"}},
    //     {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
    //     {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
    //     {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
    //     // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
    //     {"key" : "po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
    //     {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
    //     {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
    //     {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
    //     {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        
    //     {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
    //     {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},        
        
    //     // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
    //     {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
    //     {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
    //   ],
      "generate_picklist" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_bulk_sales_order_transfer" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "sales_order.invoices.invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        // {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "box_invoices" : [
        // {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.invoices.invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "sales_order.invoices.type", "header" : "Invoice Type", style : {width : "80px"}},
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_sales_return" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "sales_order.invoices.invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "sales_order.invoices.type", "header" : "Invoice Type", style : {width : "80px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_sales_return_tarone" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "sales_order.invoices.invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "sales_order.invoices.type", "header" : "Invoice Type", style : {width : "80px"}},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "sales_order.items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        {"key" : "order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "sales_returns" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
        {"key" : "items_rejected", "header" : "Rej. Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items_rejected", "header" : "Rej. Qty", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "items_rejected", "header" : "Rej. Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "credit_note_number", "header" : "Credit Note No.", style : {width : "120px"}},
        {"key" : "invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "order.sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        // {"key" : "order", "header" : "Order", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "order"},
        {"key" : "order.created_by", "header" : "Order Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "order.total", "header" : "Order Total", style : {width : "70px"}},
        {"key" : "order.order_lines", "header" : "Order Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "order.order_lines", "header" : "Order Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "order.user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "order.user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        {"key" : "order.created_by", "header" : "User ID", style : {width : "100px"}},
        //IMP
        {"key" : "order.sales_order.created_at", "header" : "Order Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},

        {"key" : "order.stockone_order_id", "header" : "Order ID", style : {width : "100px"}, "panel" : "right"},
        {"key" : "order.sales_order.status", "header" : "SO Status", style : {width : "180px"}, "panel" : "right", "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "order.sales_order.items", "header" : "BO Status", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "order.sales_order.items", "header" : "FO Status", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "order.sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "panel" : "right", "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "order.payment_mode", "header" : "Payment Mode", style : {width : "180px"}, "panel" : "right"},
        {"key" : "order.sales_order.invoice_number", "header" : "Invoice No.", style : {width : "180px"}, "panel" : "right"},
        {"key" : "order.sales_order.items", "header" : "Order Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
      ],
      "purchase_returns" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus"},
        
        {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "po.items", "header" : "PO Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "po.items", "header" : "PO Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "po.items", "header" : "PO Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "order"},
        {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},

        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "transfer_for_dpsp" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "back_order.order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        
        // {"key" : "back_order.order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_rfq" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "back_order.order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        
        // {"key" : "back_order.order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_rfq2" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "back_order.order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        
        // {"key" : "back_order.order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        
        {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_rfq_skus" : [
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "sku_brand", "header" : "Brand", style : {width : "150px"}},
    ],
    "generate_rfq_vendors" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "code", "header" : "Code", style : {width : "90px"}},
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "state", "header" : "State", style : {width : "90px"}},
        {"key" : "city", "header" : "City", style : {width : "90px"}},
        {"key" : "pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "180px"}, "type" : "array_string"},
      ],
      "generate_rfq2_vendors" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "owner_name", "header" : "Owner", style : {width : "120px"}},
        {"key" : "state", "header" : "State", style : {width : "90px"}},
        {"key" : "city", "header" : "City", style : {width : "90px"}},
        {"key" : "pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "180px"}, "type" : "array_string"},
      ],
      "generate_rfq_acc" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sales_order.so_order_id", "header" : "Sales Order ID", style : {width : "120px"}},
        {"key" : "order_id", "header" : "Order ID", style : {width : "100px"}},
        // {"key" : "order_status", "header" : "Status", style : {width : "90px"}},

        {"key" : "sales_order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "sales_order.items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        
        // {"key" : "back_order.order_lines", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique_with_filter", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "back_order.order_lines", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "sales_order.items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "sales_order.items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "user_details.name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "user_details.mobile", "header" : "Phone", style : {width : "90px"}},
        {"key" : "user_details.user_id", "header" : "User ID", style : {width : "100px"}},
        {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "generate_rfq_skus_acc" : [
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "sku_brand", "header" : "Brand", style : {width : "150px"}},
    ],
    "generate_rfq_vendors_acc" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "code", "header" : "Code", style : {width : "90px"}},
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        // {"key" : "state", "header" : "State", style : {width : "90px"}},
        // {"key" : "city", "header" : "City", style : {width : "90px"}},
        // {"key" : "pincode", "header" : "PIN Code", style : {width : "70px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "450px"}, "type" : "array_string"},
      ],
      "create_vom_skus" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}},
        {"key" : "product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "sku_brand", "header" : "Brand", style : {width : "150px"}},
        {"key" : "product_title", "header" : "Title", style : {width : "250px"}},
    ],
    "create_vom_vendors" : [
        {"key" : "select", "header" : "Select", style : {width : "50px"}, "type":"custom", "type1" : "checkbox", "headerType" : "checkbox"}, 
        {"key" : "name", "header" : "Name", style : {width : "180px"}},
        {"key" : "owner_name", "header" : "Owner Name", style : {width : "120px"}},
        {"key" : "brands", "header" : "Brands", style : {width : "180px"}, "type" : "array_string"},
      ],
    "rfqs" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "rfq_type", "header" : "Type", style : {width : "70px"}, "type" : "custom", "type1" : "rfq_type"},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "pos", "header" : "POs", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "orders_list", "header" : "Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        {"key" : "vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendors_list", "header" : "Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors_rfq", "extra_key" : "id"},
        // {"key" : "vendors2_list", "header" : "Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors_rfq2", "extra_key" : "id"},
        {"key" : "vendor_responses", "header" : "Responses", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendor_responses", "header" : "Response Details", style : {width : "80px"}, "panel" : "right", "type" : "custom", "type1" : "rfq_vendor_response_details"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus_list"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},

        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "rfqs_acc" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "pos", "header" : "POs", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "orders_list", "header" : "Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders_acc"},
        {"key" : "vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendors_list", "header" : "Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors_rfq_acc", "extra_key" : "id"},
        {"key" : "vendor_responses", "header" : "Responses", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendor_responses", "header" : "Response Details", style : {width : "80px"}, "panel" : "right", "type" : "custom", "type1" : "rfq_acc_vendor_response_details"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus_list"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "rfqs_acc_global" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "pos", "header" : "POs", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "vendor_details.name", "header" : "Vendor", style : {width : "180px"}},
        {"key" : "vendor_details", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor_rfq_acc_global", "extra_key" : "id"},
        // {"key" : "vendor_responses", "header" : "Responses", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "vendor_responses", "header" : "Response Details", style : {width : "80px"}, "panel" : "right", "type" : "custom", "type1" : "rfq_acc_vendor_response_details"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "sku_code"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus_list"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "sku_code"},
        
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
    "manufos_acc" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "mo_id", "header" : "MO ID", style : {width : "210px"}},
        // {"key" : "mo_type", "header" : "Type", style : {width : "70px"}, "type" : "custom", "type1" : "rfq_type"},
        {"key" : "mo_type", "header" : "Type", style : {width : "70px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "acc_vendors"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "acc_vendor"},
        
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "items", "header" : "Mfd. Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity_mfd"},
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus5"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        
        {"key" : "mo_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "mo_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "mo_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
    "purchos" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "po_type", "header" : "Type", style : {width : "70px"}, "type" : "custom", "type1" : "rfq_type"},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn.grn_id", "header" : "GRN ID", style : {width : "250px"}},
        {"key" : "grns", "header" : "GRNs", style : {width : "70px"}, "type" : "list_count"},
        
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "pickup_date", "header" : "PickUp Date", style : {width : "90px"}},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendor_id", "header" : "Vendor1", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "vendor_id", "header" : "Vendor1", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "vendor_id", "header" : "Vendor2", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors2"},
        // {"key" : "vendor_id", "header" : "Vendor2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor2"},
        
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "grns", "header" : "GRN Items", style : {width : "70px"}, "type" : "custom", "type1" : "po_grns_items"},
        {"key" : "grns", "header" : "GRN Quantity", style : {width : "70px"}, "type" : "custom", "type1" : "po_grns_quantity"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "rfq.orders", "header" : "RFQ Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "rfq.vendors", "header" : "RFQ Vendors", style : {width : "70px"}, "type" : "list_count"},        
        // {"key" : "rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        // {"key" : "rfq.vendors2_list", "header" : "RFQ Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "po_receivings", "header" : "Receivings", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "po_receivings", "header" : "Receivings", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "po_receivings"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
    "purchos_acc" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn.grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "grns", "header" : "GRNs", style : {width : "70px"}, "type" : "list_count"},
        
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "acc_vendors"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "acc_vendor"},
        
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},        
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "rfq.orders", "header" : "RFQ Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders_acc"},
        {"key" : "rfq.vendors", "header" : "RFQ Vendors", style : {width : "70px"}, "type" : "list_count"},        
        {"key" : "rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors_acc"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
    "purchos_acc_global" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn.grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "grns", "header" : "GRNs", style : {width : "70px"}, "type" : "list_count"},
        
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "acc_vendors"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "acc_vendor"},
        
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "sku_code"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},        
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        // {"key" : "rfq.orders", "header" : "RFQ Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders_acc"},
        // {"key" : "rfq.vendors", "header" : "RFQ Vendors", style : {width : "70px"}, "type" : "list_count"},        
        // {"key" : "rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors_acc"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "po_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
    "grns" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "grn_id", "header" : "GRN ID", style : {width : "150px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "putaway_batches", "header" : "PA Batches", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "putaway_batches", "header" : "PA Batches", style : {width : "120px"}, "type" : "array_object_string", "key_display" : "id"},
        {"key" : "po_id", "header" : "PO ID", style : {width : "90px"}},
        {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "po.po_type", "header" : "PO Type", style : {width : "70px"}},
        {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "started_at", "header" : "Started at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors2"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor2"},
        // {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        // {"key" : "po.rfq.vendors2_list", "header" : "RFQ Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
        
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "items_rejected", "header" : "Rej Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items_rejected", "header" : "Rej Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items_rejected", "header" : "Rejected Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "started_by", "header" : "Started By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "operators", "header" : "Operators", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "operators", "header" : "Operators", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string2"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "grns_incomplete" : [
        // {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "po_id", "header" : "PO ID", style : {width : "90px"}},
        {"key" : "po_id_full", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "po.po_type", "header" : "PO Type", style : {width : "180px"}},
        // {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "180px"}, roles : ["admin"]},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "created.created_warehouse", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "last_updated.updated_at", "header" : "Last Updated At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "last_updated.updated_by", "header" : "Last Updated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "users", "header" : "Users", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "users", "header" : "Users", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string1"},
        {"key" : "filled_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "filled_items", "header" : "Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "filled_items", "header" : "Rej Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity_rejected"},
        {"key" : "filled_items", "header" : "Orig Qty", style : {width : "70px"}, "type" : "custom", "type1" : "item_quantity_inner"},
        {"key" : "created.created_at", "header" : "Created At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "created.created_by", "header" : "Created By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors2"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor2"},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        // {"key" : "po.rfq.vendors2_list", "header" : "RFQ Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items_rejected", "header" : "Rej Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rej Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rejected Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "picklists" : [
        {"key" : "picklist_id", "header" : "PickList ID", style : {width : "150px"}},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "boxing_batches_list", "header" : "Status", style : {width : "90px"}, "type" : "custom", "type1" : "picklist_status"},
        {"key" : "orders_list", "header" : "Order IDs", style : {width : "180px"}, "type" : "custom", "type1" : "order_ids_array_string"},
        {"key" : "orders_list", "header" : "Order Created", style : {width : "180px"}, "type" : "custom", "type1" : "order_created_at_array_string"},
        {"key" : "orders_list", "header" : "Customers", style : {width : "180px"}, "type" : "custom", "type1" : "order_customer_firstnames_array_string"},
        {"key" : "boxing_batches_list", "header" : "Boxing Batches", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "list", "header" : "Items", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "list", "header" : "Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        
        {"key" : "orders_list", "header" : "Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        
        {"key" : "list", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus2"},
        // {"key" : "dsps", "header" : "DSPs", style : {width : "80px"}, "type" : "boolean"},
        // {"key" : "dsps", "header" : "DSPs", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "dsps"},
        {"key" : "inventory_snapshot", "header" : "Inventory Snapshot", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "inventory_items"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "putaway_batches" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "items", "header" : "Locations", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "location"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},        
        // {"key" : "items", "header" : "Locations", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "items", "header" : "Location & Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "locations_skus"},
        {"key" : "sr_id", "header" : "SR ID", style : {width : "90px"}},
        {"key" : "grn_id", "header" : "GRN ID", style : {width : "90px"}},
        {"key" : "grn.po_id", "header" : "PO ID", style : {width : "90px"}},
        {"key" : "grn.po.po_id", "header" : "PO ID", style : {width : "180px"}},
        {"key" : "grn.po.rfq.id", "header" : "RFQ ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "started_at", "header" : "Started at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "order"},
        {"key" : "grn.po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "grn.po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "grn.po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},        
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "started_by", "header" : "Started By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "operators", "header" : "Operators", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "operators", "header" : "Operators", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string2"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "putaways_incomplete" : [
        // {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "grn_id", "header" : "GRN ID", style : {width : "90px"}},
        {"key" : "grn_id_full", "header" : "GRN ID", style : {width : "150px"}},
        // {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "po.po_type", "header" : "PO Type", style : {width : "180px"}},
        // {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "180px"}, roles : ["admin"]},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "created.created_warehouse", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "last_updated.updated_at", "header" : "Last Updated At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "last_updated.updated_by", "header" : "Last Updated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "users", "header" : "Users", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "users", "header" : "Users", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string1"},
        {"key" : "filled_items", "header" : "Items", style : {width : "70px"}, "type" : "custom", "type1" : "putaways_incomplete_items_count"},
        {"key" : "filled_items", "header" : "Qty", style : {width : "70px"}, "type" : "custom", "type1" : "putaways_incomplete_items_quantity"},
        // {"key" : "filled_items", "header" : "Rej Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity_rejected"},
        // {"key" : "filled_items", "header" : "Orig Qty", style : {width : "100px"}, "type" : "custom", "type1" : "item_quantity_inner"},
        {"key" : "created.created_at", "header" : "Created At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "created.created_by", "header" : "Created By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors2"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor2"},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        // {"key" : "po.rfq.vendors2_list", "header" : "RFQ Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items_rejected", "header" : "Rej Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rej Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rejected Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "putaways_incomplete_by_sales_return" : [
        // {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "sr_id", "header" : "Sales Return ID", style : {width : "90px"}},
        // {"key" : "sr_id_full", "header" : "Sales Return ID", style : {width : "150px"}},
        {"key" : "created.created_warehouse", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "last_updated.updated_at", "header" : "Last Updated At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "last_updated.updated_by", "header" : "Last Updated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "users", "header" : "Users", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "users", "header" : "Users", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string1"},
        {"key" : "filled_items", "header" : "Items", style : {width : "70px"}, "type" : "custom", "type1" : "putaways_incomplete_items_count"},
        {"key" : "filled_items", "header" : "Qty", style : {width : "70px"}, "type" : "custom", "type1" : "putaways_incomplete_items_quantity"},
        // {"key" : "filled_items", "header" : "Rej Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity_rejected"},
        // {"key" : "filled_items", "header" : "Orig Qty", style : {width : "100px"}, "type" : "custom", "type1" : "item_quantity_inner"},
        {"key" : "created.created_at", "header" : "Created At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "created.created_by", "header" : "Created By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        // {"key" : "po.vendor_id", "header" : "Vendor1", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors2"},
        // {"key" : "po.vendor_id", "header" : "Vendor2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor2"},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},
        // {"key" : "po.rfq.vendors2_list", "header" : "RFQ Vendors2", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors2"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items_rejected", "header" : "Rej Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rej Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rejected Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "orders_es" : [
        {"key" : "order_id", "header" : "Order ID", style : {width : "120px"}},
        {"key" : "type", "header" : "Type", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors,acc_vendors"},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor_all"},

        {"key" : "client_customer_details.name", "header" : "Customer", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "client_customer_details.shipping_address.city", "header" : "City", style : {width : "90px"}},
        {"key" : "line_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "line_items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        {"key" : "items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "items", "header" : "Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_item_statuses", "unique_key" : "status"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "client_order.sales_order.items", "header" : "Client Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        // {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        // {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "total", "header" : "Total Price", style : {width : "70px"}},
        
        // {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        {"key" : "sales_invoices", "header" : "Sales Invoices", style : {width : "120px"}, "type" : "custom", "type1" : "sales_invoices_numbers_es"},





        {"key" : "client_order.sales_order.so_order_id", "header" : "Client SO ID", style : {width : "120px"}},
        {"key" : "client_order.stockone_order_id", "header" : "Client Order ID", style : {width : "100px"}},
        // {"key" : "client_order.created_by", "header" : "User ID", style : {width : "100px"}},
        // {"key" : "client_order.user_details.first_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "client_order.created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        // {"key" : "user_details.shipping_city", "header" : "City", style : {width : "90px"}},
        {"key" : "client_order.order_lines", "header" : "Client Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "client_order.order_lines", "header" : "Client Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        // {"key" : "gm_order_id", "header" : "GM Order ID", style : {width : "120px"}},
        //IMP
        // {"key" : "sales_order.created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "client_order.sales_order.status", "header" : "Client SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "client_order.sales_order.items", "header" : "Client Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        {"key" : "client_order.sales_order.items", "header" : "Client BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        {"key" : "client_order.sales_order.items", "header" : "Client FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        {"key" : "client_order.sales_order.items", "header" : "Client DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "client_order.sales_order.items", "header" : "Client Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "client_order.total", "header" : "Client Total Price", style : {width : "70px"}},
        
        {"key" : "client_order.payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        // {"key" : "sales_order.invoice_number", "header" : "Invoice No.", style : {width : "120px"}},
        
        // {"key" : "user_details.phone_number", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        
        // {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "sales_order_history"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},





        
        // {"key" : "client_customer_details.mobile", "header" : "Phone", style : {width : "90px"}},
        
        // {"key" : "sales_order.items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "client_order.sales_order.items", "header" : "Client Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus1"},
        {"key" : "_id.$oid", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "remote", "type1" : "dpsp_order_history"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],

      "sales_returns_es" : [
        // {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "credit_note_number", "header" : "Credit Note No.", style : {width : "120px"}},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "sku_code"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "items", "header" : "Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus4"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        {"key" : "invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "invoice.invoice_date", "header" : "Invoice Date", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "invoice.invoice_type", "header" : "Inv. Type", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "invoice.payment_status", "header" : "Payment Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "invoice.invoice_value", "header" : "Invoice Value", style : {width : "70px"}},
        {"key" : "invoice.balance_payable", "header" : "Balance Payable", style : {width : "70px"}},
        {"key" : "invoice.amount_received", "header" : "Amount Received", style : {width : "70px"}},
        {"key" : "invoice.payments", "header" : "Payments", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "sales_invoice_payments"},

        
        {"key" : "invoice.customer_name", "header" : "Customer", style : {width : "120px"}},
        {"key" : "invoice.customer_mobile", "header" : "Phone", style : {width : "90px"}},
        // {"key" : "order.created_by", "header" : "User ID", style : {width : "100px"}},
        //IMP
        {"key" : "invoice.created_at", "header" : "Invoice Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},

        {"key" : "invoice.line_items", "header" : "Items", style : {width : "70px"}, "panel" : "right", "type" : "object_count"},
        {"key" : "invoice.line_items", "header" : "Quantity", style : {width : "70px"}, "panel" : "right", "type" : "object_key_sum", "key_sum" : "quantity"},
        {"key" : "invoice.line_items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus4"},
        {"key" : "invoice.billing_address.city", "header" : "Billing City", style : {width : "90px"}},
        {"key" : "invoice.billing_address.state", "header" : "Billing State", style : {width : "90px"}},
        {"key" : "invoice.billing_address.pincode", "header" : "Billing PIN Code", style : {width : "70px"}},
        {"key" : "invoice.billing_address.address", "header" : "Billing Address", style : {width : "250px"}},
        {"key" : "invoice.shipping_address.city", "header" : "Shipping City", style : {width : "90px"}, "panel" : "right"},
        {"key" : "invoice.shipping_address.state", "header" : "Shipping State", style : {width : "90px"}, "panel" : "right"},
        {"key" : "invoice.shipping_address.pincode", "header" : "Shipping PIN Code", style : {width : "70px"}, "panel" : "right"},
        {"key" : "invoice.shipping_address.address", "header" : "Shipping Address", style : {width : "250px"}, "panel" : "right"},
        {"key" : "invoice.customer_gst", "header" : "Customer GSTIN", style : {width : "150px"}},
        {"key" : "invoice.notes", "header" : "Notes", style : {width : "250px"}},
        {"key" : "invoice.payment_mode", "header" : "Payment Mode", style : {width : "180px"}, "panel" : "right"},
        // {"key" : "invoice.operated_by", "header" : "Invoice Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"}
      ],

      "boxing_batches_es" : [
        // DSP CODE	DOCKET NO.	INVOICE NO.	INVOICE VALUE
        {"key" : "id", "header" : "ID", style : {width : "90px"}},
        // {"key" : "order.order_id", "header" : "Order ID", style : {width : "120px"}},
        {"key" : "order.client_order.stockone_order_id", "header" : "Order ID", style : {width : "120px"}},
        {"key" : "partner.name", "header" : "Vendor", style : {width : "180px"}},
        {"key" : "boxes", "header" : "Boxes", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "boxes", "header" : "Items", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_items_count"},
        {"key" : "boxes", "header" : "Quantity", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_items_quantity"},
        {"key" : "boxes", "header" : "Manifested", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_manifest_count"},
        {"key" : "boxes", "header" : "Dispatched", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_dispatched_count"},
        
        {"key" : "boxes", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "boxing_items_es", "extra_key" : "id"},
        {"key" : "boxes", "header" : "DSPs", style : {width : "70px"}, "type" : "custom", "type1" : "dsps_check"},
        // {"key" : "boxes", "header" : "DSPs", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "dsps_details"},
        
        
        
        // {"key" : "created_by", "header" : "User ID", style : {width : "100px"}},
        {"key" : "order.client_customer_details.name", "header" : "Customer", style : {width : "120px"}},
        // {"key" : "created_by", "header" : "Channel", style : {width : "70px"}, "type" : "custom", "type1" : "order_channel"},
        {"key" : "order.client_customer_details.shipping_address.city", "header" : "City", style : {width : "90px"}},
        // {"key" : "line_items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        // {"key" : "line_items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        //IMP
        {"key" : "order.created_at", "header" : "Order Created at", style : {width : "150px"}, "type" : "mongodate"},
        
        // {"key" : "sales_order.status", "header" : "SO Status", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "order.items", "header" : "Brands", style : {width : "180px"}, "type" : "custom", "type1" : "so_item_brands"},
        // {"key" : "items", "header" : "BO Status", style : {width : "180px"}, "type" : "custom", "type1" : "bo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "back"},
        // {"key" : "items", "header" : "FO Status", style : {width : "180px"}, "type" : "custom", "type1" : "fo_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "forward"},
        // {"key" : "items", "header" : "DPSP Status", style : {width : "180px"}, "type" : "custom", "type1" : "dpsp_order_statuses", "unique_key" : "status1", "filter_key" : "status", "filter_key_value" : "ttdpsp"},
        // {"key" : "sales_order.warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        // {"key" : "items", "header" : "Warehouses", style : {width : "120px"}, "type" : "custom", "type1" : "item_warehouses", "unique_key" : "warehouse_id"},
        {"key" : "order.total", "header" : "Order Total", style : {width : "70px"}},
        
        // {"key" : "payment_mode", "header" : "Payment Mode", style : {width : "90px"}},
        // {"key" : "order.sales_invoice.invoice_number", "header" : "Sales Invoice No.", style : {width : "120px"}},
        {"key" : "order.sales_invoices", "header" : "Sales Invoices", style : {width : "120px"}, "type" : "custom", "type1" : "sales_invoices_numbers_es"},
        
        {"key" : "order.client_customer_details.mobile", "header" : "Phone", style : {width : "90px"}},


        // {"key" : "grn.po_id", "header" : "PO ID", style : {width : "250px"}},
        // {"key" : "grn.po.po_id", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "grn.po.rfq.id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        // {"key" : "started_at", "header" : "Started at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "started_by", "header" : "Started By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        // {"key" : "operators", "header" : "Operators", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "operators", "header" : "Operators", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string2"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        // {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "grn.po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "grn.po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},        
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "reverse_pickups" : [
        {"key" : "id", "header" : "ID", style : {width : "90px"}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        
        {"key" : "invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "order.stockone_order_id", "header" : "Order ID", style : {width : "100px"}},
        {"key" : "boxing_batch_id", "header" : "Boxing Batch ID", style : {width : "90px"}},
        {"key" : "box_id", "header" : "Box ID", style : {width : "90px"}},
        {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity"},
        
        {"key" : "dispatch_data", "header" : "Tracking Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "tracking_details"},
        {"key" : "dispatch_data", "header" : "Tracking Status", style : {width : "70px"}, "type" : "custom", "type1" : "dsps_tracking_check1"},
        // {"key" : "boxes", "header" : "DSPs", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "dsps_details"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "boxing_batches" : [
        // DSP CODE	DOCKET NO.	INVOICE NO.	INVOICE VALUE
        {"key" : "id", "header" : "ID", style : {width : "90px"}},
        {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        
        {"key" : "picklist.orders_list", "header" : "Order IDs", style : {width : "180px"}, "type" : "custom", "type1" : "order_ids_array_string"},
        {"key" : "picklist.orders_list", "header" : "Order Created", style : {width : "180px"}, "type" : "custom", "type1" : "order_created_at_array_string"},
        // {"key" : "picklist.dsps", "header" : "DSPs", style : {width : "80px"}, "type" : "boolean"},

        {"key" : "picklist.orders_list", "header" : "Channels", style : {width : "90px"}, "type" : "custom", "type1" : "order_channel_array_string"},
        {"key" : "boxes", "header" : "Boxes", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "boxes", "header" : "Items", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_items_count"},
        {"key" : "boxes", "header" : "Quantity", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_items_quantity"},
        {"key" : "boxes", "header" : "Manifested", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_manifest_count"},
        {"key" : "boxes", "header" : "Dispatched", style : {width : "70px"}, "type" : "custom", "type1" : "boxing_dispatched_count"},
        {"key" : "picklist.orders_list", "header" : "Customers", style : {width : "180px"}, "type" : "custom", "type1" : "order_customer_firstnames_array_string"},
        
        {"key" : "boxes", "header" : "Orders", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "so_order_id"},
        {"key" : "boxes", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "boxing_items", "extra_key" : "id", "extra_key1" : "picklist.orders_list"},
        {"key" : "boxes", "header" : "DSPs", style : {width : "70px"}, "type" : "custom", "type1" : "dsps_check"},
        {"key" : "boxes", "header" : "Tracking Status", style : {width : "70px"}, "type" : "custom", "type1" : "dsps_tracking_check"},
        // {"key" : "boxes", "header" : "DSPs", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "dsps_details"},
        {"key" : "picklist.id", "header" : "Picklist ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "picklist.picklist_id", "header" : "Picklist ID", style : {width : "150px"}},
        {"key" : "picklist.orders", "header" : "PickList Orders", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "picklist.orders_list", "header" : "PickList Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        {"key" : "picklist.list", "header" : "PickList Items", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "picklist.list", "header" : "PickList Quantity", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "quantity"},
        {"key" : "picklist.list", "header" : "PickList Items", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus2"},
        {"key" : "picklist.inventory_snapshot", "header" : "PickList Inventory Snapshot", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "inventory_items"},
        // {"key" : "grn.po_id", "header" : "PO ID", style : {width : "250px"}},
        // {"key" : "grn.po.po_id", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "grn.po.rfq.id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "started_at", "header" : "Started at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "operated_by", "header" : "Operated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "started_by", "header" : "Started By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "operators", "header" : "Operators", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "operators", "header" : "Operators", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string2"},
        // {"key" : "rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        // {"key" : "grn.po.vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        // {"key" : "grn.po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        // {"key" : "grn.po.rfq.orders", "header" : "Orders", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.orders_list", "header" : "RFQ Orders", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "orders"},
        // {"key" : "grn.po.rfq.vendors", "header" : "Vendors", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "grn.po.rfq.vendors_list", "header" : "RFQ Vendors", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendors"},        
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      "boxings_incomplete" : [
        // {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        // {"key" : "grn_id", "header" : "GRN ID", style : {width : "250px"}},
        // {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "pl_id", "header" : "Picklist ID", style : {width : "90px"}},
        {"key" : "pl_id_full", "header" : "Picklist ID", style : {width : "150px"}},
        // {"key" : "po.po_id", "header" : "PO ID", style : {width : "180px"}},
        // {"key" : "po.po_type", "header" : "PO Type", style : {width : "180px"}},
        // {"key" : "po.rfq.id", "header" : "RFQ ID", style : {width : "180px"}, roles : ["admin"]},
        // {"key" : "po.rfq.rfq_id", "header" : "RFQ ID", style : {width : "120px"}},
        {"key" : "created.created_warehouse", "header" : "Warehouse", style : {width : "80px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses"},
        {"key" : "last_updated.updated_at", "header" : "Last Updated At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "last_updated.updated_by", "header" : "Last Updated By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        {"key" : "users", "header" : "Users", style : {width : "70px"}, "type" : "object_count"},
        {"key" : "users", "header" : "Users", style : {width : "80px"}, panel : "right", "type" : "custom", "type1" : "dashboard_roles_string1"},
        {"key" : "skus", "header" : "Items", style : {width : "70px"}, "type" : "custom", "type1" : "boxings_incomplete_items_count"},
        {"key" : "skus", "header" : "Qty", style : {width : "70px"}, "type" : "custom", "type1" : "boxings_incomplete_items_quantity"},
        // {"key" : "filled_items", "header" : "Rej Qty", style : {width : "70px"}, "type" : "object_key_sum", "key_sum" : "quantity_rejected"},
        // {"key" : "filled_items", "header" : "Orig Qty", style : {width : "100px"}, "type" : "custom", "type1" : "item_quantity_inner"},
        {"key" : "created.created_at", "header" : "Created At", style : {width : "150px"}, "type" : "firebasedate"},
        {"key" : "created.created_by", "header" : "Created By", style : {width : "100px"}, "type" : "localstorage_fetch", "key_fetch" : "user_id", "key_display" : "user.name", "localstorage_id" : "dashboard_roles"},
        
        // {"key" : "items", "header" : "Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items", "header" : "Quantity", style : {width : "70px"}, "type" : "list_count"},
        // {"key" : "items_rejected", "header" : "Rej Items", style : {width : "70px"}, "type" : "list_count_unique", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rej Quantity", style : {width : "70px"}, "type" : "list_count"},
        
        // {"key" : "items", "header" : "Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "items_rejected", "header" : "Rejected Item Details", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "skus", "unique_key" : "product_id"},
        // {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
      ],
      
      "debit_notes" : [
        {"key" : "dn_id", "header" : "Request ID", style : {width : "150px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "status", "header" : "Status", style : {width : "120px"}, "type" : "custom", "type1" : "vpr_status", "extra_key" : "payments"},
        {"key" : "type", "header" : "Type", style : {width : "90px"}},
        {"key" : "entity", "header" : "Entity", style : {width : "90px"}},
        {"key" : "entity_id", "header" : "Entity", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "distributors"},
        {"key" : "entity_id", "header" : "Entity", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "distributor"},
        {"key" : "amount", "header" : "Amount", style : {width : "70px"}},
        {"key" : "document_id", "header" : "Document ID", style : {width : "150px"}},
        // {"key" : "payments", "header" : "Paid Amount", style : {width : "120px"}, "type" : "array_key_sum_with_filter", "key_sum" : "amount", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "payments", "header" : "Paid Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "amount"},
        // {"key" : "payments", "header" : "Payments Made", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "notes", "header" : "Notes", style : {width : "150px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        // {"key" : "payments", "header" : "Payments", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_payments"},
        // {"key" : "tax_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
      "vendor_payment_requests" : [
        {"key" : "vpr_id", "header" : "Request ID", style : {width : "150px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "status", "header" : "Status", style : {width : "120px"}, "type" : "custom", "type1" : "vpr_status", "extra_key" : "payments"},
        {"key" : "request_type", "header" : "Request Type", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "payment_type", "header" : "Payment Type", style : {width : "90px"}},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor"},
        {"key" : "invoice_amount", "header" : "Invoice Amount", style : {width : "70px"}},
        // {"key" : "payments", "header" : "Paid Amount", style : {width : "120px"}, "type" : "array_key_sum_with_filter", "key_sum" : "amount", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "payments", "header" : "Paid Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "amount"},
        // {"key" : "payments", "header" : "Payments Made", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "payments", "header" : "Payments Made", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "invoice_date", "header" : "Invoice Date", style : {width : "90px"}},
        {"key" : "notes", "header" : "Notes", style : {width : "150px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "entity", "header" : "Entity", style : {width : "90px"}},
        {"key" : "company", "header" : "Company", style : {width : "90px"}},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "payments", "header" : "Payments", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_payments"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
      "vendor_payment_requests_acc" : [
        {"key" : "vpr_id", "header" : "Request ID", style : {width : "150px"}},
        {"key" : "status", "header" : "Status", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        // {"key" : "status", "header" : "Status", style : {width : "120px"}, "type" : "custom", "type1" : "vpr_status", "extra_key" : "payments"},
        {"key" : "request_type", "header" : "Request Type", style : {width : "90px"}, "type" : "localstorage_fetch", "key_fetch" : "status", "key_display" : "title", "localstorage_id" : "so_order_statuses"},
        {"key" : "payment_type", "header" : "Payment Type", style : {width : "90px"}},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "acc_vendors"},
        {"key" : "vendor_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "acc_vendor"},
        {"key" : "invoice_amount", "header" : "Invoice Amount", style : {width : "70px"}},
        // {"key" : "payments", "header" : "Paid Amount", style : {width : "120px"}, "type" : "array_key_sum_with_filter", "key_sum" : "amount", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "payments", "header" : "Paid Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "amount"},
        // {"key" : "payments", "header" : "Payments Made", style : {width : "70px"}, "type" : "list_count_with_filter", "filter_key" : "status", "filter_key_value" : "processed"},
        {"key" : "payments", "header" : "Payments Made", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices Amount", style : {width : "70px"}, "type" : "array_key_sum", "key_sum" : "invoice_amount"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices", style : {width : "70px"}, "type" : "list_count"},
        {"key" : "invoice_number", "header" : "Invoice Number", style : {width : "120px"}},
        {"key" : "invoice_date", "header" : "Invoice Date", style : {width : "90px"}},
        {"key" : "notes", "header" : "Notes", style : {width : "150px"}},
        {"key" : "created_at", "header" : "Created at", style : {width : "150px"}, "type" : "mongodate"},
        {"key" : "entity", "header" : "Entity", style : {width : "90px"}},
        {"key" : "company", "header" : "Company", style : {width : "90px"}},
        {"key" : "id", "header" : "ID", style : {width : "90px"}, roles : ["admin"]},
        {"key" : "_id.$oid", "header" : "_ID", style : {width : "160px"}, roles : ["admin"]},
        {"key" : "payments", "header" : "Payments", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_payments"},
        {"key" : "tax_invoices", "header" : "Uploaded Invoices", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_tax_invoices"},
        {"key" : "history", "header" : "History", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vpr_history_items"},
      ],
      "inventory_es" : [
        // {"key" : "part.product_id", "header" : "Product ID", style : {width : "120px"}},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "name", "localstorage_id" : "vendors,acc_vendors"},
        {"key" : "partner_id", "header" : "Vendor", style : {width : "180px"}, "panel" : "right", "type" : "custom", "type1" : "vendor_all"},
        {"key" : "sku_code", "header" : "SKU Code", style : {width : "120px"}, mobile_view_details : {isTitle : true, orderV : 1, orderH : 1}},
        {"key" : "qty", "header" : "Quantity", style : {width : "70px"}, mobile_view_details : {orderV : 1, orderH : 2}},
        // {"key" : "frozen_stock", "header" : "Frozen Qty", style : {width : "70px"}},
        {"key" : "location", "header" : "Location", style : {width : "90px"}, mobile_view_details : {orderV : 1, orderH : 3}},
        // {"key" : "warehouse_id", "header" : "Warehouse", style : {width : "120px"}, "type" : "localstorage_fetch", "key_fetch" : "id", "key_display" : "code", "localstorage_id" : "warehouses", mobile_view_details : {orderV : 4, orderH : 1}},
        {"key" : "part.title", "header" : "Title", style : {width : "350px"}, "type" : "custom", "type1" : "sku_title", "extra_key" : "sku_code", mobile_view_details : {orderV : 2, orderH : 1}},
        // {"key" : "part.title", "header" : "Title", style : {width : "250px"}, mobile_view_details : {orderV : 2, orderH : 1}},
        // {"key" : "part.parts", "header" : "Category", style : {width : "120px"}, "type" : "array_object_string_unique", "key_display" : "sku_category", mobile_view_details : {orderV : 3, orderH : 1}},
        {"key" : "part.sku_brand", "header" : "Brand", style : {width : "150px"}, mobile_view_details : {orderV : 3, orderH : 2}},
        {"key" : "sku_code", "header" : "BarCode", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "barcode"},
        {"key" : "part.mrp", "header" : "Selling price", style : {width : "70px"}},
        // {"key" : "part.price", "header" : "Price", style : {width : "70px"}},
        // {"key" : "part.parts", "header" : "Alternative Parts", style : {width : "70px"}, "type" : "custom", "type1" : "alt_parts_count_es", "extra_key" : "product_id"},
        // {"key" : "part.parts", "header" : "Alternative Parts", style : {width : "70px"}, "panel" : "right", "type" : "custom", "type1" : "alt_parts_product_ids_es", "extra_key" : "sku_code"},
        // {"key" : "part.parts", "header" : "Image", style : {width : "250px"}, "panel" : "right", "type" : "custom", "type1" : "image_es"},
    ],
  }

export default new constants();
