import { Loader } from "../../base/utils/loader";
import { ContentListItem } from "./ContentListItem";
import { ContentListItemTemp } from "./ContentListItemTemp";

export const ContentListItems = (props) => {

    console.log("props1", props);

    let classNameCLI = "contentlistitems border-box full_width full_height" + " " + props.displayMode;

    return (
        <>
            {
                {
                    true: 
                        <Loader innerstyle={{width : "20%"}}/>,
                    false: 
                        <>
                            <div className={classNameCLI} style={{gridTemplateColumns : "repeat("+props.gridColumnsCount+", 1fr)"}}>
                                {props.data.map((item, index) => (props.section.key == "candidates" ? <ContentListItem section={props.section} tab={props.tab} key={index} data={item} displayMode={props.displayMode} /> : <ContentListItemTemp section={props.section} tab={props.tab} key={index} data={item} displayMode={props.displayMode} />))}
                            </div>
                        </>,
                }[props.isLoading]
            }
        </>
    );
}