import { useEffect, useRef, useState } from "react";
import { EntriesPerPage } from '../../../base/utils/entriesperpage';
import { Pagination } from "../../../base/utils/pagination";
import { DisplayMode } from "../../../base/utils/displaymode";
import { Spacer } from "../../../base/utils/spacer";
import apidata from "../../../base/utils/data/apidata";
import { ContentListItems } from "../ContentListItems";
import { useWindowDimensions } from "../../../base/utils/windowdimensions";
import utils, { useHasObjectChanged, usePrevious } from "../../../utils";
import { SelectPretty } from "../../../base/utils/selectpretty";
import FieldBox from "../../../base/utils/fieldbox";
import constants from "../../../constants";
import { CalendarBaseBox } from "./CalendarBaseBox";
import { CalendarDay } from "./CalendarDay";

export const ContentCalendar = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    console.log("ContentCalendar", props.timeline);

    const getCalendarContent = () => {
        var calendarcontent = <></>;
        switch(props.timeline){
            case "today":
            case "tomorrow":
                calendarcontent = 
                    <CalendarDay {...props} />
                break;
            default:
                calendarcontent = 
                    <CalendarDay {...props} />
                break;
        }
        return calendarcontent;
    }


    return (
        <>
            {getCalendarContent()}
        </>
    );
}