import { useEffect, useRef, useState } from "react";
import { EntriesPerPage } from '../../base/utils/entriesperpage';
import { Pagination } from "../../base/utils/pagination";
import { DisplayMode } from "../../base/utils/displaymode";
import { Spacer } from "../../base/utils/spacer";
import apidata from "../../base/utils/data/apidata";
import { ContentListItems } from "./ContentListItems";
import { useWindowDimensions } from "../../base/utils/windowdimensions";
import utils, { useHasObjectChanged, usePrevious } from "../../utils";
import { SelectPretty } from "../../base/utils/selectpretty";
import FieldBox from "../../base/utils/fieldbox";
import { ContentCalendar } from "./calendar/ContentCalendar";
import { Loader } from "../../base/utils/loader";

export const ContentSchedule = (props) => {



    const [contentData, setContentData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(NaN);
    const [paramsFilter, setParamsFilter] = useState({timeline : "today"});
    const [displayMode, setDisplayMode] = useState("grid");

    let isChecked = false;

    useEffect(() => {
      if (isChecked) return;
      isChecked = true;
      
      fetchData(paramsFilter);
      
    }, []);

    const handleFilterChange = (type, value) => {
        let newFilters = {...paramsFilter, [type] : value};
        setParamsFilter({...paramsFilter, [type] : value});
        console.log("handleFilterChange", type, value);
        fetchData(newFilters);
    }

    function handleFieldState(key, value) {
        handleFilterChange(key, value);
    }

    async function fetchData(params_filter = {}, isReload = true){
        setIsLoading(true);
        const res = await apidata.fetchInterviewsScheduleData(params_filter, isReload);
        setFetchedData(res.status, res.data ? res.data : []);
    }
  
    function setFetchedData(status, data){
        if(status === "success"){
            setContentData(data);
        }else{
            setContentData([]);
        }
        setIsLoading(false); 
    }

    return (
        <>
        {isLoading ? <Loader innerstyle={{width : "20%"}}/> : 
            <div className="contentschedulebase border-box full_height full_width flex_vertical start_vertical center_horizontal">
                <div className="header border-box full_width flex_horizontal center_vertical space_between_horizontal">
                    <div className="filters border-box flex_horizontal center_vertical start_horizontal">
                        {/* <SelectPretty size="medium" title="View" type="view" inlineTitle={true}
                            objects={props.tab.filtersPrimary.view.map(o => ({title : o.title, id : o.id}))} 
                            selected={paramsFilter.view}
                            onClick={handleSelectPrettyClick} /> */}
                        <FieldBox objectKey="timeline" title="Timeline" selectedValue={paramsFilter.timeline} size="medium"
                                    onSetFieldState={handleFieldState} type="selectpretty"
                                    choiceType="timeline" noTitle={true} inlineTitle={true}
                                    choices={props.tab.filtersPrimary.timeline.map(o => ({title : o.title, id : o.id}))}
                                    errorDisplay="no"/>
                        {paramsFilter.timeline == "custom" ? <>
                        <FieldBox objectKey="datefrom" title="From" value={paramsFilter.datefrom} size="medium"
                                    onSetFieldState={handleFieldState} type="datepretty"
                                    noTitle={true} inlineTitle={true} limits="noPast"
                                    errorDisplay="no"/>
                        <FieldBox objectKey="dateto" title="To" value={paramsFilter.dateto} size="medium"
                                    onSetFieldState={handleFieldState} type="datepretty"
                                    noTitle={true} inlineTitle={true} limits="noPast"
                                    errorDisplay="no"/>
                        </> : <></>}
                    </div>
                </div>
                
                <div className="main border-box spacer clearfix full_width flex_horizontal start_vertical center_horizontal">
                    <div className="calendarbox border-box full_height spacer flex_vertical start_vertical center_horizontal">
                        <ContentCalendar {...props} data={contentData} timeline={paramsFilter.timeline} selectedItem={selectedItem} />
                    </div>
                    <div className="calendaritemlist border-box full_height flex_vertical start_vertical center_horizontal bgcolorGrayE50">
                        
                    </div>
                </div>
            </div>
            }
        </>
    );
}