import { AnimationLive } from "../base/utils/animation_live";
import Linkbutton from "../base/utils/linkbutton";
import { Spacer } from "../base/utils/spacer";
import { companyName } from "../constants";

export default function Topbaraccount(props) {

  // let className  = "topbarinner border-box interviewcandidate";
  // if (props.status == "live"){
  //   className += " live";
  // }

  
  let content = 
      <div className="topbarinner border-box account flex_horizontal center_horizontal center_vertical">
          <div className="left border-box weight900 colorGrayE256span">
              {companyName}
          </div>
          <Spacer/>
          {/* <div className="topbarinnerright border-box">
              {props.page !== "login" ? <Linkbutton text="Log in" href="/" newTab="no" type="topbar_large" button_type="transparent" /> : <></>}
              {props.page !== "signup" ? <Linkbutton text="Sign up" href="/signup" newTab="no" type="topbar_large" button_type="solid" /> : <></>}
          </div> */}
      </div>

  return (
    <>{content}</>
  );
}