import { useEffect, useRef, useState } from "react";
import { EntriesPerPage } from '../../../base/utils/entriesperpage';
import { Pagination } from "../../../base/utils/pagination";
import { DisplayMode } from "../../../base/utils/displaymode";
import { Spacer } from "../../../base/utils/spacer";
import apidata from "../../../base/utils/data/apidata";
import { ContentListItems } from "../ContentListItems";
import { useWindowDimensions } from "../../../base/utils/windowdimensions";
import utils, { useHasObjectChanged, usePrevious } from "../../../utils";
import { SelectPretty } from "../../../base/utils/selectpretty";
import FieldBox from "../../../base/utils/fieldbox";
import constants from "../../../constants";
import { CalendarBaseBox } from "./CalendarBaseBox";

export const CalendarDay = (props) => {

    const boxRef = useRef(null);

    const [entriesperpage, setEntriesPerPage] = useState("1000");
    const [selectedItem, setSelectedItem] = useState(NaN);
    const [gridColumnsCount, setGridColumnsCount] = useState(3);

    const [offsetTop, setOffsetTop] = useState(0);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [offsetHeight, setOffsetHeight] = useState(0);
    const [offsetWidth, setOffsetWidth] = useState(0);
    const [itemAvailableHeight, setItemAvailableHeight] = useState(0);
    const [itemAvailableWidth, setItemAvailableWidth] = useState(0);
    const [itemHeight, setItemHeight] = useState(0);
    const [itemWidth, setItemWidth] = useState(0);

    const date = constants.getDateRangeFromTimeline(props.timeline).dateFrom;

    console.log("CalendarDay", props.timeline, date);

    
    const timeSegmentsDay = constants.getTimeSegmentsDay();

    const contentData = Object.keys(timeSegmentsDay).map((key) => ({
        key : key,
        list : props.data.filter((item) => (new Date(date.getFullYear(), date.getMonth(), date.getDay(), timeSegmentsDay[key].timeline.from.hour, timeSegmentsDay[key].timeline.from.minute)) <= constants.get_DateX(new Date(item.interview_start_time.$date)) && constants.get_DateX(new Date(item.interview_start_time.$date)) <= (new Date(date.getFullYear(), date.getMonth(), date.getDay(), timeSegmentsDay[key].timeline.to.hour, timeSegmentsDay[key].timeline.to.minute)))
    }));

    const maxItemCount = Math.max(...contentData.map((item) => item.list.length));
    const minItemHeight = 20;
    const maxItemHeight = 80;
    const minItemWidth = 100;
    const maxItemWidth = 160;

    useEffect(() => {
        setOffsetTop(boxRef.current.offsetTop);
        setOffsetLeft(boxRef.current.offsetLeft);
        setOffsetHeight(boxRef.current.offsetHeight);
        setOffsetWidth(boxRef.current.offsetWidth);
        setItemHeight(((boxRef.current.offsetHeight-2)/6));
        setItemWidth(((boxRef.current.offsetWidth-2)/contentData.filter((item) => item.list.length > 0).length));
        setItemAvailableHeight(((boxRef.current.offsetHeight-2)/6)-10-20);
        setItemAvailableWidth(((boxRef.current.offsetWidth-2)/contentData.filter((item) => item.list.length > 0).length)-10);
  
        console.log("offsets1", boxRef.current.offsetTop, boxRef.current.offsetLeft, boxRef.current.offsetHeight, boxRef.current.offsetWidth);
        console.log("offsets", offsetTop, offsetLeft, offsetHeight, offsetWidth);
      }, [boxRef.current]);

    const getGridColumnsCount = () => {
        if(maxItemCount*maxItemHeight < offsetHeight){
            return 1;
        }else{
            if(maxItemCount*maxItemWidth < offsetWidth){
                if(maxItemCount*maxItemHeight/2 < offsetHeight){
                    return 2;
                }else{
                    return 1;
                }
            }else{
                if(maxItemCount*maxItemHeight/3 < offsetHeight){
                    return 3;
                }else{
                    return 1;
                }
            }
        }
    }

    console.log("contentData", contentData);

    const calendarbasebox = (index, hour, item) => {
        console.log("offsets2", itemAvailableHeight, itemAvailableWidth);
        let hour1 = (hour > 12 ? hour - 12 : (hour == 24 ? 12 : hour)) + (hour < 12 ? "AM" : "PM");
        let hour2 = ((hour+1) > 12 ? (hour+1) - 12 : ((hour+1) == 24 ? 12 : (hour+1))) + ((hour+1) < 12 ? "AM" : ((hour+1) == 24 ? "AM" : "PM"));
        return <CalendarBaseBox gridColumnsCount={getGridColumnsCount()} compHeight={itemHeight} key={index} hour={hour} startHour={hour1} endHour={hour2} data={item.list.filter(o => hour <= constants.get_DateX(new Date(o.interview_start_time.$date)).getHours() && constants.get_DateX(new Date(o.interview_start_time.$date)).getHours() < (hour == 23 ? 24 : (hour + 1)))} />
    }

    const getCalendarContent = () => {
        console.log("getCalendarContent", contentData);
        return (
            <div className="border-box mainInner full_height clearfix full_width flex_horizontal start_vertical center_horizontal" ref={boxRef}>
            {contentData.filter((item) => item.list.length > 0)
            .map((item, index) => 
                <div key={index} className="border-box daysegment spacer flex_vertical start_vertical start_horizontal">
                    {/* <div className="header border-box full_width flex_horizontal center_vertical center_horizontal bgcolorGrayE5 colorGrayE133span uppercased weight600">
                        {timeSegmentsDay[item.key].title}
                    </div> */}
                    <div className="content border-box full_width spacer flex_vertical start_vertical start_horizontal">
                        {utils.range(timeSegmentsDay[item.key].timeline.to.hour-timeSegmentsDay[item.key].timeline.from.hour, timeSegmentsDay[item.key].timeline.from.hour)
                                .map((hour, index1) => 
                                calendarbasebox(index1, hour, item)
                        )}
                    </div>
                </div>
            )}
            </div>);
    }

    const getCalendarHeader = () => {
        console.log("getCalendarContent", contentData);
        return (
            <div className="border-box mainInner clearfix full_width flex_horizontal start_vertical center_horizontal">
            {contentData.filter((item) => item.list.length > 0)
            .map((item, index) => 
                <div key={index} className="border-box daysegment spacer flex_vertical start_vertical start_horizontal">
                    <div className="header border-box full_width flex_horizontal center_vertical center_horizontal bgcolorGrayE5 colorGrayE133span uppercased weight600">
                        {timeSegmentsDay[item.key].title}
                    </div>
                </div>
            )}
            </div>);
    }


    return (
        <>
            <div className="calendarbase border-box full_width full_height flex_vertical start_vertical start_horizontal bgcolorGrayE30">
                <div className="header border-box full_width flex_horizontal center_vertical space_between_horizontal">
                    {getCalendarHeader()}
                </div>
                <div className="main border-box clearfix spacer full_width flex_vertical start_vertical center_horizontal">
                    {getCalendarContent()}
                </div>
            </div>
        </>
    );
}