import { Outlet } from 'react-router-dom';

export default function AppMonitor(props) {
    return (
        <>
            <>
                <Outlet />
            </>
        </>
    );
}