import { Link } from "react-router-dom";
import Linkbutton from "../base/utils/linkbutton";
import constants, { companyName } from "../constants";

export default function Topbarauth(props) {

    function sectionClickHandler(section) {
        props.sectionClickHandler(section);
    }

    var sectionList = constants.getSections().map((section) => {
        return (
            <div className={section.key === props.liveSection ? "clickable border-box cursor-pointer active" : "clickable border-box cursor-pointer"} onClick={() => sectionClickHandler(section.key)}>
                {section.icon}
                <span style={{width : "5px"}} />
                {section.title}
            </div>
        );
    });

  return (
    <div className="topbarinner border-box auth">
        <div className="topbarinnerleft border-box weight600">
            <Link to="/">{companyName}</Link>
        </div>
        <div style={{width : "100px"}} />
        <div className="topbarinnermiddle border-box">
            {sectionList}
        </div>
        <div className="flexspacer border-box" />
        <div className="topbarinnerright border-box">
            
        </div>
    </div>
  );
}