import { set } from "date-fns";
import { Spacer } from "../../../base/utils/spacer";
import constants from "../../../constants";
import utils from "../../../utils";
import { useEffect, useRef, useState } from "react";

export const CalendarBaseBox = (props) => {

    console.log("offsets3", props.compHeight);
    let classNameInner = "calendarbaseboxinner border-box full_width full_height flex_vertical start_vertical start_horizontal";
    let classNameEvent = "eventbase border-box full_width clearfix flex_vertical center_vertical center_horizontal";
    let classNameEventHeader = "header border-box full_width flex_horizontal center_vertical start_horizontal";
    let classNameEventContent = "content border-box full_width spacer flex_vertical start_vertical start_horizontal";
    if(props.hour < (new Date()).getHours()){
        classNameInner += " bgcolorGrayE14 colorGrayE100span";
        classNameEvent += " bgcolorGrayE40";
        classNameEventHeader += " colorGrayE150span";
        classNameEventContent += " colorGrayE140span";
    }else{
        classNameInner += " bgcolorGrayE1 colorGrayE133span";
        classNameEvent += " bgcolorOneA200";
        classNameEventHeader += " colorGrayE2span";
        classNameEventContent += " colorGrayE10span";
    }

    console.log("CalendarBaseBox", props.startHour, props.endHour, props.hour, props.data.map((item) => constants.get_DateX(new Date(item.interview_start_time.$date))));

    const getContent = () => {
        return (
            <>
            {props.data.map((item, index) => 
                <div key={index} className={classNameEvent}>
                    <div className={classNameEventHeader}>
                        <span className="fontspx9 weight400">{item.candidate.first_name + " " + item.candidate.last_name}</span>
                    </div>
                    <div className={classNameEventContent}>
                    <span className="fontspx7 weight300">{constants.get_TimeString(constants.get_DateX(item.interview_start_time.$date))}</span>
                    </div>
                </div>
            )}
            </>);
    }

    return(
        <>
            <div className="calendarbaseboxbase border-box full_width" style={{height : (props.compHeight)+"px"}}>
                <div className={classNameInner}>
                    <div className="header border-box full_width flex_horizontal start_horizontal start_vertical">
                        <span className="border-box flex_horizontal start_horizontal start_vertical weight300"><span className="fontspx13">{props.startHour}</span><span className="fontspx8" style={{marginLeft : "5px"}}> to {props.endHour}</span></span>
                        <Spacer />
                        <span className="fontspx8 weight300 colorGrayE117span"><span className="weight500">{props.data.length}</span><span className="fontspx7"> {utils.getPluralText("interview", props.data.length)}</span></span>
                    </div>
                    <div className="content border-box full_width spacer" style={{gridTemplateColumns : "repeat("+props.gridColumnsCount+", 1fr)", gap : "2px"}}>
                        {getContent()}
                    </div>
                </div>
            </div>
        </>
    )
}