import { Navigate, useNavigate, useParams } from 'react-router-dom';
import './orgadmin.css';
import { useEffect, useState } from 'react';
import constants from '../constants';
import utils from '../utils';
import { Sidebar } from '../Sidebar';
import { ContentMiddle } from './ContentMiddle';

export default function OrgAdmin(props) {
    const navigate = useNavigate();
    const {section, tab} = useParams();

    let loaded = false;

    const [liveSection, setLiveSection] = useState(section);

    let sectionData = constants.getSidebarFooterSection(section);
    // let tabData = tab === undefined ? (constants.checkMonitorTabsExist(section) ? constants.getMonitorTabByIndex(section, 0) : null) : constants.getMonitorTabByKey(section, tab);
    let tabData = tab === undefined ? null : constants.getSidebarFooterTabByKey(section, tab);

    console.log("useEffect2", section, tab);

    let content = <></>;

    if(utils.isLoggedIn() == false){
        content = <Navigate to="/account" />;
    }else{
        if(!sectionData) {
            content = <Navigate to="/org/integrations" />;
        }else if (!tabData) {
            if(constants.checkSidebarFooterTabsExist(section)){
                tabData = constants.getSidebarFooterTabByIndex(section, 0);
                content = <Navigate to={"/org/" + section + "/pages/" + tabData.key} />;
            }
        }else{
            content = <>
                <Sidebar />
                <ContentMiddle section={sectionData} tab={tabData} />
            </>;
        }
    }

    // const navigationHandler = () => {
    //     console.log("useEffect", section, tab);
    //     loaded = true;
    //     if(utils.isLoggedIn() == false){
    //         navigate("/account");
    //     }else{
    //         console.log("useEffectX1");
    //         if(!sectionData) {
    //             console.log("useEffectX2");
    //             navigate("/monitor/dashboard");
    //         }else if (!tabData) {
    //             console.log("useEffectX3");
    //             if(constants.checkMonitorTabsExist(section)){
    //                 console.log("useEffectX4");
    //                 tabData = constants.getMonitorTabByIndex(section, 0);
    //                 console.log("useEffect3", "tabData", tabData, "/monitor/" + section + "/pages/" + tabData.key);
    //                 navigate("/monitor/" + section + "/pages/" + tabData.key);
    //             }
    //         }else{
    //             console.log("useEffectX5");
    //         }
    //     }
    // }

    // if (loaded){
    //     navigationHandler();
    // }

    // useEffect(() => {
    //     console.log("useEffect1", section, tab);
    //     navigationHandler();
    // }, []);
    
    // function sectionClickHandler(section) {
    //     setLiveSection(section);
    // }

    // let contentSidebar = <Sidebar />;
    // let contentMiddle = <ContentMiddle section={sectionData} tab={tabData} />;
  
    return (
        <>
            <div className="monitorbase border-box flex_horizontal center_horizontal center_vertical bgcolorGrayE15">
                {content}
                {/* {contentSidebar}
                {contentMiddle} */}
                {/* <Dashboard type={props.type} liveSection={liveSection} sectionClickHandler={sectionClickHandler} {...props} /> */}
            </div>
        </>
    );


    
}