import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';

export default function Bottombarinterviewcandidate() {
	return (
		<div className="bottombarinner border-box interviewcandidate">
			<div className="bottombarinnerleft border-box">
                <a href="/terms-of-user" target="_blank" className="colorGrayE1span">Terms of Use</a>
                <span style={{width: "20px", display: "inline-block"}}></span>
                <a href="/privacy-policy" target="_blank" className="colorGrayE1span">Privacy Policy</a>
            </div>
            <div className="bottombarinnerright border-box colorGrayE1span">
                © 2023 ThorinTech.com
            </div>
		</div>
	);
}