import { SidebarTip } from "./sidebartip";

export const SidebarDynamic = (props) => {
    
    const clickHandler = (type, extra) => {
        
    }

    let content = <></>;
    switch(props.type) {
        case "tip":
            content = <SidebarTip {...props} clickHandler={clickHandler} />;
            break;
    }

    return (
        <>
            {content}
        </>
    );
}