import Linkbutton from "../base/utils/linkbutton";
import constants, { companyName } from "../constants";

export const SidebarTip = (props) => {
    
    return (
        <div className="base border-box full_width flex_vertical center_horizontal start_vertical bgcolorTwoA400">
            <div className="header border-box full_width flex_horizontal space_between_horizontal start_vertical">
                <img className="border-box" src="/images/bulb.png" alt="tip" height="40" />
                <i className="ti ti-x"></i>
            </div>
            <div className="title border-box full_width flex_horizontal start_horizontal start_vertical weight300">
                Set up your email pipeline
            </div>
            <div className="subtitle border-box full_width flex_horizontal start_horizontal start_vertical weight200">
                Connect your email to automatically feed inbox resumes into your {companyName} account.
            </div>
            <div className="action border-box full_width flex_horizontal start_horizontal start_vertical">
                <Linkbutton text="Learn more" href="/" newTab="yes" type="topbar_large" button_type="solid white" size="small" />
            </div>
        </div>
    );
}