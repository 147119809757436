import { useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "../../base/utils/windowdimensions";
import chat_utils_admin from "../../chat/utils/chat_utils_admin";
import { PanelLeft } from "./chat/PanelLeft";
import { PanelMiddle } from "./chat/PanelMiddle";
import apidata from "../../base/utils/data/apidata";
import { set } from "date-fns";
import { Overlay } from "../../base/utils/overlay/overlay";
import { PanelRight } from "./chat/PanelRight";

export const ContentChat = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedThread, setSelectedThread] = useState(NaN);
    const [paramsFilter, setParamsFilter] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [threadsList, setThreadsList] = useState([]);
    const [allModels, setAllModels] = useState({});
    const [divK, setDivK] = useState({});
    const [divM, setDivM] = useState({});
    const [divH, setDivH] = useState({});
    const [isCustomerTyping, setIsCustomerTyping] = useState(false);
    const [shouldScroll, setShouldScroll] = useState(false);
    const [overlay, setOverlay] = useState(null);
    const [isPanelMiddleLoading, setIsPanelMiddleLoading] = useState(false);

    const chatRef = useRef(null);

    let isChecked = false;

    
    let windowDimensions = useWindowDimensions();
    // console.log("windowDimensions", useWindowDimensions());
    // console.log("reloadingforwindowDimensions");

    // useWindowDimensions((windowDimensions) => console.log("windowDimensions", windowDimensions));


    useEffect(() => {
        if(isChecked) return;
        isChecked = true;
        chat_utils_admin.loadInitial(setChatState, setTypingState, false);
        // fetchData();
        
      }, []);

      useEffect(() => {
        if(chatRef.current && shouldScroll)
            chatRef.current.scrollTo({ behavior: "smooth", top: chatRef.current.scrollHeight});
            setShouldScroll(false);
        }, [shouldScroll]);

        useEffect(() => {
            if(chatRef.current)
                chatRef.current.scrollTo({ behavior: "smooth", top: chatRef.current.scrollHeight});
        }, [isCustomerTyping]);

        useEffect(() => {
            if(selectedThread && threadsList.find((thread) => thread.id == selectedThread) && threadsList.find((thread) => thread.id == selectedThread).is_typing_user){
                setIsCustomerTyping(true);
            }else{
                setIsCustomerTyping(false);
            }
            if(chatRef.current)
                chatRef.current.scrollTo({ behavior: "smooth", top: chatRef.current.scrollHeight});
        }, [selectedThread]);

    const setChatState = (threads_list = null, all_models = null, divK = null, divM = null, divH = null, isEmpty = false, thread_id = null) => {
        console.log("setChatState", threads_list, all_models, divK, divM, divH, isEmpty, thread_id);
        if(threads_list){
            setThreadsList([...threads_list]);
        }
        if(all_models){
            setAllModels({...all_models});
        }
        if(divK){
            setDivK({...divK});
        }
        if(divM){
            setDivM({...divM});
        }
        if(divH){
            setDivH({...divH});
        }
        console.log("viewtypeXX", "setChatState", thread_id, selectedThread);
        if(thread_id && thread_id == selectedThread){
            setShouldScroll(true);
        }
        // setAllModels([...all_models[thread_id]]);
        // setDivK([...var_divK[thread_id]]);
        // setDivM([...var_divM[thread_id]]);
        // setDivH([...var_divH[thread_id]]);
        // setIsEmpty(var_isEmpty ? true : false);
        // scrollToBottom();
    }

    const setTypingState = (role, is_typing, thread_id) => {
        console.log("setTypingState", role, is_typing);
        if(role == "user" && thread_id == selectedThread){
            setIsCustomerTyping(is_typing);
            // setIsAssistantTyping(true);
        }
    }

      const typingAlertHandler = (thread_id, is_typing) => {
        chat_utils_admin.isTypingAlert(thread_id, is_typing);
      }

      const messageSendHandler = (thread_id, message) => {
        let message_obj = chat_utils_admin.sendMessage(message, thread_id, setChatState);
        postMessage(thread_id, message_obj);
      } 

    const postMessage = async (thread_id, message) => {
        const res = await apidata.postMessage({thread_id : thread_id, message : message});
        // setFetchedData(res.status, res.data ? res.data : []);
    }

    const searchQueryHandler = (query) => {
        setSearchQuery(query);
        chat_utils_admin.searchThreads(query, setChatState);
    }

    const threadClickHandler = (thread_id) => {
        console.log("thread_id", thread_id)
        // if(this.state.live){
        chat_utils_admin.handleThreadClick(thread_id, setChatState, threadsList);
        setSelectedThread(thread_id);
        // }else{
        //   ChatUtilsArchive.clickTab(tag, this, this.state.archived_threads_list);
        // }
        // API.getChatUserDetails(tag, this);
        // API.getSupportChatArchivesByKey(tag, this);
        // API.getOrderDetailsPaginate(tag, [], this);
        // API.tabClickCall(tag, that);
      }

    const rowClickHandler = () => {

    }

    const handoff = async (thread_id) => {
        setIsPanelMiddleLoading(true);
        const res = await apidata.handoffThread({id : thread_id});
        setIsPanelMiddleLoading(false);
        // setSelectedThread(NaN);
        // setFetchedData(res.status, res.data ? res.data : []);
    }

    const archiveThread = async (thread_id) => {
        setIsPanelMiddleLoading(true);
        const res = await apidata.archiveThread({id : thread_id});
        setSelectedThread(NaN);
        setIsPanelMiddleLoading(false);
        // setFetchedData(res.status, res.data ? res.data : []);
    }

    const buttonClickHandler = (buttonType, data=null) => {
        switch (buttonType) {
            case "archive":
                initiateOverlay("confirmation1", data);
                break;
            case "handoff":
                initiateOverlay("confirmation1", data);
                break;
            case "chat_initiate":
                initiateOverlay("chat_initiate");
                break;
            default:
                break
        }
    }

    const initiateOverlay = (type, data=null) => {
        setOverlay({isShowing : true, type : type, data : data});
    }

    const onOverLayClose = () => {
        setOverlay(null);
    }

    const overlayButtonClickHandler = (buttonType, data=null) => {
        if (buttonType == "button_positive"){
            switch(data.key){
                case "handoff":
                    console.log("handingoff");
                    onOverLayClose();
                    handoff(data.thread_id);
                    break;
                case "archive":
                    console.log("handingoff", "archive");
                    onOverLayClose();
                    archiveThread(data.thread_id);
                    break;
            }
        }
    }


    return (
        <>
            <div className="contentchatbase border-box full_height full_width flex_vertical start_vertical center_horizontal">
                <div className="header border-box full_width flex_horizontal center_vertical center_horizontal">
                    <div className="spacer" />
                    <button className="bttn small solid one" onClick={(e) => buttonClickHandler("chat_initiate")}>Initiate</button>
                </div>
                
                <div className="main border-box clearfix spacer full_width flex_horizontal center_vertical center_horizontal">
                    <PanelLeft 
                        handleThreadClick={threadClickHandler} selectedThread={selectedThread}
                        searchQuery={searchQuery} onSetSearchQuery={searchQueryHandler} 
                        threadsList={threadsList} allModels={allModels}
                        pagetab = "live" />
                    <PanelMiddle chatRef={chatRef} selectedThread={selectedThread}
                        selectedThreadDetails={threadsList.find((thread) => thread.id == selectedThread)}
                        searchQuery={searchQuery} onSetSearchQuery={searchQueryHandler}
                        threadsList={threadsList} allModels={allModels} 
                        // onArchiveThread={onArchiveThread}
                        // onHandoff={onHandoff}
                        isLoading={isPanelMiddleLoading}
                        buttonClickHandler={buttonClickHandler}
                        rowClickHandler={rowClickHandler} messageSendHandler={messageSendHandler}
                        typingAlertHandler={typingAlertHandler}
                        isCustomerTyping={isCustomerTyping}
                        pagetab = "live" />
                    <PanelRight 
                        selectedThread={selectedThread}
                        selectedThreadDetails={threadsList.find((thread) => thread.id == selectedThread)}
                        threadsList={threadsList} allModels={allModels}
                        pagetab = "live" />
                </div>
                
                <div className="footer border-box full_width flex_horizontal center_vertical center_horizontal bgcolorGrayE1">
                    {/* <EntriesPerPage
                        onClick={choiceBoxMultiClickHandler}
                        selectedValue={entriesperpage ? entriesperpage.toLowerCase() : "30"}
                    />
                    <Spacer/>
                    <Pagination buttonClickHandler={buttonClickHandler} page={page} />
                    <Spacer/>
                    <DisplayMode
                        onClick={choiceBoxMultiClickHandler}
                        selectedValue={displayMode ? displayMode.toLowerCase() : "list"}
                    /> */}
                </div>
            </div>
            {overlay && overlay.isShowing ? <Overlay type={overlay.type} data={overlay.data} onOverLayClose={onOverLayClose} overlayButtonClickHandler={overlayButtonClickHandler} /> : <></>}
        </>
    );
}