import React from 'react';
import './overlay.css';
import { ChatInitiate } from './chat_initiate';
import { Confirmation1 } from './confirmation1';

export const Overlay = (props) => {

    const buttonClickHandler = (buttonType, data) => {
        switch(buttonType) {
            case "close":
                props.onOverLayClose();
                break;
            default:
                props.overlayButtonClickHandler(buttonType, data);
                break;
        }
    }

    const getContent = () => {
        switch(props.type) {
            case "confirmation1":
                return <Confirmation1 {...props} buttonClickHandler={buttonClickHandler} />
            case "chat_initiate":
                return <ChatInitiate {...props} buttonClickHandler={buttonClickHandler} />;
            // case "deletefile":
            //     return <DeleteFile data={props.data} onSetPopUp={props.onSetPopUp} />;
            default:
                return <React.Fragment />;
        }
    }

    return(
        props.type ? 
            <div className="styleOverlay outer border-box">
                {
                    {
                        // 'searchfileupload': <SearchFileUpload data={this.props.data} onSetPopUp={this.props.onSetPopUp} />,
                        // 'deletefile': <DeleteFile data={this.props.data} onSetPopUp={this.props.onSetPopUp} />,
                        default : <React.Fragment />
                    }[props.type]
                }
                <div className="styleOverlay inner border-box shadowbox bgwhite flex_vertical center_vertical center_horizontal">
                    {getContent()}
                </div>
            </div>
          : <></>
    )
}

