import constants from '../../constants';
import { child, get, getDatabase, off, onChildAdded, onValue, orderByKey, push, query, ref, remove, set, startAt } from 'firebase/database';

var thread_codes = [];
var threads_list = [];

var divM= {};
var divH= {};
var divK= {};
var div_lastkeys= {};
var div_lasttimes= {};
var div_lastmessages= {};
var div_lastmessagetimes = {};

var org_id = null;
var thread_id = null;

var all_models = {};

var fbListeners1 = [];
var fbListeners2 = [];
var fbListeners3 = [];
var fbListeners4 = [];

var isEmpty = true;

const database = constants.getFirebaseDatabase();
var chatRef = ref(database, 'threads');
var queueRef = ref(database, 'queue/tasks');

class chat_utils{
    loadInitialMain(onSetChatState, onSetTypingState, returnNow){
        org_id = sessionStorage.getItem("org_id");
        thread_id = sessionStorage.getItem("thread_id");

        divM= {};divH= {};divK= {};
        div_lastkeys= {};div_lasttimes= {};div_lastmessages= {};div_lastmessagetimes = {};
        thread_codes = [];threads_list = [];all_models = {};

        var models= [];var texts = [];var textHs = [];var mKeys = [];

        let listener21 = get(child(chatRef, org_id+"/"+thread_id)).then(function(data) {
            if (data.val() != null){
                
            }else{
                
            }
            var chat_start_key = " ";

            divM[thread_id] = texts;divH[thread_id] = textHs;divK[thread_id] = mKeys;
            div_lastkeys[thread_id] = null;
            div_lasttimes[thread_id] = null;
            div_lastmessages[thread_id] = null;
            div_lastmessagetimes[thread_id] = null;
            
            all_models[thread_id] = models;
            
            var currentModel = null;
            var messageCount = 0;
            
            data.child("chat").forEach(function(child){
                if(!currentModel || isEmpty || (currentModel && !constants.get_TimeComparison(currentModel['time'], child.val().time))){
                    var texts = divM[thread_id];var textHs = divH[thread_id];var mKeys = divK[thread_id];
                    var textH = "";var textM = "";
                    var message= {
                        senderName:null,
                        role:null,
                        time:child.val().time,
                        textH : textH,
                        textM : textM
                    };

                    models.push(message);    
                    
                    textHs.push(textH);texts.push(textM);mKeys.push(null);
                    divM[thread_id] = texts;divH[thread_id] = textHs;divK[thread_id] = mKeys;
                    div_lastkeys[thread_id] = null;
                    div_lasttimes[thread_id] = message['time'];
                    div_lastmessages[thread_id] = textM;
                    div_lastmessagetimes[thread_id] = message['time'];
                }

                isEmpty = false;

                var texts = divM[thread_id];var textHs = divH[thread_id];var mKeys = divK[thread_id];
                
                var textH = '';var textM = '';
                
                if (child.val().notification){
                    textH = child.val().type.toUpperCase();textM = child.val().text;
                } else{
                    textH = '';textM = child.val().text;
                }
                

                var datanew= all_models[thread_id];
                
                var newobject= {
                    senderName : child.val().senderName,
                    role : child.val().role,
                    time : child.val().time,
                    textH : textH,
                    textM : textM,
                };

                currentModel = newobject;

                datanew.push(newobject);

                textHs.push(textH);texts.push(textM);mKeys.push(child.key);
                divM[thread_id] = texts;divH[thread_id] = textHs;divK[thread_id] = mKeys;
                div_lasttimes[thread_id] = child.val().time;
                div_lastmessages[thread_id] = textM;
                div_lastmessagetimes[thread_id] = child.val().time;

                messageCount += 1;
                
                all_models[thread_id] = datanew
                
                chat_start_key = child.key;

                onSetChatState(all_models, divK, divM, divH, false);
            });

            
            var childref= child(chatRef, org_id+"/"+thread_id);
            let listener5 = onChildAdded(query(child(childref, "chat"), orderByKey(), startAt(chat_start_key)), (child) => {
                if (thread_id in divK){
                    var exists = false;
                    for (var i = 0; i < divK[thread_id].length; i++){
                        if (child.key == divK[thread_id][i]){
                            exists = true;break;
                        }
                    }
    
                    if(!exists){
                        var datanew1= all_models[thread_id];
                        if(!datanew1 || isEmpty || (datanew1.length > 0 && !constants.get_TimeComparison(datanew1[datanew1.length-1]['time'], child.val().time))){
                            var texts = divM[thread_id];var textHs = divH[thread_id];var mKeys = divK[thread_id];
                            var textH = "";var textM = "";
                                
                            var message= {
                                senderName:null,
                                role:null,
                                time:child.val().time,
                                textH : textH,
                                textM : textM
                            };
        
                            models.push(message);
                            textHs.push(textH);texts.push(textM);mKeys.push(null);
                            divM[thread_id] = texts;divH[thread_id] = textHs;divK[thread_id] = mKeys;
                            div_lastkeys[thread_id] = null;
                            div_lasttimes[thread_id] = message['time'];
                            div_lastmessages[thread_id] = textM;
                            div_lastmessagetimes[thread_id] = message['time'];
                        }

                        isEmpty = false;

                        var texts = divM[thread_id];var textHs = divH[thread_id];var mKeys = divK[thread_id];
                        var textH = '';var textM = '';
                        
                        if (child.val().notification){
                            textH = child.val().type.toUpperCase();textM = child.val().text;
                        } else{
                            textH = '';textM = child.val().text;
                        }

                        var datanew= all_models[thread_id];
                        var newobject= {
                            senderName : child.val().senderName,
                            role : child.val().role,
                            time : child.val().time,
                            textH : textH,
                            textM : textM,
                        };

                        currentModel = newobject;

                        datanew.push(newobject);
            
                        textHs.push(textH);texts.push(textM);mKeys.push(child.key);
                        divM[thread_id] = texts;divH[thread_id] = textHs;divK[thread_id] = mKeys;
            

                        div_lasttimes[thread_id] = child.val().time;
                        div_lastmessages[thread_id] = textM;
                        div_lastmessagetimes[thread_id] = child.val().time;
                                
                        all_models[thread_id] = datanew

                        onSetChatState(all_models, divK, divM, divH, false);

                    }
                                
                }
            });
            
            fbListeners3.push({'path' : 'chat', 'ref' : childref, 'type' : 'child_added', 'listener' : listener5});
            onSetChatState(all_models, divK, divM, divH, null);                
        });
        
        fbListeners1.push({'path' : null, 'ref' : child(chatRef, org_id+"/"+thread_id), 'type' : 'value', 'listener' : listener21});

        let listener6 = onValue(query(child(chatRef, org_id+"/"+thread_id+"/"+"is_assistant_typing")), (child) => {
            if(child.val()){
                onSetTypingState("assistant", true);
            }else{
                onSetTypingState("assistant", false);
            }
        });
        fbListeners4.push({'path' : null, 'ref' : child(chatRef, org_id+"/"+thread_id+"/"+"is_assistant_typing"), 'type' : 'value', 'listener' : listener6});
    }


    sendMessage(mText, onSetChatState){
        var thread_id = sessionStorage.getItem("thread_id");
        var org_id = sessionStorage.getItem("org_id");
        
        var datanew= all_models[thread_id];
        var code = thread_id;
        var date = new Date();
        var dateString = constants.get_DateString(date);
        var time = date.getTime();

        var texts = divM[thread_id];var textHs = divH[thread_id];var mKeys = divK[thread_id];
                
        if(isEmpty){
            var detailsObject= {
                last_updated : time,
                name : thread_id,
                mobile : thread_id,
                source : "support_chat_link",
            };
            
            set(child(chatRef, org_id+"/"+thread_id), detailsObject);

            isEmpty = false;
                
            var textH = "";var textM = "";
            
            var message= {
                senderName:null,
                role:null,
                time:time,
                textH : textH,
                textM : textM
            };

            datanew.push(message);

            textHs.push(textH);texts.push(textM);mKeys.push(null);
            divM[code] = texts;divH[code] = textHs;divK[code] = mKeys;
            div_lastkeys[code] = null;
            div_lasttimes[code] = message['time'];
            div_lastmessages[code] = textM;
            div_lastmessagetimes[code] = message['time'];        
        }

        var textH = '';
        var textM = mText;

        div_lasttimes[code] = time;
        div_lastmessages[code] = textM;
        div_lastmessagetimes[code] = time;

        var chatRefPush = push(child(chatRef, org_id+"/"+thread_id+"/chat"));

        texts.push(mText);textHs.push('');mKeys.push(chatRefPush.key);
        divM[thread_id] = texts;divH[thread_id] = textHs;divK[thread_id] = mKeys;

        var newobject= {
            senderName : "user",
            role : "user",
            time : time,
            textM : textM,
            textH : textH
        };
            
        datanew.push(newobject);
    
        all_models[thread_id] = datanew;

        set(chatRefPush, {
            text: mText,
            senderName : "user",
            role : "user",
            time : time
        });

        set(child(chatRef, org_id+"/"+thread_id+"/last_updated"), time);

        remove(child(chatRef, org_id+"/"+thread_id+"/is_customer_typing"));

        onSetChatState(all_models, divK, divM, divH, false);

        return {text: mText, senderName : "user", role : "user", time : time};
    }


    isTypingAlert(isTyping){
        var thread_id = sessionStorage.getItem("thread_id");
        var org_id = sessionStorage.getItem("org_id");
        if(isTyping)
            set(child(chatRef, org_id+"/"+thread_id+"/is_customer_typing"), true);
        else
            remove(child(chatRef, org_id+"/"+thread_id+"/is_customer_typing"));
    }

    clearListeners(){
        for(var l1 in fbListeners1){
            if(l1){
                off(l1['ref'], l1['type'], l1['listener']);
            }
        }
        for(var l2 in fbListeners4){
            if(l2){
                off(l2['ref'], l2['type'], l2['listener']);
            }
        }
        for(var l3 in fbListeners2){
            if(l3){
                off(ref(database, l3['path']), l3['type'], l3['listener']);
            }
        }
        for(var l4 in fbListeners3){
            if(l4){
                off(child(l4['ref'], l4['path']), l4['type'], l4['listener']);
            }
        }
    }


}

export default new chat_utils();