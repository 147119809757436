import { Spacer } from "../base/utils/spacer";

export const Sidebartab = (props) => {
    let className = "tab border-box full_width flex_horizontal center_horizontal center_vertical";
    // className += " " + props.status;
    return (
        <div className={className}>
            <div className="statusbar border-box full_height" />
            <div className="icon border-box flex_vertical center_horizontal center_vertical">
                {props.section.icon}
            </div>
            <div className="text border-box flex_vertical center_horizontal center_vertical">
                <span className="weight500">{props.section.section}</span>
            </div>
            <Spacer/>
            <div className="notif border-box flex_vertical center_horizontal center_vertical bgcolorOne300 colorGrayE1span">
                <span className="weight600">3</span>
            </div>
        </div>
    );
}