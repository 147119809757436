import { Link, NavLink, useNavigate } from "react-router-dom";
import constants from "../../constants";
import { useState } from "react";
import FieldBox from "../../base/utils/fieldbox";

export const ContentTopbar = (props) => {
    const navigate = useNavigate();
    const section = constants.getMonitorSection(props.section);

    const [searchQuery, setSearchQuery] = useState("");

    const fieldStateHandler = (key, value) => {
        switch (key) {
            case "searchquery":
                setSearchQuery(value);
                break;
            default:
                break;
        }
    }

    let tabs = <></>;
    if(constants.getSidebarFooterSection(props.section.key).middleTabs){
        tabs = constants.getSidebarFooterSection(props.section.key).middleTabs.map((tab, index) => 
            <NavLink to={"/org/"+props.section.key+"/pages/"+tab.key} key={tab.key}
                className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
                <div key={index} className="tab border-box full_height flex_vertical center_horizontal center_vertical">
                    <span>{tab.title.toUpperCase()}</span>
                </div>
            </NavLink>
        );
    
    }
    const tabClickHandler = () => {

    }

    return(
        <>
            <div className="contenttopbarbase border-box full_width flex_horizontal center_horizontal center_vertical bgcolorGrayE1">
                <div className="tabs border-box full_height flex_horizontal center_horizontal center_vertical">
                    {tabs}
                </div>
                <div style={{width : "10%"}} />
                <div className="search spacer border-box full_height flex_horizontal center_horizontal center_vertical">
                    <FieldBox objectKey="searchquery" title="Search anything. No, really." value={searchQuery} size="small"
                        onSetFieldState={fieldStateHandler} type="text" noTitle={true} 
                        // backgroundImage="../../../public/images/search.svg" 
                        />
                </div>
                <div className="notif border-box full_height flex_vertical center_horizontal center_vertical">
                    <Link to="/notifications" className="weight100"><i className={true ? "ti ti-bell" : "ti ti-bell-ringing"}></i></Link>
                </div>
            </div>
        </>
    );
};