import { Outlet } from 'react-router-dom';

function AppAccount(props) {
    return (
        <>
            <>
                <Outlet />
            </>
        </>
    );
}

export default AppAccount;
