import './topbar.css';
import Topbaraccount from './topbar_account';
import Topbarauth from './topbar_auth';
import Topbarchat from './topbar_chat';
import Topbarinterviewcandidate from './topbar_interview_candidate';
import Topbarunauth from './topbar_unauth';

export default function Topbar(props) {
	console.log("here3");
	console.log(props);
	let contentType = ["account"].includes(props.type) ? "account" : "default";
    let content;
	switch(props.type) {
		case "interview_candidate":
			content = <Topbarinterviewcandidate {...props} />;
			break;
		case "unauth":
			content = <Topbarunauth {...props} />;
			break;
		case "account":
			content = <Topbaraccount {...props} />;
			break;
		case "chat":
			content = <Topbarchat {...props} />;
			break;
		default:
			content = <Topbarauth {...props} />;
			break;
	}
	return (
		<>
		{
			{
				"account": 
					<>
						{content}
					</>,
				"default": 
					<div className="topbarbase border-box">
						{content}
					</div>,
			}[contentType]
		}
		</>
	);
}
