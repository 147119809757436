import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import "./base/sitebase1.css"
import "./base/Fonts/Lexend/lexend.css"
// import "./base/Fonts/Switzer/Fonts/WEB/css/switzer.css"
import App from './App';
import reportWebVitals from './reportWebVitals';

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDVOIZo-K26JuUD-b7lxpkR0X-WvLnCfg0",
//   authDomain: "mirkwood-793fc.firebaseapp.com",
//   projectId: "mirkwood-793fc",
//   storageBucket: "mirkwood-793fc.appspot.com",
//   messagingSenderId: "917834021129",
//   appId: "1:917834021129:web:1c272621733623aa30bbd8",
//   measurementId: "G-JCS1RPXGB5"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
