import axios from 'axios';
import api from '../../../api';
import constants from '../../../constants';

class apidata{
    async fetchMonitorContentListData(section, tab, limit = "30", pageNo = 1, params_filter={}, isReload=true){
        let response = null;

        var limit = limit ? limit : "30";
        var pageN = pageNo ? pageNo : 1;
        var params_page = {offset : (pageN - 1)*parseInt(limit), limit : limit};

        // if (params_filter.timeline){
        //     if(params_filter.timeline !== "custom" && params_filter.timeline !== "all"){
        //         let range = constants.getDateRangeFromTimeline(params_filter.timeline);
        //         params_filter = {...params_filter, date_from : range.dateFrom.toDateString(), date_to : range.dateTo.toDateString()};
        //     }
        // }

        // console.log("params_filter", params_filter);
        console.log("datedateX4", params_filter);
        if (params_filter.date_from){
            params_filter = {...params_filter, date_from : params_filter.date_from.toDateString()};
        }
        if (params_filter.date_to){
            params_filter = {...params_filter, date_to : params_filter.date_to.toDateString()};
        }

        var params = {...params_page, ...params_filter};

        console.log("hereAPI1", section, tab);
        
        switch(section){
            case "chats":
                switch(tab){
                    case "archives":
                        console.log("hereAPIXX");
                        try{
                            response = await api.getThreads(params);
                            console.log("response", response);
                            return {data : response.data.data, status : "success"};
                        }catch(error){
                            console.log("error", error);
                            if (error.response) {
                                return {status : "error", message : error.response.data.message};
                            } else if (error.request) {
                            } else {
                            }
                            return {status : "error"};
                        }
                }
                break;
            case "candidates":
                switch(tab){
                    case "management":
                        console.log("hereAPI");
                        try{
                            response = await api.getCandidates(params);
                            console.log("response", response);
                            return {data : response.data.data, status : "success"};
                        }catch(error){
                            if (error.response) {
                                // Request made but the server responded with an error
                            } else if (error.request) {
                                // Request made but no response is received from the server.
                            } else {
                                // Error occured while setting up the request
                            }
                            return {status : "error"};
                        }
                    default:
                        return {status : "error"};
                }
                break;
            case "interviews":
                switch(tab){
                    case "management":
                        try{
                            response = await api.getInterviewSets(params);
                            return {data : response.data.data, status : "success"};
                        }catch(error){
                            if (error.response) {
                                // Request made but the server responded with an error
                            } else if (error.request) {
                                // Request made but no response is received from the server.
                            } else {
                                // Error occured while setting up the request
                            }
                            return {status : "error"};
                        }
                }
            default:
                return {status : "error"};
        }
    }


    async fetchMonitorContentSplitData(section, tab, limit = "30", pageNo = 1, params_filter={}, isReload=true){
        let response = null;

        var limit = limit ? limit : "30";
        var pageN = pageNo ? pageNo : 1;
        var params_page = {offset : (pageN - 1)*parseInt(limit), limit : limit};
        var params = {...params_page, ...params_filter};

        console.log("hereAPI1", section, tab);
        
        switch(section){
            case "interviews":
                switch(tab){
                    case "updates":
                        try{
                            let range = constants.getDateRangeFromTimeline("today");
                            response = await api.getInterviews({...params, date_from : range.dateFrom.toDateString(), date_to : range.dateTo.toDateString()});
                            try{
                                let set_ids = [... new Set(response.data.data.map((item) => item.interview_set_id))];
                                let response1 = await api.getInterviewSets({...params, ids : ",".join(set_ids)});
                                return {data : response.data.data, status : "success"};
                            }catch(error){
                                if (error.response) {
                                    // Request made but the server responded with an error
                                } else if (error.request) {
                                    // Request made but no response is received from the server.
                                } else {
                                    // Error occured while setting up the request
                                }
                                return {status : "error"};
                            }
                            // return {data : response.data.data, status : "success"};
                        }catch(error){
                            if (error.response) {
                                // Request made but the server responded with an error
                            } else if (error.request) {
                                // Request made but no response is received from the server.
                            } else {
                                // Error occured while setting up the request
                            }
                            return {status : "error"};
                        }
                }
            default:
                return {status : "error"};
        }
    }

    async fetchInterviewSetData(params_filter={}){
        var params = {...params_filter};
        try{
            let response = await api.getInterviewSets({...params});
            return {data : response.data.data, status : "success"};
        }catch(error){
            if (error.response) {
            } else if (error.request) {
            } else {
            }
            return {status : "error"};
        }
    }
    async fetchInterviewData(params_filter={}){
        var params = {...params_filter};
        try{
            let response = await api.getInterviews({...params});
            return {data : response.data.data, status : "success"};
        }catch(error){
            if (error.response) {
            } else if (error.request) {
            } else {
            }
            return {status : "error"};
        }
    }

    async fetchInterviewsScheduleData(params_filter={}, isReload=true){
        let response = null;

        var limit = "1000";
        var pageN = 1;
        var params_page = {offset : (pageN - 1)*parseInt(limit), limit : limit};
        if(params_filter.timeline !== "custom"){
            let range = constants.getDateRangeFromTimeline(params_filter.timeline);
            console.log("range", range);
            params_filter = {...params_filter, date_from : range.dateFrom.toDateString(), date_to : range.dateTo.toDateString()};
        }
        
        var params = {...params_page, ...params_filter};

        try{
            response = await api.getInterviews(params);
            return {data : response.data.data, status : "success"};
        }catch(error){
            if (error.response) {
                // Request made but the server responded with an error
            } else if (error.request) {
                // Request made but no response is received from the server.
            } else {
                // Error occured while setting up the request
            }
            return {status : "error"};
        }
    }


    async postMessage(data={}){
        try{
            let response = await api.postMessage({...data});
            return {data : response.data.data, status : "success"};
        }catch(error){
            if (error.response) {
            } else if (error.request) {
            } else {
            }
            return {status : "error"};
        }
    }

    async archiveThread(data={}){
        try{
            let response = await api.archiveThread({...data});
            return {data : response.data.data, status : "success"};
        }catch(error){
            if (error.response) {
            } else if (error.request) {
            } else {
            }
            return {status : "error"};
        }
    }

    async handoffThread(data={}){
        try{
            let response = await api.handoffThread({...data});
            return {data : response.data.data, status : "success"};
        }catch(error){
            if (error.response) {
            } else if (error.request) {
            } else {
            }
            return {status : "error"};
        }
    }

    async initiateThread(data={}){
        try{
            let response = await api.initiateThread({...data});
            return {data : response.data.data, status : "success"};
        }catch(error){
            if (error.response) {
                return {status : "error", message : error.response.data.message};
            } else if (error.request) {
            } else {
            }
            return {status : "error"};
        }
    }


    async shopifyConnectionInitiate(data={}){
        try{
            let response = await api.shopifyConnectionInitiate({...data});
            return {data : response.data.data, status : "success"};
        }catch(error){
            if (error.response) {
            } else if (error.request) {
            } else {
            }
            return {status : "error"};
        }
    }

    async fetchStores(params_filter={}){
        var params = {...params_filter};
        try{
            let response = await api.getStores({...params});
            return {data : response.data.data, status : "success"};
        }catch(error){
            if (error.response) {
            } else if (error.request) {
            } else {
            }
            return {status : "error"};
        }
    }
}

export default new apidata();