import { Link, NavLink, useNavigate } from "react-router-dom";
import utils from "./utils";
import constants from "./constants";
import { Sidebartab } from "./components/sidebartab";
import { SidebarDynamic } from "./components/sidebardynamic";
import './sidebar.css';

export const Sidebar = (props) => {
    const navigate = useNavigate();

    let user = utils.getSavedObject("user");

    return(
        <>
            <div className="sidebarbase border-box flex_vertical center_horizontal start_vertical bgcolorGrayE1">
                <div className="header border-box full_width flex_vertical start_horizontal center_vertical">
                    <div className="logo border-box flex_vertical center_horizontal center_vertical">
                        {/* <div className="logoimage border-box flex_vertical center_horizontal center_vertical"> */}
                        <img width="150px" 
                            src={utils.getSavedObject("org") ? utils.getSavedObject("org").logo : "https://fdfdf.png"} alt="logo" />
                        {/* </div> */}
                        {/* <div className="logotext border-box flex_vertical center_horizontal center_vertical">
                            <span className="text">Monitor</span>
                        </div> */}
                    </div>
                </div>
                <div className="contentScroll border-box full_width spacer flex_vertical center_horizontal start_vertical">
                    <div className="tabs border-box full_width flex_vertical center_horizontal start_vertical">
                        {constants.getMonitorSections().map((section, index) => 
                            <NavLink to={"/monitor/"+section.key} key={section.key}
                                className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
                                <Sidebartab 
                                    key={index} 
                                    section={section} /> 
                            </NavLink>
                        )}
                    </div>
                </div>
                <div className="dynamic border-box full_width flex_vertical center_horizontal center_vertical">
                    <SidebarDynamic type="tip"/>
                </div>
                <div className="footertabs border-box full_width flex_vertical start_horizontal center_vertical">
                    {constants.getSidebarFooterSections().map((tab, index) => 
                            <NavLink to={"/org/"+tab.key} key={tab.key}
                                className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
                                <div className="tab border-box full_width flex_horizontal start_horizontal center_vertical weight300">
                                    {tab.title}
                                </div>
                            </NavLink>
                    )}
                </div>
                <div className="footer border-box full_width flex_vertical start_horizontal center_vertical colorGrayE218span">
                    <div className="title border-box flex_horizontal start_horizontal center_vertical">
                        <span className="weight400">{user.first_name + " " + user.last_name}</span>
                    </div>
                    <div className="border-box full_width flex_horizontal space_between_horizontal center_vertical">
                        <div className="subtitle border-box flex_horizontal start_horizontal center_vertical">
                            <span className="weight300">{constants.getRoles()}</span>
                        </div>
                        <div className="subtitle logout border-box flex_horizontal start_horizontal center_vertical">
                            <Link to="/account/logout" className="weight300"><i className="ti ti-logout"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};