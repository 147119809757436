import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import { companyName } from '../constants';

export default function Bottombaraccount() {
	return (
		<div className="bottombarinner border-box account flex_horizontal start_horizontal center_vertical">
			<div className="left border-box weight500">
                <a href="/terms-of-use" target="_blank">© {companyName}</a>
                <span style={{width: "20px", display: "inline-block"}}></span>
                <a href="/terms-of-use" target="_blank">Terms of Use</a>
                <span style={{width: "20px", display: "inline-block"}}></span>
                <a href="/privacy-policy" target="_blank">Privacy Policy</a>
            </div>
		</div>
	);
}