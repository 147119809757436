import { useState } from 'react';
import Utils from '../utils';
import FieldBox from '../base/utils/fieldbox';
import Linkbutton from '../base/utils/linkbutton';
import { redirect } from 'react-router-dom';
import api from '../api';
import { useNavigate } from "react-router-dom";

export const AppPasswordReset = (props) => {
    const [fields, setFields] = useState({email : ""});
    const [error, setError] = useState({});
    const [pageStatus, setPageStatus] = useState("initial");
    const [sendCount, setSendCount] = useState(0);
    const navigate = useNavigate();

    const errors = {
        email : "Please enter a valid email address", 
    }

    function handleFieldState(key, value) {
        switch (key) {
            default:
                setFields({...fields, [key] : value.trim()});
                validateField(key, value.trim());
                break;
        }
    }

    function validateField(key, value){
        switch (key) {
              case "email":
                  if(!Utils.validateField(key, value)) {
                      setError({...error, [key] : errors[key]});
                      return false;
                  }else{
                      setError({...error, [key] : ""});
                      return true;
                  }
                  break;
              default:
                  if(!value || value.length == 0) {
                      setError({...error, [key] : errors[key]});
                      return false;
                  }else{
                      setError({...error, [key] : ""});
                      return true;
                  }
                  break;
        }
    }

    function validateAllFields(){
        let isValid = true;
        
        Object.keys(fields).forEach((key, index) => {
            console.log(key, validateField(key, fields[key]));
            if(!validateField(key, fields[key])){
                isValid = false;
            }
        });
        return isValid;
    }

    function handleButtonClick(buttonType) {
        if(validateAllFields() && sendCount < 2){
            api.passwordReset(fields.email).then(function (response) {
                if(response.data.status){
                    console.log("response", response.data);
                    setPageStatus("success");
                    setSendCount(sendCount + 1);
                    // navigate("/account/password/set");
                    
                }else{
                    setError({...error, "global" : response.data.message});
                    setPageStatus("initial");
                }
                })
                .catch(function (error) {
                    console.log(error);
                    setError({...error, "global" : error.response.data.message});
                    setPageStatus("initial");
                })
                .finally(function () {
                });
        }
    }

    

    return (
      <>
        {
            {'initial':
                <>
                    <div className="mainview border-box bgcolorGrayE1 flex_vertical start_horizontal center_vertical">
                        <h1 className='weight100'>Reset your password</h1>
                        <p className='weight200'>Enter the email address associated with your account, and we'll send you a link to reset your password.</p>
                        <div className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                            <FieldBox objectKey="email" title="email" value={fields.email}
                                onSetFieldState={handleFieldState} type="text" size="large"
                                errorDisplay="yes" errorText={error.email} />  
                        </div>
                        {/* <div className="fieldouter border-box flex_horizontal end_horizontal center_vertical">
                            <a href="/account/password/reset" className="colorOne300span weight400" style={{fontSize : "14px"}}>Forgot your password?</a>
                        </div> */}
                        <div className="fieldouter border-box flex_horizontal start_horizontal center_vertical colorThree500span" style={{fontSize : "14px"}}>
                            {error.global ? error.global : ""}
                        </div>
                        <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                            <button className="bttn large solid one" style={{width : "100%"}} onClick={(e) => handleButtonClick("reset")}>Continue</button>
                        </div>
                    </div>
                    <div className="extraview border-box flex_vertical start_horizontal center_vertical full_width">
                        <div className="one border-box flex_horizontal start_horizontal center_vertical">
                            <a href="/account/signin" className="colorOne300span weight400">Return to sign in</a>
                        </div>
                        <div style={{height : "10px"}} />
                        <div className="one border-box flex_horizontal start_horizontal center_vertical">
                            Don't have an account? &nbsp; <a href="/account/signup" className="colorOne300span weight600">Get in touch</a>
                        </div>
                    </div>
                </>
                ,
                
                'success':
                <>
                    <div className="mainview border-box bgcolorGrayE1 flex_vertical start_horizontal center_vertical">
                        <h1 className='weight100'>Check your email</h1>
                        <p className='weight200'>Thanks! If <span className="weight400">{fields.email}</span> matches an email address we have on file, then we've sent you an email containing further instructions for resetting your password.</p>
                        <p className='weight200'>If you haven't received an email in 5 minutes, check your spam{sendCount < 2 ? <>, <a href="/" className="colorOne300span weight400" onClick={(e) => {e.preventDefault(); handleButtonClick("reset");}}>resend</a>,</>: <></>} or <a href="/account/password/reset" className="colorOne300span weight400">try a different email address</a>.</p>
                    </div>
                    <div className="extraview border-box flex_vertical start_horizontal center_vertical full_width">
                        <div className="one border-box flex_horizontal start_horizontal center_vertical">
                            <a href="/account/signin" className="colorOne300span weight400">Return to sign in</a>
                        </div>
                    </div>
                </>
            }[pageStatus]
        }
      </>
    );
}
