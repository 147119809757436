import { useEffect, useState } from "react";
import { Spacer } from "../../../base/utils/spacer";
import { LightTooltip } from "../../../base/utils/tooltip/lighttooltip";
import country_codes from "../../../country_codes";

export const Thread = (props) => {

    console.log("props", props);

    const getThreadClassName = () => {
        let className = "thread border-box flex_horizontal center_vertical center_horizontal full_width";
        if(props.selectedThread == props.tag){
            className += " selected";
        }else{
            className += " cursor-pointer";
        }
        return className;
    }

    const getCountry = () => {
        if (props.country){
            var object = country_codes.getCountryCodes().find((obj) => obj.name == props.country.name);
            if(object){
                return <><div style={{width : "5px"}}></div><div className="border-box flex_horizontal center_vertical center_horizontal fontspx10 colorGrayE160span">
                            <LightTooltip title={object.name +" ("+object.dial_code+")"}>{object.emoji}</LightTooltip>
                        </div></>
            }else{
                return <></>;
            }
        }else{
            return <></>;
        }
        
    }

    return (
        <>
            <div className={getThreadClassName()}
                onClick={(e) => props.handleThreadClick(props.tag)}>
                <div className="statusbar border-box full_height" />
                <div className="main border-box flex_vertical start_vertical center_horizontal cursor-pointer spacer">
                    <div className="one border-box flex_horizontal center_vertical center_horizontal full_width">
                        <div className="title border-box flex_horizontal center_vertical center_horizontal fontspx13 weight400">
                            {props.customer_info && props.customer_info.display_name ? props.customer_info.display_name : "Customer"}
                        </div>
                        <Spacer />
                        {props.pagetab == "live" && props.unread && props.unread != 0 ?
                        <LightTooltip title="Unread messages"><div className="notif border-box flex_horizontal center_vertical center_horizontal fontspx9 bgcolorOne300 colorGrayE1span">
                            {props.unread}
                        </div></LightTooltip> : <></>}
                        {props.pagetab == "archives" && props.messages_count > 0 ?
                        <LightTooltip title="Total messages"><div className="notif border-box flex_horizontal center_vertical center_horizontal fontspx9 bgcolorGrayE22 colorGrayE250span">
                            {props.messages_count}
                        </div></LightTooltip> : <></>}
                    </div>
                    <div className="two border-box flex_horizontal center_vertical center_horizontal full_width">
                        <div className="subtitle border-box flex_horizontal center_vertical center_horizontal fontspx11 weight300">
                            {props.customer_id}
                        </div>
                        <Spacer />
                        {props.control_status && props.control_status == "human" ?
                        <div className="border-box flex_horizontal center_vertical center_horizontal fontspx10 colorOne500span">
                            <LightTooltip title="Manual chat control"><i className="ti ti-keyboard"></i></LightTooltip>
                        </div> : 
                        <div className="border-box flex_horizontal center_vertical center_horizontal fontspx10 colorGrayE160span">
                            <LightTooltip title="AI chat control"><i className="ti ti-robot"></i></LightTooltip>
                        </div>}
                        <div style={{width : "5px"}}></div>
                        {props.comm_type && props.comm_type == "whatsapp" ?
                        <div className="border-box flex_horizontal center_vertical center_horizontal fontspx10 colorOne500span">
                            <LightTooltip title="WhatsApp"><i className="ti ti-brand-whatsapp"></i></LightTooltip>
                        </div> : 
                        <div className="border-box flex_horizontal center_vertical center_horizontal fontspx10 colorGrayE160span">
                            <LightTooltip title="Web Chat"><i className="ti ti-world"></i></LightTooltip>
                        </div>}
                        {getCountry()}
                    </div>
                    <div className="three border-box flex_horizontal center_vertical center_horizontal full_width">
                        <div className="text border-box flex_horizontal center_vertical center_horizontal fontspx10">
                            {props.is_typing_user ? <span className="weight500 colorOneA400span">Typing...</span>
                            : <LightTooltip title="Last message"><span className="weight200">{props.last_message}</span></LightTooltip>}
                        </div>
                        <Spacer />
                        <div className="text border-box flex_horizontal center_vertical center_horizontal fontspx10 weight200">
                            <LightTooltip title="Time of last message">{props.last_messagetime}</LightTooltip>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// if (this.props.selectedTab == this.props.tag){
//     return (
//       <>
//       <div id={this.props.id} 
//         className={this.props.unread != 0 ? (this.props.theme == "light" ? "thread selected border-box clearfix colorprim500span" : "thread selected border-box clearfix colorprimdark500span" ) : "thread selected border-box clearfix"} 
//         data-id={this.props.id} onClick={this.handleClick}>
//         <div className="code">
//           <table className="stdtable">
//             <tbody>
//               <tr>
//                 <td valign="center" style={{width:'60%'}}>
//                   {this.props.name ? this.props.name : this.props.tag}
//                 </td>
//                 <td valign="center" className="rightalign" style={{width:'30%', paddingRight: "5px"}}>{this.props.new ? (<span style={{fontSize: "0.5rem"}} className={this.props.theme == "light" ? "new weight400 whitespan bgcolorGreen card" : "new weight400 whitespan bgcolorGreen card"}>NEW</span>) : ""}</td>
//                 <td valign="center" className="rightalign" style={{width:'30%'}}>{label ? (<span style={{background : "#336ae7d4", fontSize: "0.6rem"}} className={this.props.theme == "light" ? "new weight400 whitespan bgcolorprim500 card" : "new weight400 whitespan bgcolorprimdark500 card"}>{label}</span>) : ""}</td>
//                 <td valign="center" className="rightalign" style={{width:'10%'}}><span className={this.props.theme == "light" ? "unread weight700 colorprim500span" : "unread weight700 colorprimdark500span"}>{this.props.unread != 0 ? this.props.unread : ""}</span></td>
//               </tr>
//             </tbody>
//           </table>
//           </div>
//           <di   v className="people">
//           <table className="stdtable"><tbody><tr><td valign="center">
//               {this.props.mobile}
//             </td>
//             <td className={this.props.theme == "light" ? "rightalign blackspan7" : "rightalign ddspan"} valign="center" style={{width:'auto'}}>
//               {response_time ? "R.T. : "+response_time : ""}
//             </td>
//             </tr></tbody></table>
//           </div>
//           <div className="msg">
//           <table className="stdtable"><tbody><tr><td valign="center">
//           <span className="msg weight300i">{this.props.last_message}</span>
//             </td>
//             <td valign="center" className="rightalign" style={{width:'40%'}}>
//               <span className="msg weight300i">{this.props.last_messagetime}</span>
//             </td>
//             </tr></tbody></table>
//           </div>
//       </div>
//       </>
//     );  
//   }