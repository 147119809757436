import React from 'react';
import ChoiceBoxMulti from './choiceboxmulti';
import "./entriesperpage.css";
import utils from '../../utils';

export const EntriesPerPage = (props) => {
    
    return(
        
        <div className="entriesperpage border-box flex_horizontal center_vertical start_horizontal">
        <ChoiceBoxMulti type="entriesperpage" 
            objects={props.choices ? props.choices.map(o => ({title : o+"", id : o+""})) : 
              [{title : "10", id : "10"}, {title : "25", id : "25"},
                {title : "50", id : "50"}, {title : "100", id : "100"}]} 
            selected={props.selectedValue}
            onClick={props.onClick} />
            {/* {utils.isDeviceMobile() ? <React.Fragment /> : <span className="weight600 colorThree500span" style={{fontSize : "11px"}}>&nbsp;&nbsp;&nbsp;Items per page</span>} */}
        </div>
            
    );
}