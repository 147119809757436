import { useEffect, useState } from "react";
import FieldBox from "../../../base/utils/fieldbox";
import { Thread } from "./Thread";
import constants from "../../../constants";

export const PanelRight = (props) => {

    return (
        <>
            <div className="panelright border-box flex_vertical start_vertical center_horizontal full_height">
                  <div className="header border-box flex_vertical center_vertical center_horizontal full_width">
                      
                  </div>
                  <div className="main border-box flex_vertical start_vertical center_horizontal full_width spacer">
                    
                  </div>
            </div>
        </>
    )
}