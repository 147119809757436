import constants from './constants';
import axios from 'axios';

class api{

    getConfig(token=localStorage.getItem("access_token")+""){
        return {headers: {'Authorization': "Bearer " + token, 'trorg' : localStorage.getItem("org_id")}};
    }
    
    getConfigWithParams(params, token=localStorage.getItem("access_token")+"", ){
        console.log("params3", params);
        return {headers: {'Authorization': "Bearer " + token, 'trorg' : localStorage.getItem("org_id")}, "params" : params};
    }
    
    getParams(params){
        return {params : params};
    }
    
    getConfigMultiPartFormData(token=localStorage.getItem("access_token").toString()) {
        if(token){
            return { headers: { "content-type": "multipart/form-data", 'Authorization': "Bearer " + token, 'trorg' : localStorage.getItem("org_id") } };
        }else{
            return { headers: { "content-type": "multipart/form-data", 'trorg' : localStorage.getItem("org_id") } };
        }
    }
    
    getConfigJSON(token=localStorage.getItem("access_token").toString()) {
        if(token){
            return { headers: { "content-type": "application/json", 'Authorization': "Bearer " + token, 'trorg' : localStorage.getItem("org_id") } };
        }else{
            return { headers: { "content-type": "application/json", 'trorg' : localStorage.getItem("org_id") } };
        }
    }



    loginWithEmailPassword(email, password){
        return axios.post(constants.getBaseURL()+'api/account/v1/login_with_password', {
            email : email, password : password,
            // session_key : sessionStorage.getItem("session_key"),
            source : "mirkwood_dashboard"
          })
    }
    signup(email, password, first_name, last_name, phone_number){
        return axios.post(constants.getBaseURL()+'api/v1/signup', {
            email : email, password : password,
            first_name : first_name, last_name : last_name, phone_number : phone_number,
            // session_key : sessionStorage.getItem("session_key"),
            source : "mirkwood_dashboard"
          })
    }
    logout(phone, otp){
        return axios.post(constants.getBaseURL()+'api/logout', {
            
          }, this.getConfig())
    }
    passwordReset(email, password){
        return axios.post(constants.getBaseURL()+'api/account/v1/password/reset', {
            email : email,
            // session_key : sessionStorage.getItem("session_key"),
            source : "mirkwood_dashboard"
          })
    }
    passwordSetCheck(token){
        return axios.post(constants.getBaseURL()+'api/account/v1/password/set/check', {
            token : token,
            // session_key : sessionStorage.getItem("session_key"),
            source : "mirkwood_dashboard"
          })
    }
    passwordSet(user_id, password){
        return axios.post(constants.getBaseURL()+'api/account/v1/password/set', {
            user_id : user_id, password : password,
            // session_key : sessionStorage.getItem("session_key"),
            source : "mirkwood_dashboard"
          })
    }


    
    shopifyConnectionInitiate(data){
        console.log("config", this.getConfig())
        return axios.post(constants.getBaseURL()+'api/store/v1/shopify/connection/initiate', data, this.getConfig());
    }
    getStores(params){
        return axios.get(constants.getBaseURL()+'api/store/v1/shopify/stores', this.getConfigWithParams(params));
    }


    postMessage(data){
        return axios.post(constants.getBaseURL()+'api/chat/v1/message', data);
    }
    archiveThread(data){
        return axios.post(constants.getBaseURL()+'api/chat/v1/thread/archive', data, this.getConfig());
    }
    handoffThread(data){
        return axios.post(constants.getBaseURL()+'api/chat/v1/thread/handoff', data, this.getConfig());
    }
    initiateThread(data){
        return axios.post(constants.getBaseURL()+'api/chat/v1/thread/initiate', data, this.getConfig());
    }
    getThreads(params){
        return axios.get(constants.getBaseURL()+'api/chat/v1/threads', this.getConfigWithParams(params));
    }


    getProfileRoles(token){
        return axios.get(constants.getBaseURL()+'api/v1/uprofile/profile_roles', this.getConfig(token));
    }

    getCandidates(params){
        return axios.get(constants.getBaseURL()+'api/candidate/v1/candidates', this.getConfigWithParams(params));
    }
    getInterviews(params){
        return axios.get(constants.getBaseURL()+'api/interview/v1/interviews', this.getConfigWithParams(params));
    }
    getInterviewSets(params){
        return axios.get(constants.getBaseURL()+'api/interview/v1/interviewsets', this.getConfigWithParams(params));
    }
    
    getCustomers(params){
        if(localStorage.getItem("oem_id")){
            params["oem_id"] = localStorage.getItem("oem_id");
        }
        if(constants.isStateHead()){
            params["state"] = localStorage.getItem("stateName");
        }
        if(constants.isCityHead()){
            params["city"] = localStorage.getItem("cityName");
        }
        return axios.get(constants.getBaseURL()+'api/v1/uprofile/customer_details', this.getConfigWithParams(params));
    }
    getOrders(params){
        if(localStorage.getItem("oem_id")){
            params["oem_id"] = localStorage.getItem("oem_id");
        }
        if(constants.isStateHead()){
            params["state"] = localStorage.getItem("stateName");
        }
        if(constants.isCityHead()){
            params["city"] = localStorage.getItem("cityName");
        }
        return axios.get(constants.getBaseURL()+'api/v1/order/orders', this.getConfigWithParams(params));
    }
    getServiceBuddies(params){
        if(localStorage.getItem("oem_id")){
            params["oem_id"] = localStorage.getItem("oem_id");
        }
        if(constants.isStateHead()){
            params["state"] = localStorage.getItem("stateName");
        }
        if(constants.isCityHead()){
            params["city"] = localStorage.getItem("cityName");
        }
        return axios.get(constants.getBaseURL()+'api/v1/service_buddy/service_buddy_info', this.getConfigWithParams(params));
    }
    saveServiceBuddies(files, data){
        const formData = new FormData();
            // formData.append("name", data.name);
        formData.append("file", files[0]);
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
            }

        return axios.post(
            constants.getBaseURL() + "api/v1/service_buddy/service_buddy_info",
            formData,
            this.getConfigMultiPartFormData()
        );
    }
    saveOEM(data){
        return axios.post(constants.getBaseURL()+'api/v1/uprofile/edit_oem', data, this.getConfig());
    }
    deleteServiceBuddy(data){
        return axios.delete(constants.getBaseURL()+'api/v1/service_buddy/service_buddy_info', {...this.getConfig(), data : data});
    }
    updateServiceBuddy(data){
        return axios.put(constants.getBaseURL()+'api/v1/service_buddy/service_buddy_info', data, this.getConfig());
    }
    editCustomer(data){
        return axios.post(constants.getBaseURL()+'api/v1/uprofile/edit_customer', {...data, type : "customer"}, this.getConfig());
    }
    editVehicle(data){
        return axios.post(constants.getBaseURL()+'api/v1/uprofile/edit_customer', {...data, type : "vehicle"}, this.getConfig());
    }
    getInterviewDetails(params){
        return axios.get(constants.getBaseURL()+'api/interview/v1/interview', this.getConfigWithParams(params));
    }
    getCandidateDetails(params){
        return axios.get(constants.getBaseURL()+'api/candidate/v1/candidate', this.getConfigWithParams(params));
    }
    
    uploadChunk(files, data){
        const formData = new FormData();
        formData.append("file", files[0]);
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
            }

        return axios.post('http://127.0.0.1:8000/api/interview/v1/live/audio/upload_chunk', formData, { headers: { "content-type": "multipart/form-data" } });
    }



    // getProfileRoles(params){
    //     return axios.get(constants.getBaseURL()+'api/v1/uprofile/profile_roles', this.getConfigWithParams(params));
    // }
}

export default new api();