import { Rating1 } from "../../base/utils/rating1";
import { Spacer } from "../../base/utils/spacer";
import constants from "../../constants";
import utils from "../../utils";

export const ContentListItem = (props) => {

    console.log("props", props);

    const imageBox = () => {
        if(props.data.photo_url && props.data.photo_url !== "") {
            return (
                <div className="image flex_vertical center_vertical center_horizontal" style={{backgroundImage : "url("+props.data.photo_url+")", backgroundSize : "cover"}}>
                </div>
            )
        }else{
            return (
                <div className="image flex_vertical center_vertical center_horizontal bgcolorOne50 colorOne900span weight600">
                    <span className="initials fontspx15">{utils.getInitialsFromName(props.data.first_name + " " + props.data.last_name)}</span>
                </div>
            )
        }    
    }

    const content2Details = () => {
        let content = <>
                    <div className="intv_type flex_horizontal start_horizontal center_vertical weight400 colorGrayE171span fontspx12 uppercased" style={{marginBottom : "5px"}}>
                        {props.data.interview_type}
                    </div>
                    <div className="intv1 flex_horizontal start_horizontal center_vertical weight300 fontspx10">
                        <i className="ti ti-language colorGrayE99span"></i><span className="colorGrayE133span" style={{marginLeft : "5px"}}>{props.data.languages.map(o => o.language).join(", ")}</span>
                    </div>
                    <div className="intv2 flex_horizontal start_horizontal center_vertical weight300 fontspx10" style={{marginTop : "2px"}}>
                        <i className="ti ti-clock-play colorGrayE99span"></i><span className="colorGrayE133span" style={{marginLeft : "5px"}}>{props.data.availability_and_preferences.preferred_work_hours}</span>
                    </div>
        </>;
        if(props.data.experience_type === "fresher") {
            return (
                <>
                    {content}
                    <div className="exp_type flex_horizontal start_horizontal center_vertical weight400 colorGrayE171span fontspx12 uppercased" style={{marginTop : "10px",marginBottom : "5px"}}>
                        {props.data.experience_type}
                    </div>
                    <div className="exp1 flex_horizontal start_horizontal center_vertical weight300 fontspx10">
                        <i className="ti ti-school colorGrayE99span"></i><span className="colorGrayE133span" style={{marginLeft : "5px"}}>{props.data.education_details[0].degree}</span>
                    </div>
                    <div className="exp2 flex_horizontal start_horizontal center_vertical weight300 fontspx10" style={{marginTop : "2px"}}>
                        <i className="ti ti-building-arch colorGrayE99span"></i><span className="colorGrayE133span" style={{marginLeft : "5px"}}>{props.data.education_details[0].institution}</span>
                    </div>
                    <div className="exp3 flex_horizontal start_horizontal center_vertical weight300 fontspx10" style={{marginTop : "2px"}}>
                        <i className="ti ti-book colorGrayE99span"></i><span className="colorGrayE133span" style={{marginLeft : "5px"}}>{props.data.education_details[0].major}</span>
                    </div>
                    {/* <div className="exp4 flex_horizontal start_horizontal center_vertical weight300 fontspx10" style={{marginTop : "2px"}}>
                        <i className="ti ti-calendar-event colorGrayE99span"></i><span className="colorGrayE133span" style={{marginLeft : "5px"}}>{props.data.education_details[0].graduation_date}</span>
                    </div> */}
                </>
            )
        }else{
            return (
                <>
                    {content}
                    <div className="exp_type flex_horizontal start_horizontal center_vertical weight400 colorGrayE171span fontspx12 uppercased" style={{marginTop : "10px",marginBottom : "5px"}}>
                        {props.data.experience_type}
                    </div>
                    <div className="exp1 flex_horizontal start_horizontal center_vertical weight300 fontspx11" style={{marginTop : "2px"}}>
                        <i className="ti ti-building-skyscraper colorGrayE99span"></i><span className="colorGrayE133span" style={{marginLeft : "5px"}}>{props.data.experience_details[0].previous_company}</span>
                    </div>
                    <div className="exp2 flex_horizontal start_horizontal center_vertical weight300 fontspx11" style={{marginTop : "2px"}}>
                        <i className="ti ti-tie colorGrayE99span"></i><span className="colorGrayE133span" style={{marginLeft : "5px"}}>{props.data.experience_details[0].previous_job_title}</span>
                    </div>
                    {/* <div className="exp3 flex_horizontal start_horizontal center_vertical weight300 fontspx11" style={{marginTop : "2px"}}>
                        <i className="ti ti-building-factory-2 colorGrayE99span"></i><span className="colorGrayE133span" style={{marginLeft : "5px"}}>{props.data.experience_details[0].previous_industry}</span>
                    </div> */}
                    <div className="exp4 flex_horizontal start_horizontal center_vertical weight300 fontspx11" style={{marginTop : "2px"}}>
                        <i className="ti ti-calendar-event colorGrayE99span"></i><span className="colorGrayE133span" style={{marginLeft : "5px"}}>{props.data.experience_details[0].previous_job_duration}</span>
                    </div>
                </>
            )
        }
    }

    const content3Details = () => {
        let content_resume = <></>;
        if(props.data.resume_url && props.data.resume_url !== "" || props.data.id == 101){
            content_resume = 
            <>
                <i className="ti ti-circle-check-filled greenseaspan"></i>
                <span className="colorGrayE218span" style={{marginLeft : "5px"}}>Resume</span>
                <Spacer />
                <a href={props.data.resume_url} target="_blank">
                    <i className="ti ti-file-download colorOneA400span"></i>
                </a>
            </>;
        }else{
            content_resume = 
            <>
                <i className="ti ti-circle-x-filled colorGrayE99span"></i>
                <span className="colorGrayE133span" style={{marginLeft : "5px"}}>Resume</span>
                <Spacer />
                <i className="ti ti-info-circle colorThree500span"></i>
            </>;
        }
        let content_references = <></>;
        if(props.data.references && props.data.references.length !== 0 && props.data.id != 102){
            content_references = 
            <>
                <i className="ti ti-circle-check-filled greenseaspan"></i>
                <span className="colorGrayE218span" style={{marginLeft : "5px"}}>{props.data.references.length} Reference{props.data.references.length !== 1 ? "s" : ""}</span>
            </>;
        }else{
            content_references = 
            <>
                <i className="ti ti-circle-x-filled colorGrayE99span"></i>
                <span className="colorGrayE133span" style={{marginLeft : "5px"}}>No References</span>
                <Spacer />
                <i className="ti ti-info-circle colorGrayE99span"></i>
            </>;
        }
        let content_interviews = <></>;
        let interviews = props.data.interviews;
        let interviews_completed = interviews.filter(o => o.status === "completed");
        let interviews_scheduled = interviews.filter(o => o.status === "yet_to_start");
        let interviews_proposed = interviews.filter(o => o.status === "proposed");
        let interviews_lapsed = interviews.filter(o => o.status === "lapsed");
        let last_completed_date = interviews_completed.length > 0 ? constants.get_DateString1Date(interviews_completed[0].interview_start_time.$date) : null;
        let last_scheduled_date = interviews_scheduled.length > 0 ? constants.get_DateString1Date(interviews_scheduled[0].interview_start_time.$date) : null;
        let last_proposed_date = interviews_proposed.length > 0 ? constants.get_DateString1Date(interviews_proposed[0].interview_start_time.$date) : null;
        let last_lapsed_date = interviews_lapsed.length > 0 ? constants.get_DateString1Date(interviews_lapsed[0].interview_start_time.$date) : null;
        if(props.data.interviews_completed && props.data.interviews_completed.length !== 0 && props.data.id != 103){
            content_interviews = 
            <>
                <i className="ti ti-circle-check-filled greenseaspan"></i>
                <div className="border-box spacer flex_vertical start_vertical start_horizontal" style={{marginLeft : "5px"}}>
                    <span className="colorGrayE218span">{interviews_completed.length} Interview{interviews_completed.length !== 1 ? "s" : ""}</span>
                    <span className="colorGrayE133span fontspx10" style={{marginTop : "2px"}}>Last on {last_completed_date}</span>
                </div>
            </>;
        }else{
            content_interviews = 
            <>
                <i className="ti ti-circle-x-filled colorGrayE99span"></i>
                <div className="border-box spacer flex_vertical start_vertical start_horizontal" style={{marginLeft : "5px"}}>
                    <span className="colorGrayE133span full_width">No Interviews</span>
                    {last_lapsed_date ? <span className="colorGrayE133span fontspx10" style={{marginTop : "2px"}}>Last lapsed on {last_lapsed_date}</span> : <></>}
                </div>
                <i className="ti ti-info-circle colorGrayE99span"></i>
            </>;
        }
        
        let content_interviews_future = <></>;
        if(last_proposed_date || last_scheduled_date){
            if(last_scheduled_date){
                content_interviews_future = 
                <>
                <div className="full_width flex_horizontal start_horizontal start_vertical weight300 fontspx12" style={{marginTop : "5px"}}>
                    <i className="ti ti-circle-check-filled colorTwo700span"></i>
                    <div className="border-box spacer flex_vertical start_vertical start_horizontal" style={{marginLeft : "5px"}}>
                        <span className="colorGrayE218span">Scheduled Interview</span>
                        <span className="colorGrayE133span fontspx10" style={{marginTop : "2px"}}>Next on {last_scheduled_date}</span>
                    </div>
                    <i className="ti ti-info-circle colorGrayE99span"></i>
                </div>
                </>;
            }else{
                content_interviews_future =
                <>
                <div className="full_width flex_horizontal start_horizontal start_vertical weight300 fontspx12" style={{marginTop : "5px"}}>
                    <i className="ti ti-circle-check-filled colorTwo700span"></i>
                    <div className="border-box spacer flex_vertical start_vertical start_horizontal" style={{marginLeft : "5px"}}>
                        <span className="colorGrayE218span">Proposed Interview</span>
                        <span className="colorGrayE133span fontspx10" style={{marginTop : "2px"}}>Next on {last_proposed_date}</span>
                    </div>
                    <i className="ti ti-info-circle colorGrayE99span"></i>
                </div>
                </>;
            }
        }
        return(
                <>
                    <div className="rating flex_horizontal start_horizontal center_vertical weight500 colorGrayE189span fontspx12 uppercased" style={{marginBottom : "5px"}}>
                        <span className="colorGrayE99span weight300" style={{marginRight : "10px"}}>TR Score</span> <span className="colorGrayE218span" style={{marginRight : "5px"}}>{props.data.rating ? props.data.rating : 0.4}</span> <Rating1 value={props.data.rating ? props.data.rating : 0.4} />
                    </div>
                    <div className="full_width flex_horizontal start_horizontal center_vertical weight300 fontspx12" style={{marginTop : "5px"}}>
                        {content_resume}
                    </div>
                    <div className="full_width flex_horizontal start_horizontal center_vertical weight300 fontspx12" style={{marginTop : "5px"}}>
                        {content_references}
                    </div>
                    <div className="full_width flex_horizontal start_horizontal start_vertical weight300 fontspx12" style={{marginTop : "5px"}}>
                        {content_interviews}
                    </div>
                    {content_interviews_future}
                </>
        )
    }
    

    return (
        <div className="contentlistitem full_width border-box flex_vertical start_vertical center_horizontal bgcolorGrayE1">
            <div className="header full_width flex_horizontal start_vertical center_horizontal">
                <div className="content1 flex_vertical start_vertical start_horizontal">
                    <div className="name flex_horizontal start_horizontal start_vertical">
                        {imageBox()}
                        <div className="flex_vertical start_horizontal start_vertical weight300 fontspx15" style={{marginLeft : "10px"}}>
                            <span>{props.data.first_name} {props.data.last_name}</span>
                            <span className="fontspx9 colorGrayE189span" style={{marginTop : "2px"}}><i className="ti ti-phone colorGrayE99span"></i> {props.data.phone}</span>
                        </div>
                    </div>
                    <div className="email flex_horizontal start_horizontal center_vertical fontspx11 colorOneA200span" style={{marginTop : "10px"}}>
                        <span><i className="ti ti-mail colorGrayE99span"></i> {props.data.email}</span>
                    </div>
                    <div className="social flex_horizontal start_horizontal center_vertical fontspx11 colorOneA200span" style={{marginTop : "5px"}}>
                        <span><i className="ti ti-brand-linkedin colorGrayE99span"></i> {props.data.linkedin_profile && props.data.linkedin_profile !== "" ? props.data.linkedin_profile : "NA"}</span>
                    </div>
                    <div className="location flex_horizontal start_horizontal center_vertical fontspx11 colorGrayE99span" style={{marginTop : "5px"}}>
                        <span><i className="ti ti-map-pin colorGrayE99span"></i> {props.data.address ? props.data.address.city +", " + props.data.address.state : "NA"}</span>
                    </div>
                </div>
                <div className="divider vertical" />
                <div className="content2 flex_vertical start_vertical start_horizontal">
                    {content2Details()}
                </div>
                <div className="divider vertical" />
                <div className="content3 spacer flex_vertical start_vertical start_horizontal">
                    {content3Details()}
                </div>
            </div>
        </div>
    )
}