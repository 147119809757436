import Lottie from "lottie-react"
import * as loaderData from "./lottie/loader2.json";
import { CircularProgress } from "@mui/material";

export const Loader = (props) => {
    return (
        <div className="full_width full_height flex_vertical center_vertical center_horizontal" style={props.style ? props.style : {}}>
            {/* <Lottie animationData={loaderData} loop={true} style={props.innerstyle ? props.innerstyle : {}} /> */}
            <CircularProgress
            variant="indeterminate"
            sx={{
                // color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                color : "#3740ff",
                animationDuration: '550ms',
                // position: 'absolute',
                // left: 0,
                // [`& .${circularProgressClasses.circle}`]: {
                //   strokeLinecap: 'round',
                // },
              }}
              size={40}
              thickness={4}
              {...props}
             />
        </div>
    );

}