import { useEffect, useState } from "react";
import FieldBox from "../../../base/utils/fieldbox";
import { Thread } from "./Thread";
import constants from "../../../constants";
import { EntriesPerPage } from "../../../base/utils/entriesperpage";
import { Spacer } from "../../../base/utils/spacer";
import { Pagination } from "../../../base/utils/pagination";

export const PanelLeft = (props) => {


    const fieldStateHandler = (key, value) => {
        switch (key) {
            case "searchquery":
                props.onSetSearchQuery(value);
                break;
            default:
                break;
        }
    }

    const getContent = () => {
        return (
            props.threadsList.map((thread, index) =>
                <Thread key={index} 
                  handleThreadClick={props.handleThreadClick}
                  tag={thread.id} name={thread.name} 
                  last_message = {thread.last_message ? (thread.last_message.length > 30 ? thread.last_message.substring(0,30) + " ...": thread.last_message) : ""} 
                  last_messagetime = {thread.last_messagetime ? constants.get_DateString1(thread.last_messagetime, true) : ""} 
                  last_time={thread.last_time} mobile={thread.mobile} unread={thread.unread} new={thread.new} 
                  comm_type={thread.comm_type}
                  customer_info={thread.customer_info}
                  first_executive_message_time={thread.first_executive_message_time} 
                  first_customer_message_time={thread.first_customer_message_time} 
                  country={thread.country}
                  control_status={thread.control_status}
                  models={thread.id in props.allModels ? props.allModels[thread.id] : {}} 
                  // onThreadClick={props.onTabClick} 
                  selectedThread={props.selectedThread} 
                  is_typing_user={thread.is_typing_user} 
                  source={thread.source}
                  customer_id={thread.customer_id}
                  messages_count={thread.messages_count}
                  pagetab={props.pagetab} />
              )
        )
    }

    return (
        <>
            <div className="panelleft border-box flex_vertical start_vertical center_horizontal full_height">
                  <div className="header border-box flex_vertical center_vertical center_horizontal full_width">
                      <FieldBox objectKey="searchquery" title="Search anything. No, really." value={props.searchQuery} size="small"
                          onSetFieldState={fieldStateHandler} type="text" noTitle={true} />
                  </div>
                  <div className="main border-box flex_vertical start_vertical center_horizontal full_width spacer">
                    {getContent()}
                  </div>
                  {props.pagetab == "archives" ?
                  <div className="footer border-box full_width flex_horizontal center_vertical center_horizontal bgcolorGrayE1">
                    <EntriesPerPage
                        onClick={props.choiceBoxMultiClickHandler}
                        selectedValue={props.entriesperpage.toLowerCase()}
                    />
                    <Spacer/>
                    <Pagination textDetail="minimal" buttonClickHandler={props.buttonClickHandler} page={props.page} />
                    {/* <Spacer/>
                    <DisplayMode
                        onClick={props.choiceBoxMultiClickHandler}
                        selectedValue={props.displayMode ? props.displayMode.toLowerCase() : "list"}
                    /> */}
                </div>
                  :<></>}
            </div>
        </>
    )
}