import React, { useEffect, useRef, useState } from 'react';
import constants from '../../constants';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './datepretty.css';
import { ClickAwayListener } from '@mui/material';

export const DatePretty = (props) => {

    const boxRef = useRef(null);
    console.log("DatePretty", props)
    // const [value, setValue] = useState(props.value ? props.value : new Date());
    // const [value, setValue] = useState(props.value ? props.value : null);
    const [value, setValue] = useState(props.value ? props.value : (props.nullDateAllowed ? null : new Date()));
    const [clicked, setClicked] = useState(false);
    const [offsetTop, setOffsetTop] = useState(0);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [offsetHeight, setOffsetHeight] = useState(0);

    let className = "prettybox date border-box";
    let classNameInner = "inner border-box flex_horizontal space_between_horizontal center_vertical";

    useEffect(() => {
      setOffsetTop(boxRef.current.offsetTop);
      setOffsetLeft(boxRef.current.offsetLeft);
      setOffsetHeight(boxRef.current.offsetHeight);

      console.log("offsets", offsetTop, offsetLeft, offsetHeight);
    }, [boxRef]);

    // useEffect(() => {
    //   setValue(dayjs(props.value));
    // }, [props.value]);

    useEffect(() => {
      props.onClick(props.type, value);
      setClicked(false);
    }, [value]);

    if(props.size){
        className += " " + props.size;
    }

    const boxClickHandler = () => {
      setClicked(!clicked);
    }

    const clickAwayHandler = () => {
      setClicked(false);
    }

    return(
      <div className={className} ref={boxRef}>
          <div className={clicked ? classNameInner + " clicked" : classNameInner} onClick={boxClickHandler}>
              <div className="text border-box"><span className="weight300">{props.inlineTitle ? props.title : <></>}</span>{value ? <span className="weight300"> : </span> : <></>}<span className="weight500">{value ? constants.get_DateString1Date(value) : <></>}</span></div>
              {/* <div className="arrow border-box"><i className="ti ti-triangle-inverted"></i></div> */}
          </div>
          {clicked ?
          <ClickAwayListener onClickAway={clickAwayHandler}>
          <div className="dropdown border-box absolute" style={{top : offsetTop+offsetHeight+2, left : offsetLeft+10, zIndex : "950", backgroundColor : "#ffffff"}}>
              <DayPicker required mode="single" selected={value} onSelect={setValue} fromDate={props.limits == "noPast" ? new Date() : {}} toDate={props.limits == "noFuture" ? new Date() : {}} />
          </div>
          </ClickAwayListener> : <></>}
      </div>
    );
}

const SelectPrettyOption = (props) => {
  let className = "row border-box flex_horizontal space_between_horizontal center_vertical";
  let classNameText = "text border-box";
  if (props.selected){
    className += " selected";
    classNameText += " weight400";
  }else{
    classNameText += " weight300";
  }

  const clickHandler = () => {
    // if(!props.selected){
        props.onClick(props.type, props.id+"", props.text, props.selected);
    // }
  }

      return(
        <>
            <div className={className} onClick={clickHandler}>
                <div className={classNameText}>{props.text}</div>
            </div>
        </>
      );
}