import React from 'react';
import ChoiceBoxMulti from './choiceboxmulti';
import "./displaymode.css";
import utils from '../../utils';

export const DisplayMode = (props) => {
    
    return(
        
        <div className="displaymode border-box flex_horizontal center_vertical start_horizontal">
        <ChoiceBoxMulti type="displaymode" fontSize="14px"
            objects={props.choices ? props.choices.map(o => ({title : o+"", id : o+""})) : 
                [
                    {title : <i className="ti ti-layout-list"></i>, id : "list"},
                    {title : <i className="ti ti-layout-grid"></i>, id : "grid"},
                    {title : <i className="ti ti-map"></i>, id : "map"},
                ]} 
            selected={props.selectedValue}
            onClick={props.onClick} />
            {/* {utils.isDeviceMobile() ? <React.Fragment /> : <span className="weight600 colorThree500span" style={{fontSize : "11px"}}>&nbsp;&nbsp;&nbsp;Items per page</span>} */}
        </div>
            
    );
}