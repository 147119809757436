import Bottombar from "../../bottombar/bottombar";
import Topbar from "../../topbar/topbar";
import Linkbutton from "../utils/linkbutton";
import './base.css';

export default function FourOFour(props) {
    return (
        <>
        <div className="baseouter border-box full_width full_height flex_vertical center_vertical center_horizontal">
        <Topbar type="unauth" />
        <div className="basebase border-box full_width spacer flex_vertical center_vertical center_horizontal clearfix">
            <div className="basebaseinner border-box">
                  <div className="basebaseinnercenter border-box">
                      <div className="inner border-box">
                          <h1 className="colorThree500span weight700" style={{fontSize : "40px"}}>404!</h1>
                          <p className="centeralign" style={{fontSize : "12px"}}>We couldn't find the page you were looking for :/</p>
                          <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <Linkbutton text="Back to Home" href="/" newTab="no" type="large1" button_type="solid" />
                          </div>
                      </div>
                      
                  </div>
              </div>
        </div>
        <Bottombar type="unauth" />
        </div>
        </>
    );
}