import { Navigate, Outlet, useParams } from 'react-router-dom';
import Topbar from '../topbar/topbar';
import './chat.css';
import utils from '../utils';

function AppChat(props) {

    return (
        <>
            <>
                <Outlet />
            </>
        </>
    );
}

export default AppChat;
