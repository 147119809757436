import { Loader } from "../../base/utils/loader";

export const ContentSplitOne = (props) => {

    console.log("props1", props);

    // let classNameCLI = "oneinner border-box full_width full_height";

    return (
        <>
            {
                {
                    true: 
                        <Loader innerstyle={{width : "20%"}}/>,
                    false: 
                        <>
                            <div className="header border-box full_width flex_vertical start_vertical start_horizontal">
                                <div className="title fontspx30 weight300">Hi Prasenjit,</div>
                                <div className="subtitle fontspx18 weight200">Here are your updates for today.</div>
                            </div>
                            {/* <div className={classNameCLI} style={{gridTemplateColumns : "repeat("+props.gridColumnsCount+", 1fr)"}}>
                                {props.data.map((item, index) => (props.section.key == "candidates" ? <ContentListItem section={props.section} tab={props.tab} key={index} data={item} displayMode={props.displayMode} /> : <ContentListItemTemp section={props.section} tab={props.tab} key={index} data={item} displayMode={props.displayMode} />))}
                            </div> */}
                        </>,
                }[props.isLoading]
            }
        </>
    );
}