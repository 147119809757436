import React, { useState } from 'react';
import { Spacer } from '../spacer';
import FieldBox from '../fieldbox';
import utils from '../../../utils';
import apidata from '../data/apidata';
import { Loader } from '../loader';

export const ChatInitiate = (props) => {

    const [fields, setFields] = useState({phoneNumber : ""});
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const errors = {
        phoneNumber : "Please enter a valid phone number",
    }

    function handleFieldState(key, value) {
        console.log(key, value, error);
        switch (key) {
              default:
                  setFields({...fields, [key] : value.trim()});
                  validateField(key, value.trim());
                  break;
        }
    }

    function validateField(key, value){
        switch (key) {
            case "phoneNumber":
                console.log(key, value, utils.validateField(key, value))
                if(!utils.validateField(key, value)) {
                    setError({...error, [key] : errors[key]});
                    return false;
                }else{
                    setError({...error, [key] : ""});
                    return true;
                }
                break;
            default:
                  if(!value || value.length == 0) {
                      setError({...error, [key] : errors[key]});
                      return false;
                  }else{
                      setError({...error, [key] : ""});
                      return true;
                  }
                  break;
        }
    }
  
    function validateAllFields(){
        let isValid = true;
        
        Object.keys(fields).forEach((key, index) => {
            console.log(key, validateField(key, fields[key]));
            if(!validateField(key, fields[key])){
                isValid = false;
            }
        });
        return isValid;
    }

    function buttonClickHandler(buttonType, data=null) {
        switch(buttonType) {
            case "action_main":
                if(validateAllFields()){
                    actionAPICall({phoneNumber : fields.phoneNumber});
                    // api.signup(fields.email, fields.password, fields.firstName, fields.lastName, fields.phoneNumber).then(function (response) {
                    //     if(response.data.status){
                    //         // localStorage.setItem("user_id", response.data.data.id);
                    //         // localStorage.setItem("custom_token", response.data.data.custom_token);
                    //         // localStorage.setItem("access_token", response.data.data.access_token.token);
                    //         // localStorage.setItem("token_expiration_date", response.data.data.access_token.expiration_date);
                    //         // localStorage.setItem("logged_in_at", new Date());
                    //         // localStorage.setItem("email", fields.email);
                    //         // utils.setSavedObject("user", response.data.data.user);
                    //         // if(response.data.data.org){
                    //         //     utils.setSavedObject("org", response.data.data.org);
                    //         //     localStorage.setItem("org_id", response.data.data.org.id);
                    //         // }
                    //         // utils.setSavedList("roles", response.data.data.user.roles);
                    //         // navigate("/login");
                               
                    //     }else{
                    //         setError({...error, "global" : response.data.message});
                    //         // setPageStatus("initial");
                    //     }
                    //     })
                    //     .catch(function (error) {
                    //         setError({...error, "global" : error.response.data.message});
                    //         // setPageStatus("initial");
                    //     })
                    //     .finally(function () {
                    //     });
                }
        }
    }

    async function actionAPICall(data){
        setIsLoading(true);
        const res = await apidata.initiateThread(data);
        postAPICall(res.status, res.data ? res.data : [], res.message ? res.message : "");
    }

    const postAPICall = (status, data, message="") => {
        if(status == "success"){
            props.buttonClickHandler("close")
        }else{
            setIsLoading(false);
            setError({...error, "global" : message});
        }
    }

    return(
        <>
        {
            {
                true: 
                    <Loader innerstyle={{width : "20%"}}/>,
                false: 
                    <div className="main border-box flex_vertical start_horizontal center_vertical full_width">
                        <h3 className='weight100'>Initiate Chat</h3>
                        <p className='weight200 fontspx14'>Enter the phone number of the customer</p>
                        <div className="fieldouter border-box flex_vertical center_horizontal center_vertical full_width">
                            <FieldBox objectKey="phoneNumber" title="Phone Number" value={fields.phoneNumber} size="small"
                                onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.phoneNumber} />  
                        </div>
                        {/* <div className="fieldouter border-box flex_vertical center_horizontal center_vertical">
                            <FieldBox objectKey="password" title="password" value={fields.password} size="large"
                                onSetFieldState={handleFieldState} type="password" errorDisplay="yes" errorText={error.password}/>
                        </div> */}
                        {/* <div className="fieldouter border-box flex_horizontal end_horizontal center_vertical">
                            <a href="/account/password/reset" className="colorOne300span weight400" style={{fontSize : "14px"}}>Forgot your password?</a>
                        </div> */}
                        <div className="fieldouter border-box flex_horizontal start_horizontal center_vertical colorThree500span full_width" style={{fontSize : "14px"}}>
                            {error.global ? error.global : ""}
                        </div>
                        <div style={{height : "20px"}}></div>
                        <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical full_width">
                            <button className="bttn medium solid three" onClick={(e) => props.buttonClickHandler("close")}>Close</button>
                            <Spacer />
                            <button className="bttn medium solid one" onClick={(e) => buttonClickHandler("action_main")}>Initiate</button>
                        </div>
                    </div>,
            }[isLoading]
        }
        </>
    )
}



